import { Navigate } from 'react-router-dom';

import { useGetMyProfileQuery } from '@api/user';

import LoadingScreen from '@common/components/LoadingScreen';

import { DEFAULT_REDIRECT_URL } from './paths';

const getDefaultRedirectRoute = (roles: string[]) => {
  if (roles.includes('Admin') && !roles.includes('Mailroom')) {
    return DEFAULT_REDIRECT_URL.company;
  }

  if (roles.includes('Mailroom') || roles.includes('KTAdmin') || roles.includes('Admin'))
    return DEFAULT_REDIRECT_URL.mailroom;

  if (roles.includes('Recipient')) return DEFAULT_REDIRECT_URL.recipient;

  return DEFAULT_REDIRECT_URL.default;
};

export const DefaultRecirect = () => {
  const { data: currentUser } = useGetMyProfileQuery();

  if (currentUser) {
    const { roles } = currentUser;

    const route = getDefaultRedirectRoute(roles);

    return <Navigate to={route} replace />;
  }

  // return <Navigate to="auth/login" replace />;
  return <LoadingScreen />;
};
