export const sxP20 = {
  p: 2.5,
};

export const sxMain = {
  m: 2.5,
  mb: 0,
  flex: 1,
};

export const sxSection = {
  p: 2.5,
  bgcolor: 'background.neutral',
  borderRadius: 1.5,
};

export const sxPaper = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  minHeight: '100%',
};
