import { Autorenew } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Button, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { PieceAssetSize, RecipientAutoDetectionStatus, Workflow, PieceAction } from '@apiContract';
// eslint-disable-next-line import/no-named-as-default
import Lightbox, { useLightBox } from '@minimalsComponents/lightbox';
import { getPieceAssetUrl } from 'src/utils/piece';

import { useSettings } from '@common/hooks/useSettings';

import { getColorPieceStatus, getPieceDetection, tooltips } from '../../../helper';
import { CapturePiece, DrawerTypes, IdV4, Steps } from '../../../types';
import IconWithLoader from '../../IconWithLoader';
import {
  sxBackBtn,
  sxGridChild_2_1,
  sxGridChild_2_1_1,
  sxGridChild_2_1_1_1,
  sxGridChild_2_1_1_2,
  sxGridChild_2_1_2,
  sxImageContainer,
  sxImg,
  sxMR0,
  sxPX2,
  sxPieceViewItem,
} from '../styles';
import CrossOriginImg from 'src/common/uiKit/CrossOriginImg';

type PieceViewProps = {
  handleBack: () => void;
  capturePiece: CapturePiece;
  setDrawer: (type: DrawerTypes) => void;
  onCropClick: (returnPath: Steps) => void;
  retryFailedPieces: (id: IdV4) => void;
  workflow: Workflow;
};

const PieceView: FC<PieceViewProps> = ({
  handleBack,
  capturePiece,
  setDrawer,
  onCropClick,
  retryFailedPieces,
  workflow,
}) => {
  const pieceDetection = getPieceDetection(capturePiece);
  const settings: Record<string, boolean> = useSettings(['AutoCroppingEnable']);

  const pieceSrc =
    capturePiece.editImage ||
    getPieceAssetUrl(capturePiece.piece?.pieceAssets || [], PieceAssetSize.Large) ||
    (capturePiece.photo instanceof File ? URL.createObjectURL(capturePiece.photo) : capturePiece.photo);

  const lightbox = useLightBox([{ src: pieceSrc || '' }]);

  const isSend = (capturePiece?.piece?.lifecycleStage || 0) >= PieceAction.Submit;

  const getActiveButton = () => {
    if (capturePiece.status === 'failed') {
      return (
        <Button
          onClick={() => {
            retryFailedPieces(capturePiece.id as IdV4);
          }}
          variant="soft"
          sx={sxPX2}
          startIcon={<Autorenew />}
        >
          Retry
        </Button>
      );
    }

    const isUserLoading =
      !!capturePiece.piece &&
      (capturePiece.isUpdating || !(capturePiece.piece?.recipient || capturePiece.pieceDetection || capturePiece.status === 'loadedFromServer'));

    return (
      <>
        <IconWithLoader
          icon="PersonPinRounded"
          tooltip={tooltips.PersonPinRounded}
          isLoading={isUserLoading}
          onClick={() => setDrawer(DrawerTypes.User)}
          color={isUserLoading ? undefined : getColorPieceStatus(capturePiece.piece, pieceDetection?.status)}
          sx={sxMR0}
        />
        {workflow !== Workflow.HighDensityMail && workflow !== Workflow.MailBox ? (
          <IconWithLoader
            icon="LocationOnRounded"
            tooltip={tooltips.LocationOnRounded}
            onClick={() => setDrawer(DrawerTypes.Location)}
            sx={sxMR0}
          />
        ) : null}

        <IconWithLoader
          icon="AddCommentRounded"
          tooltip={tooltips.AddCommentRounded}
          onClick={() => setDrawer(DrawerTypes.Comments)}
          sx={sxMR0}
        />

        <IconWithLoader
          icon="CropRotateRounded"
          tooltip={tooltips.CropRotateRounded}
          isLoading={
            settings.AutoCroppingEnable &&
            ![Workflow.Carrier, Workflow.Location].includes(workflow) &&
            !!capturePiece.piece &&
            !capturePiece.isAutoCroppedCompleted
          }
          onClick={() => onCropClick(Steps.ReviewItems)}
          sx={sxMR0}
        />
      </>
    );
  };

  const recipient =
    capturePiece.piece?.recipient ||
    (pieceDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired &&
      pieceDetection?.recipient);

  return (
    <Grid container item sx={sxGridChild_2_1}>
      <Grid container item direction={{ xs: 'column-reverse', md: 'row' }} sx={sxGridChild_2_1_1}>
        <Grid item sx={sxGridChild_2_1_1_1}>
          <ListItem disablePadding component="div" sx={sxPieceViewItem}>
            <ListItemAvatar>
              <Avatar alt="" src={recipient ? recipient.profileImageUrl : ''}>
                {recipient ? `${recipient?.firstName?.charAt(0)}${recipient?.lastName?.charAt(0)}` : <PersonIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle1">{(recipient && recipient.displayName) || 'Unassigned'} </Typography>
              }
              secondary={
                <Typography color="text.secondary" variant="body1">
                  {recipient && recipient.userName}
                </Typography>
              }
            />
          </ListItem>
        </Grid>
        <Grid item sx={sxGridChild_2_1_1_2}>
          <Box display="flex" justifyContent="space-between" height={1} alignItems="center">
            <Button onClick={handleBack} variant="text" startIcon={<ArrowBackIosNewIcon />} sx={sxBackBtn}>
              Back
            </Button>
            <Box display="flex" gap={1}>
              {!isSend && getActiveButton()}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item flex={1} sx={sxGridChild_2_1_2}>
        <Box display="flex" sx={sxImageContainer}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <CrossOriginImg
                  src={pieceSrc}
                  alt=""
                  style={{ ...sxImg, height, width }}
                  onClick={() => lightbox.onOpen(pieceSrc || '')}
                />
              );
            }}
          </AutoSizer>
        </Box>
        <Lightbox
          disabledSlideshow
          disabledTotal
          disabledThumbnails
          disabledCaptions
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          slides={[{ src: pieceSrc || '' }]}
          open={lightbox.open}
          close={lightbox.onClose}
          zoom={{
            maxZoomPixelRatio: 2.5,
            zoomInMultiplier: 1.5,
            doubleTapDelay: 300,
            doubleClickDelay: 300,
            doubleClickMaxStops: 2,
            keyboardMoveDistance: 50,
            wheelZoomDistanceFactor: 100,
            pinchZoomDistanceFactor: 100,
            scrollToZoom: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PieceView;
