import { ReactNode, createContext } from 'react';

import { useGetSettingsQuery } from '@api/settings';
import type { Setting } from '@apiContract';

import LoadingScreen from '@common/components/LoadingScreen';

export const SettingsContext = createContext<Setting[]>([]);

type SettingsProviderProps = {
  children: ReactNode;
};

export const SettingsProvider = (props: SettingsProviderProps) => {
  const { children } = props;

  const { data: settings, isLoading } = useGetSettingsQuery();

  if (!settings || isLoading) return <LoadingScreen />;

  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
};
