import { LoadingButton } from '@mui/lab';
import ContinueCapture from './ContinueCapture';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { ButtonConfig } from '../types';

type Props = {
  buttons: ButtonConfig[];
};

const ButtonsList = ({ buttons }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return buttons.map(({ type, onClick, label, disabled, captureDropdown }) => captureDropdown ? (
    <ContinueCapture
      key={label}
      type={type}
      label={label}
      disabled={disabled}
      onClick={onClick}
      isMobile={isMobile}
    />
  ) : (
    <LoadingButton
      variant={type === 'primary' ? 'contained' : 'outlined'}
      onClick={onClick}
      key={label}
      color={type}
      size={isMobile ? 'large' : 'medium'}
      sx={isMobile ? { width: buttons.length === 1 ? '100%' : '49%' } : {}}
      disabled={disabled}
    >
      {label}
    </LoadingButton>
  ));
};

export default ButtonsList;