import { useCallback, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { UploadProps } from '../../../types';
import Row from './Row';

const DEFAULT_ROW_HEIGHT = 60;

const RowRender = ({ data, index, style }: ListChildComponentProps) => (
  <Row style={style} file={data.files[index]} index={index} onRemove={data.onRemove} setSize={data.setSize} />
);

export default function PreviewMultiFile({ files, onRemove }: UploadProps) {
  const listRef = useRef<VariableSizeList>(null);
  const sizeMap = useRef<Record<number, number>>({});

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index);
  }, []);

  const getSize = (index: number) => sizeMap.current[index] || DEFAULT_ROW_HEIGHT;

  if (!files?.length) return null;

  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <VariableSizeList
          height={height}
          width="100%"
          itemCount={files.length}
          itemSize={getSize}
          className="customScrollBar"
          ref={listRef}
          itemData={{
            files,
            onRemove,
            setSize,
          }}
        >
          {RowRender}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
}
