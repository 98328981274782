import type { Variable, VariableArrayResponse, VariableRequest, VariableResponse } from '@apiContract';
import { createSelector } from '@reduxjs/toolkit';

import { receivedDigitalApi } from './index';

const companyVariableApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyVariables: builder.query<Variable[], void>({
      query: () => 'CompanyVariable',
      providesTags: ['CompanyVariable'],
      transformResponse: (response: VariableArrayResponse) => response.objectData,
    }),

    createCompanyVariable: builder.mutation<Variable, VariableRequest>({
      query: (requestData) => ({
        url: 'CompanyVariable',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['CompanyVariable'],
      transformResponse: (response: VariableResponse) => response.objectData,
    }),

    updateCompanyVariable: builder.mutation<Variable, VariableRequest>({
      query: (requestData) => ({
        url: 'CompanyVariable',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['CompanyVariable'],
      transformResponse: (response: VariableResponse) => response.objectData,
    }),
  }),
});

export const { useGetCompanyVariablesQuery, useCreateCompanyVariableMutation, useUpdateCompanyVariableMutation } =
  companyVariableApi;

export const selectVariable = createSelector(
  (variables: Variable[] | undefined) => variables,
  (_: Variable[] | undefined, name: string) => name,
  (variables, name) => variables?.find((variable) => variable.key === name),
);
