import { useState } from 'react';

import { useUpdatePiecesMutation } from '@api/piece';
import { Container, ContainerRequest, Piece, PieceAction, PieceType } from 'src/common/types/apiContract';

export const useLocationId = (pieceId: number, onChange: (piece: Piece) => void, onClose: () => void) => {
  const [storageGroupData, setStorageGroupData] = useState<ContainerRequest & Partial<Pick<Container, 'nextOrderNo'>>>(
    {} as ContainerRequest,
  );

  const [updatePieces, { isLoading }] = useUpdatePiecesMutation();

  const onSaveLocation = async (itemType: PieceType, itemSpot?: string | number) => {
    const data = itemType === PieceType.Package ? { packageSpot: itemSpot as string } : {};

    const result = await updatePieces({
      action: PieceAction.Move,
      pieces: [
        {
          id: pieceId,
          pieceLocation: {
            ...data,
            container: {
              id: storageGroupData.id,
              createdDate: storageGroupData.createdDate,
              containerTypeId: storageGroupData.containerTypeId,
              containerNumber: storageGroupData.containerNumber || '',
              sortOrder: storageGroupData.sortOrder,
            },
            orderNo: 0,
          },
        },
      ],
    }).unwrap();
    onChange(result[0]);
    onClose();
  };

  return {
    setStorageGroupData,
    onSaveLocation,
    isLoading,
    nextOrderNo: storageGroupData.nextOrderNo,
  };
};
