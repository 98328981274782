import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import App from './App';
import { activate } from './data-grid-pro';
import { store } from './redux/store';
import { useEffect } from 'react';

const ignoreMessages = [
  "Error: Failed to complete negotiation with the server: Error: : Status code '401'",
  'Error: Server timeout elapsed without receiving a message from the server',
  'Error: WebSocket closed with status code: 1006 (no reason given)',
];

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  enabled: import.meta.env.VITE_APP_ENVIRONMENT === 'production' || import.meta.env.VITE_APP_ENVIRONMENT === 'staging',
  sendDefaultPii: true,
  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: [import.meta.env.VITE_APP_API, import.meta.env.VITE_APP_IDENTITY],
      networkRequestHeaders: ['Authorization'],
      networkResponseHeaders: ['WWW-Authenticate'],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_APP_API, import.meta.env.VITE_APP_IDENTITY],
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ignoreMessages,
  normalizeDepth: 10,
  normalizeMaxBreadth: 10000,
  maxValueLength: 1000,
});

activate();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </BrowserRouter>
  </HelmetProvider>,
);
