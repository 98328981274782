import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { CSSProperties, useEffect, useMemo, useState } from 'react';

import { ReceiveSession, Workflow } from '@apiContract';
import { MotionContainer, varFade } from '@minimalsComponents/animate';
import { m } from 'framer-motion';

import { CaptureId, CapturePiece, DrawerTypes, IdV4, MapCapturePiece, RecipientStatus } from '../../types';
import MailPieceList from './components/MailPieceList';
import PieceView from './components/PieceView';
import ProgressList from './components/ProgressList';
import {
  animationOpenClosed,
  sxCenter,
  sxGridChild_1,
  sxGridChild_1_1,
  sxGridChild_1_2,
  sxGridChild_2,
  sxGridContainer,
  sxImgEmpty_160,
} from './styles';
import { GroupPiecesStatus, ProgressStatus } from './types';
import { getPiecesByStatus } from './utils/getPieces';

const initialProgressData = {
  [GroupPiecesStatus.all]: 0,
  [RecipientStatus.assigned]: 0,
  [RecipientStatus.validate]: 0,
  [RecipientStatus.unassigned]: 0,
  [GroupPiecesStatus.failed]: 0,
};

type ReviewItemsProps = {
  mapCapturePiece: MapCapturePiece;
  setDrawer: (type: DrawerTypes) => void;
  setCursor: (key: CaptureId | null) => void;
  onCropClick: () => void;
  retryFailedPieces: (id: IdV4) => void;
  defaultCursor?: CaptureId;
  workflow: Workflow;
  requestedReceiveSession: ReceiveSession,
};

type PiecesByStatus = Record<ProgressStatus, Array<CapturePiece>>;

const ReviewItems = ({
  setDrawer,
  setCursor,
  onCropClick,
  retryFailedPieces,
  mapCapturePiece,
  defaultCursor,
  workflow,
}: ReviewItemsProps) => {
  const [activePiece, setActivePiece] = useState<CapturePiece | null>(null);
  const [status, setStatus] = useState<ProgressStatus>(GroupPiecesStatus.all);
  const [pieceByStatus, setPieceByStatus] = useState<PiecesByStatus>({} as PiecesByStatus);
  const [lengthByStatus, setLengthByStatus] = useState<Record<ProgressStatus, number>>(initialProgressData);
  const [searchText, setSearchText] = useState('');
  const capturePieces = useMemo(() => Object.values(mapCapturePiece), [mapCapturePiece]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const data = getPiecesByStatus(capturePieces, searchText);
    setPieceByStatus(data);
    setLengthByStatus({
      [GroupPiecesStatus.all]: capturePieces.length,
      [RecipientStatus.assigned]: data.assigned.length,
      [RecipientStatus.validate]: data.validate.length,
      [RecipientStatus.unassigned]: data.unassigned.length,
      [GroupPiecesStatus.failed]: data.failed.length,
    });
    setActivePiece((prevPiece) =>
      prevPiece ? { ...prevPiece, ...(capturePieces.find((item) => item.id === prevPiece.id) || {}) } : prevPiece,
    );
  }, [capturePieces, searchText]);

  if (activePiece && !mapCapturePiece[activePiece.id]) {
    setActivePiece(null);
    setStatus(GroupPiecesStatus.all);
  }

  if (defaultCursor && !activePiece && mapCapturePiece[defaultCursor]) {
    setActivePiece(mapCapturePiece[defaultCursor]);
  }

  const handlePieceClick = (cursor: CaptureId, piece: CapturePiece) => {
    setCursor(cursor);
    setActivePiece(piece);
  };

  const changeProgressStatus = (data: ProgressStatus) => {
    setStatus(data);
    if (!matches) {
      if (data === GroupPiecesStatus.all) {
        setActivePiece(capturePieces[0] || null);
      } else {
        setActivePiece(pieceByStatus[data][0] || null);
      }
    }
  };

  return (
    <Grid container component={MotionContainer} columnSpacing={{ xs: 0, md: 1 }} sx={sxGridContainer}>
      <Grid item container xs={12} md={5} sx={sxGridChild_1}>
        <Grid item md={4} sx={sxGridChild_1_1}>
          <ProgressList onChange={changeProgressStatus} status={status} data={lengthByStatus} />
        </Grid>
        <Grid container item md={8} sx={sxGridChild_1_2}>
          <MailPieceList
            onSearch={setSearchText}
            handleClick={handlePieceClick}
            pieces={pieceByStatus[status]}
            activePiece={activePiece}
            status={status}
          />
        </Grid>
      </Grid>

      <Grid
        component={m.div}
        container
        initial="closed"
        animate={activePiece ? 'open' : 'closed'}
        variants={matches ? animationOpenClosed : {}}
        transition={varFade().inRight.animate.transition}
        item
        md={7}
        sx={sxGridChild_2}
      >
        {activePiece ? (
          <PieceView
            setDrawer={setDrawer}
            capturePiece={activePiece}
            onCropClick={onCropClick}
            retryFailedPieces={retryFailedPieces}
            workflow={workflow}
            handleBack={() => {
              setCursor(null);
              setActivePiece(null);
            }}
          />
        ) : (
          <Box bgcolor="background.paper" width={1} position="relative" borderRadius={1.5}>
            <img
              src="/assets/icons/empty/ic_email_disabled.svg"
              style={{ ...(sxCenter as CSSProperties), ...sxImgEmpty_160 }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ReviewItems;
