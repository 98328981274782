import { useEffect, useRef, useState } from 'react';

import { ContainerSize, Coordinates } from '../index';

type Side = 'right' | 'left';

const MAGNIFIER_IMAGE = {
  positionX: 20,
  positionY: 20,
  diameter: 120,
  crossSize: 10,
};

type CropMagnifierProps = {
  image: HTMLImageElement;
  containerSize: ContainerSize;
  coordinates: Coordinates | null;
};

export const CropMagnifier = ({ image, containerSize, coordinates }: CropMagnifierProps) => {
  const magnifierCanvasRef = useRef<HTMLCanvasElement>(null);

  const [magnifierSide, setMagnifierSide] = useState<Side>('left');

  const clearMagnifier = () => {
    if (!magnifierCanvasRef.current) return;
    const ctx = magnifierCanvasRef.current.getContext('2d');
    ctx?.clearRect(0, 0, magnifierCanvasRef.current.width, magnifierCanvasRef.current.height);
  };

  useEffect(() => {
    clearMagnifier();

    if (!coordinates) return;

    const { x, y } = coordinates;
    const magnifiedSquareEdge = 45;

    const trueX = (x / containerSize.width) * image.width;
    const trueY = (y / containerSize.height) * image.height;

    const { diameter, crossSize } = MAGNIFIER_IMAGE;

    const radius = diameter / 2;
    const currentSide: Side = x >= containerSize.width / 2 ? 'left' : 'right';
    if (currentSide !== magnifierSide) {
      setMagnifierSide(currentSide);
    }

    const ctx = magnifierCanvasRef.current?.getContext('2d');
    if (!ctx) return;

    ctx.drawImage(
      image,
      trueX - magnifiedSquareEdge / 2,
      trueY - magnifiedSquareEdge / 2,
      magnifiedSquareEdge,
      magnifiedSquareEdge,
      0,
      0,
      diameter,
      diameter,
    );
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(radius, radius);
    ctx.lineTo(radius, radius - crossSize);
    ctx.moveTo(radius, radius);
    ctx.lineTo(radius, radius + crossSize);
    ctx.moveTo(radius, radius);
    ctx.lineTo(radius + crossSize, radius);
    ctx.moveTo(radius, radius);
    ctx.lineTo(radius - crossSize, radius);

    ctx.lineWidth = 3;
    ctx.strokeStyle = 'black';
    ctx.stroke();
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'white';
    ctx.lineCap = 'square';
    ctx.stroke();
    ctx.closePath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, containerSize]);

  return (
    <canvas
      style={{
        position: 'absolute',
        zIndex: 10,
        pointerEvents: 'none',
        borderRadius: '50%',
        ...(magnifierSide === 'left' && { top: MAGNIFIER_IMAGE.positionY, left: MAGNIFIER_IMAGE.positionX }),
        ...(magnifierSide === 'right' && { top: MAGNIFIER_IMAGE.positionY, right: MAGNIFIER_IMAGE.positionX }),
      }}
      width={MAGNIFIER_IMAGE.diameter}
      height={MAGNIFIER_IMAGE.diameter}
      ref={magnifierCanvasRef}
    />
  );
};
