import { useEffect, useRef } from 'react';

import { useUpdatePiecesMutation } from '@api/piece';
import { PieceAction, UpdatePieceRequest } from '@apiContract';

export type AddEvent = {
  event: UpdatePieceRequest;
  action: PieceAction;
  source: string;
  force?: boolean;
};

export type QueueAction = Record<string | number, UpdatePieceRequest>;

type RefType = {
  queue: Record<PieceAction, QueueAction>;
  updating: boolean;
};

export const useUpdateQueue = () => {
  const [updatePieces] = useUpdatePiecesMutation();
  const updating = useRef<RefType>({
    updating: false,
    queue: {} as Record<PieceAction, QueueAction>,
  });

  const updatePiecesFn = async (pieces: UpdatePieceRequest[], action: PieceAction) => {
    try {
      updating.current.updating = true;
      const updateEvent = new CustomEvent('PiecesUpdating', {
        detail: { pieces: pieces.map(({ id }) => id) },
      });
      window.dispatchEvent(updateEvent);
      for (const member in updating.current.queue[action]) {
        delete updating.current.queue[action][member];
      }
      const nextPieces = await updatePieces({ action, pieces }).unwrap();
      const updatedEvent = new CustomEvent('PiecesUpdated', {
        detail: { nextPieces },
      });
      window.dispatchEvent(updatedEvent);
      updating.current.updating = false;
    } catch {
      const updatedEvent = new CustomEvent('PiecesUpdatedError', {
        detail: { pieces },
      });
      window.dispatchEvent(updatedEvent);
      updating.current.updating = false;
    }
  };

  useEffect(() => {
    const updateInterval = setInterval(() => {
      const actions = Object.keys(updating.current.queue);
      actions.forEach(async (action) => {
        const formattedAction = +action as PieceAction;

        const actionPieces = updating.current.queue[formattedAction] as QueueAction;

        const pieces = Object.values(actionPieces);
        if (pieces.length > 0 && !updating.current.updating) {
          updatePiecesFn(pieces, formattedAction);
        }
      });
    }, 1000);

    return () => {
      clearInterval(updateInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEvent = ({ event, action, source, force = false }: AddEvent) => {
    if (force) {
      updatePiecesFn([event], action);
      return;
    }

    if (!updating.current.queue[action]) {
      updating.current.queue[action] = {};
    }
    const originalUpdate = updating.current.queue[action][event.id];
    if (originalUpdate && source === 'user') {
      updating.current.queue[action][event.id] = {
        ...originalUpdate,
        ...event,
      };
    }
    updating.current.queue[action][event.id] = event;
  };

  return {
    addEvent,
  };
};
