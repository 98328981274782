import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';

import { PieceComment } from '@apiContract';

import { formatLongDateTimeString } from '@common/utils/stringFormatters';

import { sxAvatar, sxFlexStart, sxPB3, sxPT1 } from '../styles';

type CommentProps = {
  data: PieceComment;
};

const Comment: FC<CommentProps> = ({ data }) => {
  return (
    <Box>
      <ListItem component="div" disableGutters disablePadding sx={sxPB3}>
        <ListItemAvatar sx={sxFlexStart}>
          <Avatar alt={data.user.displayName} src={data.user.profileImageUrl} sx={sxAvatar}>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={data.user.displayName}
          primaryTypographyProps={{ variant: 'subtitle1' }}
          secondary={
            <>
              <Typography variant="caption" component="p" color="text.disabled">
                {formatLongDateTimeString(data.updated)}
              </Typography>
              <Typography color="text.primary" variant="body2" sx={sxPT1}>
                {data.text}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
    </Box>
  );
};

export default Comment;
