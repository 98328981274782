import { useEffect, useRef } from 'react';

interface ImagePreviewProps {
  imageDataUrl: string;
}

export type SetPreviewPaneDimensions = (width: number, height: number) => void;

export const ImagePreview = ({ imageDataUrl }: ImagePreviewProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const img = document.createElement('img');
    img.src = imageDataUrl;

    img.onload = () => {
      if (!canvasRef.current) return;
      canvasRef.current.width = img.width;
      canvasRef.current.height = img.height;
      const ctx = canvasRef.current.getContext('2d');
      ctx?.drawImage(img, 0, 0);
    };
  }, [imageDataUrl]);

  return (
    <div
      className="rd-image-preview"
      style={{
        display: 'flex',
        maxHeight: '100%',
      }}
    >
      <canvas style={{ maxWidth: '100%', objectFit: 'contain' }} ref={canvasRef} />
    </div>
  );
};
