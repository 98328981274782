import { Box, CircularProgress } from '@mui/material';

import { OpenCvProvider } from 'opencv-react';

import { ICropperRef, ImageCropper } from '@common/components/ImageCropper';
import { ImagePreview } from '@common/uiKit/ImagePreview';

import { sxCircleProgress, sxImageEditor } from './styles';

interface ImageEditorProps {
  imageBase64: string;
  croppingActive: boolean;
  cropperRef: React.MutableRefObject<ICropperRef | null> | undefined;
  actions: React.ReactNode;
  setOpenCvIsLoading?: (status: boolean) => void;
  isLoading?: boolean;
}

export const ImageEditor = ({
  imageBase64,
  actions,
  croppingActive = false,
  cropperRef,
  setOpenCvIsLoading,
  isLoading,
}: ImageEditorProps) => {
  return (
    <Box className="rd-image-editor" sx={sxImageEditor(croppingActive)}>
      {isLoading && (
        <Box sx={sxCircleProgress}>
          <CircularProgress size={80} />
        </Box>
      )}
      {croppingActive ? (
        <OpenCvProvider>
          <ImageCropper image={imageBase64} cropperRef={cropperRef} setOpenCvIsLoading={setOpenCvIsLoading} />
        </OpenCvProvider>
      ) : (
        <ImagePreview imageDataUrl={imageBase64} />
      )}

      {actions}
    </Box>
  );
};
