import { useOpenCv } from 'opencv-react';

import { CropPointsCoordinates } from '../index';
import { filterPointsCorrectly, getCornerArrayFromContour } from '../utils';

export const useOpenCvTools = () => {
  const { loaded, cv } = useOpenCv();

  // Commenting out previous implementation in case we would want to get back to it.
  // Remove the commented out code if new solution is accepted.
  /* const detectCorners = (image: HTMLImageElement): CropPointsCoordinates | null => {
    let cornerArray = new cv.MatVector();

    const dst = cv.imread(image);

    const ksize = new cv.Size(5, 5);
    cv.cvtColor(dst, dst, cv.COLOR_RGBA2GRAY, 0);
    cv.GaussianBlur(dst, dst, ksize, 5, 5, cv.BORDER_DEFAULT);
    cv.threshold(dst, dst, 175, 200, cv.THRESH_BINARY);
    let M = cv.Mat.ones(5, 5, cv.CV_8U);
    let anchor = new cv.Point(-1, -1);
    cv.dilate(dst, dst, M, anchor, 3, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
    cv.Canny(dst, dst, 0, 0);

    const contours = new cv.MatVector();

    const hierarchy = new cv.Mat();
    cv.findContours(dst, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

    let foundContour = new cv.MatVector();

    let sortableContours = [];
    for (let i = 0; i < contours.size(); i++) {
      let cnt = contours.get(i);
      let area = cv.contourArea(cnt, false);
      let perim = cv.arcLength(cnt, false);

      sortableContours.push({ areaSize: area, perimiterSize: perim, contour: cnt });
    }

    sortableContours = sortableContours
      .sort((item1, item2) => {
        return item1.areaSize > item2.areaSize ? -1 : item1.areaSize < item2.areaSize ? 1 : 0;
      })
      .slice(0, 5);

    let approx = new cv.Mat();
    cv.approxPolyDP(sortableContours[0].contour, approx, 0.02 * sortableContours[0].perimiterSize, true);

    if (approx.rows === 4) {
      foundContour = approx;
    } else {
      toast.error('No 4-corner large contour!');
      return null;
    }

    cornerArray = getCornerArrayFromContour(cv, foundContour, 1);

    const correctCorners = filterPointsCorrectly(cornerArray);

    const leftTopCoordinate = {
      x: correctCorners[0].corner.x / image.width,
      y: correctCorners[0].corner.y / image.height,
    };
    const rightTopCoordinate = {
      x: correctCorners[1].corner.x / image.width,
      y: correctCorners[1].corner.y / image.height,
    };
    const rightBottomCoordinate = {
      x: correctCorners[2].corner.x / image.width,
      y: correctCorners[2].corner.y / image.height,
    };
    const leftBottomCoordinate = {
      x: correctCorners[3].corner.x / image.width,
      y: correctCorners[3].corner.y / image.height,
    };

    return {
      topLeft: leftTopCoordinate,
      topRight: rightTopCoordinate,
      bottomLeft: leftBottomCoordinate,
      bottomRight: rightBottomCoordinate,
    };
  }; */

  const detectCorners = (image: HTMLImageElement): CropPointsCoordinates | null => {
    // Reading image and building cv.Mat() class instance.
    const imageMat = cv.imread(image);

    // Converting source colorful image to grayscaled.
    cv.cvtColor(imageMat, imageMat, cv.COLOR_RGBA2GRAY, 0);

    // Converting grayscaled image to black and white binary image using thresholding.
    // Otsu's algorithm will automatically calculate threshold value - third argument in .threshold()
    cv.threshold(imageMat, imageMat, 0, 255, cv.THRESH_OTSU);

    // Looking for contours in the thresholded image.
    const contours = new cv.MatVector();
    const hierarchy = new cv.Mat();
    cv.findContours(imageMat, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

    // Approximating contours to find ones that are close to rectangles.
    const approximatedContours = new cv.MatVector();
    for (let i = 0; i < contours.size(); i++) {
      const mat = new cv.Mat();
      const contour = contours.get(i);
      const perimeter = cv.arcLength(contour, false);
      // Third argument here is extremely important. It controls how rough algorithm can be while approximating.
      cv.approxPolyDP(contour, mat, 0.05 * perimeter, true);
      approximatedContours.push_back(mat);
      mat.delete();
      contour.delete();
    }

    // Looking through the approximated contours to find rectangles. Picking rectangle with the biggest perimeter.
    let rectangleContour = null;
    const perimeterTreshold = (image.width + image.height) * 2 * 0.2; // Contour perimeter must be at least 20% from image perimeter.
    for (let i = 0; i < approximatedContours.size(); ++i) {
      const contour = approximatedContours.get(i);
      if (contour.rows === 4) {
        const perimeter = cv.arcLength(contour, false);
        if (perimeter > perimeterTreshold && (!rectangleContour || perimeter > cv.arcLength(rectangleContour, false))) {
          rectangleContour = contour;
        }
      }
    }

    if (!rectangleContour) return null;

    // TODO: Look for a simpler way to convert contour to coordinates.
    const cornerArray = getCornerArrayFromContour(cv, rectangleContour);
    const correctCorners = filterPointsCorrectly(cornerArray);

    imageMat.delete();
    contours.delete();
    hierarchy.delete();
    approximatedContours.delete();

    return {
      topLeft: {
        x: correctCorners[0].corner.x / image.width,
        y: correctCorners[0].corner.y / image.height,
      },
      topRight: {
        x: correctCorners[1].corner.x / image.width,
        y: correctCorners[1].corner.y / image.height,
      },
      bottomLeft: {
        x: correctCorners[3].corner.x / image.width,
        y: correctCorners[3].corner.y / image.height,
      },
      bottomRight: {
        x: correctCorners[2].corner.x / image.width,
        y: correctCorners[2].corner.y / image.height,
      },
    };
  };

  const transform = (docCanvas: HTMLImageElement, cropPoints: CropPointsCoordinates, imageResizeRatio: number) => {
    const dst = cv.imread(docCanvas);

    const bR = cropPoints.bottomRight;
    const bL = cropPoints.bottomLeft;
    const tR = cropPoints.topRight;
    const tL = cropPoints.topLeft;

    // create source coordinates matrix
    const sourceCoordinates = [tL, tR, bR, bL].map((point) => [point.x / imageResizeRatio, point.y / imageResizeRatio]);

    // get max width
    const maxWidth = Math.max(bR.x - bL.x, tR.x - tL.x) / imageResizeRatio;
    // get max height
    const maxHeight = Math.max(bL.y - tL.y, bR.y - tR.y) / imageResizeRatio;

    // create dest coordinates matrix
    const destCoordinates = [
      [0, 0],
      [maxWidth - 1, 0],
      [maxWidth - 1, maxHeight - 1],
      [0, maxHeight - 1],
    ];

    // convert to open cv matrix objects
    const Ms = cv.matFromArray(4, 1, cv.CV_32FC2, [].concat(...(sourceCoordinates as any)));
    const Md = cv.matFromArray(4, 1, cv.CV_32FC2, [].concat(...(destCoordinates as any)));
    const transformMatrix = cv.getPerspectiveTransform(Ms, Md);
    // set new image size
    const dsize = new cv.Size(maxWidth, maxHeight);
    // perform warp
    cv.warpPerspective(dst, dst, transformMatrix, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());

    const canvas = document.createElement('canvas');
    cv.imshow(canvas, dst);

    dst.delete();
    Ms.delete();
    Md.delete();
    transformMatrix.delete();

    return canvas.toDataURL();
  };

  return { loaded, detectCorners, transform };
};
