import { type Theme } from '@mui/material';

import { Steps } from './types';

export const styles = {
  sxDrawer: {
    zIndex: 1300,
  },
  sxDrawerBox: {
    height: '100%',

    '.simplebar-content': {
      height: '100%',
    },
  },
  sxDrawerPaper: {
    sx: { width: 1, maxWidth: 480 },
  },
};

export const sxSearchIcon = {
  color: 'text.disabled',
};

export const sxSearchCircle = (theme: Theme) => ({
  color: theme.palette.grey[500],
});

export const sxButton = {
  px: '22px',
  py: '11px',
  width: 1,
};

export const sxP20 = {
  p: 2.5,
};

export const sxBackBtn = {
  display: { xs: 'flex', md: 'none' },
};

export const sxTitle = {
  position: { xs: 'absolute', md: 'static' },
  left: '50%',
  top: '50%',
  transform: { xs: 'translate(-50%, -50%)', md: 'unset' },
};

export const sxMobileGroupBtn = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 1.5,
  p: 2,
  flexDirection: 'row',
};

export const sxMobileGroupBgColor = {
  [Steps.Images]: { bgcolor: 'background.default' },
  [Steps.ReviewItems]: { bgcolor: 'background.paper' },
};

export const sxLoadingBtn = {
  flex: 1,
};

export const sxLoading = {
  box: {
    alignSelf: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '50%',
  },
  progress: { width: '100%', mt: 1, mb: 1.5 },
};

export const sxNotFound = {
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 480,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  description: { color: 'text.secondary', textAlign: 'center' },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
};
