import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Logo = ({ sx }: BoxProps) => {
  const theme = useTheme();
  const dotsColor = theme.palette.primary.main;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 650 642">
        <defs>
          <linearGradient y2="0.5" x2="0.94178" y1="0.5" x1="-4.55501" id="BG-1">
            <stop stopColor="#7ff0c7" offset="0" />
            <stop stopColor="#80fd7f" offset="1" />
          </linearGradient>
          <linearGradient xlinkHref="#BG-1" y2="309.49" x2="837.25" y1="309.49" x1="-157.01" id="BG-2" />
          <linearGradient xlinkHref="#BG-1" y2="770.51" x2="837.25" y1="770.51" x1="-157.01" id="BG-3" />
          <linearGradient xlinkHref="#BG-1" y2="770.51" x2="820.03" y1="770.51" x1="-174.23" id="BG-4" />
          <linearGradient xlinkHref="#BG-1" y2="544.05" x2="820.03" y1="544.05" x1="-174.23" id="BG-5" />
          <linearGradient xlinkHref="#BG-1" y2="309.49" x2="820.03" y1="309.49" x1="-174.23" id="BG-6" />
        </defs>
        <g>
          <title>Layer 1</title>
          <rect id="svg_1" fill="url(#BG-1)" rx="32.58" height="180.88" width="180.88" y="234.56" x="469.12" />
          <rect id="svg_2" fill={dotsColor} rx="14.3" height="79.36" width="79.36" y="511.78" x="519.88" />
          <rect id="svg_3" fill={dotsColor} rx="14.3" height="79.36" width="79.36" y="70.07" x="519.88" />
          <rect id="svg_4" fill={dotsColor} rx="14.3" height="79.36" width="79.36" y="281.27" x="285.32" />
          <rect id="svg_5" fill="url(#BG-2)" rx="32.58" height="180.88" width="180.88" y="0" x="234.56" />
          <rect id="svg_6" fill="url(#BG-3)" rx="32.58" height="180.88" width="180.88" y="461.02" x="234.56" />
          <rect id="svg_7" fill="url(#BG-4)" rx="32.58" height="180.88" width="180.88" y="461.02" x="0" />
          <rect id="svg_8" fill="url(#BG-5)" rx="32.58" height="180.88" width="180.88" y="234.56" x="0" />
          <rect id="svg_9" fill="url(#BG-6)" rx="32.58" height="180.88" width="180.88" y="0" x="0" />
        </g>
      </svg>
    </Box>
  );
};
