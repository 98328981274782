import React from 'react';
import { Navigate } from 'react-router';

import { selectVariable, useGetCompanyVariablesQuery } from '@api/companyVariable';
import { administrationRoutes } from '@routes';

import LoadingScreen from '@common/components/LoadingScreen';
import { companyVariablesNames } from '@common/config/companyVariables';

interface AccountSetupGuardProps {
  children: React.ReactElement;
}

export const AccountSetupGuard = ({ children }: AccountSetupGuardProps) => {
  const { onboardingCompleteVariable, variableIsLoading } = useGetCompanyVariablesQuery(undefined, {
    selectFromResult: (result) => ({
      onboardingCompleteVariable: selectVariable(result.data, companyVariablesNames.onboarding),
      variableIsLoading: result.isUninitialized || result.isFetching,
    }),
  });

  if (variableIsLoading) return <LoadingScreen />;

  if (!onboardingCompleteVariable || onboardingCompleteVariable.value !== 'true')
    return <Navigate to={administrationRoutes.accountSetup} />;

  return children;
};
