import { OptionsObject, enqueueSnackbar, useSnackbar } from '@minimalsComponents/snackbar';

export const defaultSnackbarOptions: OptionsObject = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  className: 'rd-snackbar',
};

export const useRdSnackbar = () => {
  // TODO: fix next problem
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { enqueueSnackbar } = useSnackbar();

  const notify = (message: string, options: OptionsObject = {}) => {
    enqueueSnackbar(message, {
      ...defaultSnackbarOptions,
      ...options,
    });
  };

  return { notify };
};

export const enqueueRdSnackbar = (message: string, options: OptionsObject = {}) => {
  enqueueSnackbar(message, {
    ...defaultSnackbarOptions,
    ...options,
  });
};
