import { Dialog, DialogProps, useTheme } from '@mui/material';
import { useEffect } from 'react';

import { DarkThemeProvider } from './DarkThemeProvider';

const detectChromium = () => {
  const vendor = (navigator?.vendor ?? '').toLowerCase();
  const userAgent = (navigator?.userAgent || '').toLowerCase();
  const match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
  return match !== null;
};

const isChromium = detectChromium();

export const DialogDarkFullscreen = (props: DialogProps) => {
  const { children, sx, PaperProps, fullScreen, open, ...dialogProps } = props;

  const theme = useTheme();

  // Known issue/bug with fillscreen in Firefox: During the lifecycle of a single browser tab, if user, during the
  // first time he was asked to provide permission to access the camera does not accepts or declines the permission
  // prompt and just reloads the page, next time when browser again needs to exit fullscreen and prompt user to provide
  // camera permission, neither of this will happen.
  // Also, if camera permission in Firefox is not granted for the whole domain (https://localhost:5100), only one tab,
  // which was first provided with permission, would be able to use the camera - others will encounter issue described above.
  useEffect(() => {
    // If current browser is not Chrome or build on Chromium, avoiding any fullscreen manipulations.
    if (!isChromium) return;

    let isInitiator = false;

    if (open && document.documentElement?.requestFullscreen) {
      document.documentElement
        .requestFullscreen({ navigationUI: 'show' })
        .then(() => {
          isInitiator = true;
        })
        .catch(() => {
          // TODO: Consider using this error calback to gather statistics on this problem and send it to Sentry.
          // console.error('An error occurred while trying to switch into fullscreen mode:', err);
        });
    }

    return () => {
      if (isInitiator && document.fullscreenElement) {
        document.exitFullscreen?.();
      }
    };
  }, [open]);

  return (
    <DarkThemeProvider>
      <Dialog
        open={open}
        sx={{
          ...sx,
          height: '100%',
          [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-container .MuiDialog-paper': {
              margin: 0,
            },
          },
        }}
        PaperProps={{ ...PaperProps, sx: { maxHeight: '100%', backgroundColor: 'black', ...PaperProps?.sx } }}
        fullScreen={fullScreen ?? true}
        {...dialogProps}
      >
        {children}
      </Dialog>
    </DarkThemeProvider>
  );
};
