import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import { useSettingsContext } from '@minimalsComponents/settings';
import { customShadows } from '@minimalsTheme/custom-shadows';
import { componentsOverrides } from '@minimalsTheme/overrides';
import { palette } from '@minimalsTheme/palette';
import { shadows } from '@minimalsTheme/shadows';
import { typography } from '@minimalsTheme/typography';

type Props = {
  children: ReactNode;
};

export const DarkThemeProvider = ({ children }: Props) => {
  const { themeDirection } = useSettingsContext();

  const themeOptions = useMemo(
    () => ({
      palette: palette('dark'),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows('dark'),
      customShadows: customShadows('dark'),
    }),
    [themeDirection],
  );

  const theme = createTheme(themeOptions as ThemeOptions);
  theme.components = componentsOverrides(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
