import { SvgIconProps } from '@mui/material';
import { SxProps } from '@mui/system';

import { Piece, RecipientAutoDetection } from '@apiContract';

import { SortingOrder, WorkflowMail, WorkflowPackages } from '@common/types/contractHelpers';

export enum Steps {
  ReceiveConfig = 'config',
  Photo = 'photo',
  Review = 'review',
  Edit = 'edit',
  Crop = 'crop',
  ReviewItems = 'reviewitems',
  Images = 'images',
  Loading = 'loading',
  NotFound = 'notFound',
}

export enum DrawerTypes {
  User = 1,
  Location = 2,
  Comments = 3,
  Barcode = 4,
}

export type IconColors = 'success' | 'warning' | 'error' | 'default';
export type ButtonTypes = 'primary' | 'secondary' | 'inherit';

export type ButtonConfig = {
  label: string;
  type: ButtonTypes;
  onClick: () => void;
  disabled?: boolean;
  captureDropdown?: boolean,
};

export type IconConfig = {
  icon: string;
  onClick: () => void;
  isLoading?: boolean;
  color?: IconColors;
  sx?: SxProps;
  retryContent?: number;
  tooltip?: string;
  disabled?: boolean;
};

export type PieceStatus = 'created' | 'queued' | 'processing' | 'completed' | 'failed' | 'detected' | 'loadedFromServer';

export type IdV4 = string;
export type PieceId = number;

export type CaptureId = IdV4 | PieceId;

export type CapturePiece = {
  id: CaptureId;
  status: PieceStatus;
  photo?: File | string;
  piece: Piece | null;
  editImage?: string;
  pieceDetection: RecipientAutoDetection | null;
  selectedPieceDetection: RecipientAutoDetection | null;
  isUpdating: boolean;
  assignUserError: boolean;
  isAutoCroppedCompleted: boolean
};

export type MapCapturePiece = Record<CaptureId, CapturePiece>;
export type MapDetectedRecipient = Record<PieceId, RecipientAutoDetection>;

export type OnPieceCreateType = {
  fileId: string;
  piece?: Piece;
  status: PieceStatus;
};

export type OnFileAddParams = {
  fileId: string;
  sessionId: string;
  file?: File | string;
  resize?: boolean;
};

export type UploadRef = {
  upload: () => void;
  removeAll: () => void;
} | null;

export enum RecipientStatus {
  assigned = 'assigned',
  validate = 'validate',
  unassigned = 'unassigned',
}

export enum ColorStatus {
  assigned = 'success',
  validate = 'warning',
  unassigned = 'error',
}

export enum DialogProcess {
  Uploading = 'Uploading',
  Unresolved = 'Unresolved',
  UnresolvedByClose = 'UnresolvedByClose',
}

export type ReceiveSettings = {
  ReceiveMailWorkflow: WorkflowMail;
  ReceivePackagesWorkflow: WorkflowPackages;
  ReceiveSortOrder: SortingOrder;
};

export interface ReceiveToggleButtonGroupConfig<T> {
  value: T;
  label: string;
  Icon: React.FC<SvgIconProps>;
  disabled?: boolean;
}
