import { Box, Button, SxProps } from '@mui/material';

interface CaptureButtonProps {
  onClick: () => any;
  width?: number;
  sx?: SxProps;
}

export const CaptureButton = ({ width = 70, sx, onClick }: CaptureButtonProps) => {
  const height = width;
  const backgroundStyle = {
    backgroundColor: 'white',
    border: '10px double rgba(255, 255, 255, 1)',
  };

  return (
    <Button
      sx={{
        width,
        height,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          minWidth: width - 10,
          minHeight: height - 10,
          borderRadius: '50%',
          backgroundClip: 'padding-box',
          ...backgroundStyle,
        }}
      ></Box>
    </Button>
  );
};
