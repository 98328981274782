import { Box, Button, Stack, Typography } from '@mui/material';

import { useGetMyProfileQuery } from '@api/user';

export default function NavDocs() {
  const { data: currentUser } = useGetMyProfileQuery();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <Box component="img" src="/assets/illustrations/illustration_docs.svg" />

      <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
        <Typography gutterBottom variant="subtitle1">
          {`Hi, ${currentUser?.displayName}`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {'Need help?\n Please visit our Help Center'}
        </Typography>
      </Stack>

      <Button
        href="https://receiveddigital.com/help-center/"
        target="_blank"
        rel="noopener"
        variant="contained"
        color="primary"
      >
        Help Center
      </Button>
    </Stack>
  );
}
