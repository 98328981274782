import { useCallback, useState } from 'react';
import Draggable from 'react-draggable';

import { Coordinates, CropPointId } from '../index';

export interface CropPointProps {
  x: number;
  y: number;
  pointId: CropPointId;
  onStop: (coordinates: any, pointId: CropPointId) => void;
  onDrag: (coordinates: any, pointId: CropPointId) => void;
}

export const CropPoint = ({ x, y, pointId, onStop: externalOnStop, onDrag: externalOnDrag }: CropPointProps) => {
  const [isActive, setIsActive] = useState(false);
  const scale = isActive ? 1.5 : 1;

  const onStart = useCallback(() => {
    setIsActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalOnDrag]);

  const onDrag = useCallback(
    (_: any, coordinates: Coordinates) => {
      externalOnDrag(coordinates, pointId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [externalOnDrag],
  );

  const onStop = useCallback(
    (_: any, coordinates: Coordinates) => {
      setIsActive(false);
      externalOnStop(coordinates, pointId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [externalOnDrag],
  );

  return (
    <Draggable
      bounds="parent"
      position={{
        x,
        y,
      }}
      onStart={onStart}
      onDrag={onDrag}
      onStop={onStop}
    >
      <div
        className={`rd-cropper-point-${pointId}`}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 7,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: -15 * scale,
            left: -15 * scale,
            width: 30 * scale,
            height: 30 * scale,
            border: '4px solid white',
            borderRadius: '100%',
            cursor: isActive ? 'grabbing' : 'grab',
          }}
        ></div>
      </div>
    </Draggable>
  );
};
