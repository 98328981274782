import type {
  ReceiveSession,
  ReceiveSessionFilteredListParams,
  ReceiveSessionPagedList,
  ReceiveSessionPagedListResponse,
  ReceiveSessionRequest,
  ReceiveSessionResponse,
} from '@apiContract';

import { receivedDigitalApi } from './index';

const receiveSessionApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    createSession: builder.mutation<ReceiveSessionResponse, ReceiveSessionRequest>({
      query: (requestData) => ({
        url: 'ReceiveSession',
        method: 'POST',
        body: requestData,
      }),
    }),
    getSessions: builder.query<ReceiveSessionPagedList, ReceiveSessionFilteredListParams & { st?: string | null }>({
      query: ({ st, ...requestData }) => ({
        url: 'ReceiveSession/filtered',
        params: requestData,
      }),
      transformResponse: (response: ReceiveSessionPagedListResponse): ReceiveSessionPagedList => response.objectData,
    }),
    getSessionById: builder.query<ReceiveSession, string | null>({
      query: (id) => ({
        url: `ReceiveSession/${id}`,
      }),
      providesTags: (response) => [{ type: 'RS', id: response?.id }],
      transformResponse: (response: ReceiveSessionResponse): ReceiveSession => response.objectData,
    }),
  }),
});

export const { useCreateSessionMutation, useGetSessionsQuery, useGetSessionByIdQuery } = receiveSessionApi;
