import CloseIcon from '@mui/icons-material/Close';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { AppBar, Box, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { PickupSessionResponse } from '@apiContract';
import { m } from 'framer-motion';
// @ts-expect-error Type definitions for this library are not available.
import QRious from 'qrious';
import { varHover } from 'src/components/animate';

import { apiRequest } from '@common/api/apiRequest';
import { getQrCode } from '@common/api/qrCode';
import LoadingScreen from '@common/components/LoadingScreen';

const qrCodeSize = 400;

export const RecipientQrCode = () => {
  const [qrious] = useState(() => new QRious({ size: qrCodeSize }));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qrCodeDataURL, setQrCodeDataURL] = useState<string>('');

  useEffect(() => {
    let awaitingResponse = true;

    const dataRequest = async () => {
      const response = await apiRequest<PickupSessionResponse>(getQrCode, null, false);

      if (!awaitingResponse) return;

      qrious.value = response.objectData.id;
      setQrCodeDataURL(qrious.toDataURL());
    };

    if (dialogOpen) {
      dataRequest();
    } else {
      setQrCodeDataURL('');
    }

    return () => {
      awaitingResponse = false;
    };
  }, [qrious, dialogOpen]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={() => setDialogOpen(true)}
      >
        <QrCodeIcon />
      </IconButton>

      <Dialog open={dialogOpen} fullScreen PaperProps={{ sx: { m: '0 !important' } }}>
        <AppBar sx={{ position: 'relative' }} color="primary">
          <Toolbar>
            <IconButton color="inherit" disableRipple onClick={() => setDialogOpen(false)}>
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              My QR Code
            </Typography>
          </Toolbar>
        </AppBar>

        {qrCodeDataURL ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, overflow: 'auto' }}>
            <img
              src={qrCodeDataURL}
              alt="My QR Code"
              style={{
                width: '100%',
                height: '100%',
                maxWidth: qrCodeSize,
                maxHeight: qrCodeSize,
                objectFit: 'contain',
              }}
            />
          </Box>
        ) : (
          <>{dialogOpen && <LoadingScreen sx={{ position: 'relative', backgroundColor: 'transparent' }} />}</>
        )}

        <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center', px: 1, py: 3 }}>
          Maximize your screen brightness for scanning.
        </Typography>
      </Dialog>
    </>
  );
};
