export class PromiseQueue {
  actions: (() => Promise<unknown>)[];
  parallelActionsCount: number = 4;
  doneCB: ((result: unknown[]) => void) | undefined;
  result: unknown[] = [];
  taskDone: number = 0;
  totalTasks: number = 0;

  constructor(actions: (() => Promise<unknown>)[], doneCB?: (result: unknown[]) => void, parallelActionsCount = 4) {
    this.actions = actions;
    this.parallelActionsCount = parallelActionsCount;
    this.doneCB = doneCB;
  }

  runTaskManager = async () => {
    while (this.actions.length) {
      const action = this.actions.pop();
      if (action) {
        this.result.push(await action());
      }
      this.taskDone += 1;
    }
    if (this.taskDone === this.totalTasks && this.doneCB) {
      this.doneCB(this.result);
    }
  }

  run = () => {
    this.totalTasks = this.actions.length;
    this.taskDone = 0;
    for (let i = 0; i < this.parallelActionsCount; i++) {
      this.runTaskManager();
    }
  }
}
