import { Box, Stack, SvgIconProps, ToggleButton, Tooltip } from '@mui/material';

import { sxFlex1, sxMR1 } from '../styles';

export interface ToggleButtonGroupConfig<ValueType> {
  value: ValueType;
  label: string;
  Icon: React.FC<SvgIconProps>;
  disabled?: boolean;
}

interface ToggleButtonGroupProps<ValueType> {
  config: ToggleButtonGroupConfig<ValueType>[];
  value: ValueType;
  disabled?: boolean;
  onChange: (value: ValueType) => void;
  tooltip: string;
}

export const ToggleButtonGroup = <ValueType extends string>({
  config,
  value,
  disabled: groupDisabled,
  onChange,
  tooltip,
}: ToggleButtonGroupProps<ValueType>) => {
  return (
    <Stack spacing={2} sx={{ flexDirection: { xs: config.length < 3 ? 'row' : 'column', md: 'row' } }}>
      {config.map(({ value: configItemValue, label, Icon, disabled }) => (
        <Tooltip arrow title={tooltip} key={configItemValue}>
          <Box component="span" sx={sxFlex1}>
            <ToggleButton
              value={configItemValue}
              selected={configItemValue === value}
              onChange={(_, newValue: ValueType) => {
                onChange(newValue);
              }}
              disabled={groupDisabled || disabled}
              color="primary"
              size="large"
              fullWidth
            >
              <Icon sx={sxMR1} /> {label}
            </ToggleButton>
          </Box>
        </Tooltip>
      ))}
    </Stack>
  );
};
