import { Box, Button, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCreateUserMutation } from '@api/user';
import { EmptyResultResponse, User } from '@apiContract';
import { yupResolver } from '@hookform/resolvers/yup';

import { enqueueRdSnackbar } from '@common/uiKit/RdSnackbar';

import { sxButton, sxFormTab, sxPT20 } from '../styles';

type FormValues = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  roles: string[];
};

type FormProps = {
  changeTab: () => void;
  assignUser: (user?: User) => void;
};
const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  userName: yup.string(),
  email: yup.string().required('Email is required').email('Invalid email format'),
  roles: yup.array().of(yup.string()).min(1),
});

const Form: FC<FormProps> = ({ changeTab, assignUser }) => {
  const [createUser, { isLoading }] = useCreateUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { roles: ['Recipient'] },
  });

  const onSubmit = (data: FormValues) => {
    createUser(data)
      .unwrap()
      .then((user: User) => {
        reset();
        enqueueRdSnackbar('User created', { variant: 'success' });
        assignUser(user);
        changeTab();
      })
      .catch((e: { data: EmptyResultResponse }) => {
        enqueueRdSnackbar(e.data?.userMessage || "Couldn't create a new user", {
          variant: 'error',
        });

        if (e.data?.validationResult?.errors) {
          const validationErrors = e.data.validationResult.errors;
          Object.keys(validationErrors).forEach((key) => {
            const errorTitle = validationErrors[key].join(', ');
            setError(key as keyof FormValues, { message: errorTitle });
          });
        }
      });
  };

  return (
    <Box display="flex" sx={sxFormTab} component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" justifyContent="space-between" flexDirection="column" gap={3}>
        <Typography variant="h6">Create User</Typography>

        <TextField
          fullWidth
          label="First Name"
          inputProps={{ ...register('firstName') }}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          required
        />

        <TextField
          fullWidth
          label="Last Name"
          inputProps={{ ...register('lastName') }}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          required
        />

        <TextField
          fullWidth
          label="Email Address"
          inputProps={{ ...register('email') }}
          error={!!errors.email}
          helperText={errors.email?.message}
          required
        />

        <TextField fullWidth label="User ID" inputProps={{ ...register('userName') }} />
      </Box>

      <Box sx={{ ...sxPT20 }} display="flex" gap={2}>
        <Button variant="outlined" sx={sxButton} size="large" fullWidth onClick={changeTab}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={sxButton}
          size="large"
          disabled={isLoading}
          fullWidth
        >
          Create User
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
