import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const primary = {
  lighter: '#DFECFB',
  light: '#9CBCEB',
  main: '#5478BF',
  dark: '#2A4389',
  darker: '#101F5B',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#D9FBFD',
  light: '#8EE3F8',
  main: '#43B3E8',
  dark: '#216AA7',
  darker: '#0C366F',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#EDFBDA',
  light: '#B8EA8D',
  main: '#68BA3F',
  dark: '#34851F',
  darker: '#11590C',
  contrastText: '#ffffff',
};

export const warning = {
  lighter: '#FEF7D6',
  light: '#FCDF84',
  main: '#F7BC33',
  dark: '#B17A19',
  darker: '#764809',
  contrastText: '#212B36',
};

export const error = {
  lighter: '#FFE8D4',
  light: '#FFA87F',
  main: '#FF4E2B',
  dark: '#B71715',
  darker: '#7A0818',
  contrastText: '#FFFFFF',
};

export const purple = {
  lighter: '#F4D6FD',
  light: '#D085F4',
  main: '#9335DC',
  dark: '#561A9E',
  darker: '#2A0A69',
  contrastText: '#FFFFFF',
};

export const orange = {
  lighter: '#FFF0D6',
  light: '#FFC685',
  main: '#FF8A35',
  dark: '#B74A1A',
  darker: '#7A1F0A',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  purple,
  orange,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
