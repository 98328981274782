import type {
  BooleanResponse,
  Container,
  ContainerFilterStorageGroups,
  ContainerPagedList,
  ContainerPagedListResponse,
  ContainerRequest,
  ContainerResponse,
  ContainerType,
  ContainerTypeArrayResponse,
  Workflow,
} from '@apiContract';

import { receivedDigitalApi } from './index';

export type GetContainersFilteredRequest = {
  Workflows?: Workflow[];
  StorageGroups?: ContainerFilterStorageGroups;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  ItemsPerPage?: number;
  PageNumber?: number;
};

export interface GetContainerByRequestRequest {
  CreatedDate?: string;
  ContainerTypeId?: string;
  ContainerNumber?: string;
  SortOrder?: string;
  UserId?: string;
}

export const containerApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getContainerTypes: builder.query<ContainerType[], void>({
      query: () => 'Container/containerTypes',
      transformResponse: (response: ContainerTypeArrayResponse) => response.objectData,
    }),

    getContainerByRequest: builder.query<Container, GetContainerByRequestRequest>({
      query: (requestParams) => ({
        url: 'Container/containerByRequest',
        params: requestParams,
      }),
      transformResponse: (response: ContainerResponse) => response.objectData,
    }),

    getContainerById: builder.query<Container, string>({
      query: (id) => `Container/${id}`,
      providesTags: (result) => (result?.id ? [{ type: 'Container' as const, id: result.id }] : []),
      transformResponse: (response: ContainerResponse) => response.objectData,
    }),

    getContainersFiltered: builder.query<ContainerPagedList, GetContainersFilteredRequest>({
      query: (params) => ({
        url: 'Container/filtered',
        params,
      }),
      providesTags: (result) => {
        const tagsList = result?.items?.map((container) => ({ type: 'Container' as const, id: container.id })) || [];
        return ['Containers', ...tagsList];
      },
      transformResponse: (response: ContainerPagedListResponse) => response.objectData,
    }),

    createContainer: builder.mutation<Container, ContainerRequest>({
      query: (requestData) => ({
        url: 'Container',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['Containers'],
      transformResponse: (response: ContainerResponse) => response.objectData,
    }),

    updateContainer: builder.mutation<Container, ContainerRequest>({
      query: (requestData) => ({
        url: 'Container',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'Container', id: result.id }] : [];
      },
      transformResponse: (response: ContainerResponse) => response.objectData,
    }),

    deleteContainer: builder.mutation<BooleanResponse, number>({
      query: (containerId) => ({
        url: `Container/${containerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Containers'],
    }),
  }),
});

export const {
  useGetContainerTypesQuery,
  useGetContainerByRequestQuery,
  useLazyGetContainerByIdQuery,
  useLazyGetContainerByRequestQuery,
  useGetContainersFilteredQuery,
  useUpdateContainerMutation,
  useDeleteContainerMutation,
  useCreateContainerMutation,
} = containerApi;
