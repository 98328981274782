import { ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';

import Label from '@minimalsComponents/label';

import { sxItemStatus, sxStatusTitle } from '../../../styles';
import { ColorStatus, GroupPiecesStatus, ProgressStatus, RecipientStatus } from '../../../types';

type ItemProps = {
  status: ProgressStatus;
  handleClick: (status: ProgressStatus) => void;
  count: number;
  isActive: boolean;
};

const Item: FC<ItemProps> = ({ count, handleClick, status, isActive }) => {
  const color = status === GroupPiecesStatus.failed ? 'error' : ColorStatus[status as RecipientStatus];

  if (status === GroupPiecesStatus.failed && !count) {
    return null;
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => handleClick(status)} sx={sxItemStatus}>
        <ListItemText
          primary={
            <Typography
              color={!isActive ? 'text.secondary' : ''}
              variant={isActive ? 'subtitle2' : 'body2'}
              sx={sxStatusTitle}
            >
              {status}
            </Typography>
          }
        />
        <Label variant={isActive ? 'filled' : 'soft'} color={color}>
          {count}
        </Label>
      </ListItemButton>
    </ListItem>
  );
};

export default Item;
