import * as signalR from '@microsoft/signalr';

const defaultApiUrl = `${import.meta.env.VITE_APP_API}/notificationhub`;

export class SignalRConnection {
  connection: signalR.HubConnection | null = null;

  events: Map<string, (notification: any) => void>;

  apiUrl: string;

  connectionState: number = 0;

  constructor(apiUrl = defaultApiUrl) {
    this.events = new Map();
    this.apiUrl = apiUrl;
  }

  /**
   * @summary Connects to the SignalR hub and listens for notifications.
   * @param {string} token - The access token for the user.
   */
  connect = async (token: string) => {
    if (!this.connection) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(this.apiUrl, {
          accessTokenFactory: () => token,
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();
      try {
        await this.connection.start();
        this.connectionState = 2;
        this.connection.on('onDisconnect', () => (this.connectionState = 1));
        this.events.forEach((value, key) => this.connection?.on(key.split('::')[0], value));
      } catch (error: any) {
        this.connection = null;

        // https://github.com/dotnet/aspnetcore/issues/39079
        if (error.message && !/Status code '40[13]'/i.test(error.message)) {
          setTimeout(() => this.connect(token), 5000);
        }
      }
    }
  };

  /**
   * @summary Disconnects from the SignalR hub.
   * @returns {void}
   */
  disconnect = () => {
    this.connection?.stop();
    this.connection = null;
    this.connectionState = 0;
    this.events.clear();
  };

  /**
   * Registers an event handler for the specified method.
   *
   * @param method - The name of the event method to register.
   * @param endpoint - The endpoint to listen to.
   * @param fn - The event handler to invoke when the event is received.
   */
  registerEvent = (method: string, endpoint: string, fn: (notification: any) => void) => {
    const methodKey = `${method}::${endpoint}`;
    const methodFn = this.events.get(methodKey);
    if (methodFn) {
      this.connection?.off(method, methodFn);
    }
    this.events.set(methodKey, fn);
    this.connection?.on(method, fn);
  };

  /**
   * Unregister an event handler for the specified method.
   *
   * @param method - The name of the event method to unregister.
   * @param endpoint - The endpoint to listen to.
   */
  unregisterEvent = (method: string, endpoint: string) => {
    const methodKey = `${method}::${endpoint}`;
    const methodFn = this.events.get(methodKey);
    if (methodFn) {
      this.connection?.off(method, methodFn);
      this.events.delete(methodKey);
    }
  };
}

export const rdBackSignalr = new SignalRConnection();
