import { IconConfig } from '../../types';
import DefaultIconWithLoader from './components/DefaultIconWithLoader';
import RetryIconWithLoader from './components/RetryIconWithLoader';

const IconWithLoader = ({ retryContent, ...other }: IconConfig) => {
  if (retryContent) {
    return <RetryIconWithLoader retryContent={retryContent} {...other} />;
  }

  return <DefaultIconWithLoader {...other} />;
};

export default IconWithLoader;
