import {
  CameraAltRounded,
  EmailRounded,
  FolderSharedRounded,
  InboxRounded,
  LinkedCameraRounded,
  LocalShippingRounded,
  MarkunreadMailboxRounded,
  PinDropRounded,
  UploadRounded,
} from '@mui/icons-material';

import {
  CaptureMethod,
  ContainerType,
  Piece,
  PieceType,
  RecipientAutoDetection,
  RecipientAutoDetectionStatus,
  Workflow,
} from '@apiContract';
import { getContainerTypeIdByWorkflow } from 'src/common/utils/container';
import { getDateStringWithoutTime } from 'src/common/utils/time';
import { v4 } from 'uuid';

import { WorkflowNameByEnum } from '@common/types/contractHelpers';

import { PackageIcon } from '@src/assets/icons/PackageIcon';

import {
  ButtonTypes,
  CapturePiece,
  ColorStatus,
  IconColors,
  OnFileAddParams,
  ReceiveSettings,
  ReceiveToggleButtonGroupConfig,
  RecipientStatus,
  Steps,
} from './types';

export const tooltips = {
  PersonPinRounded: 'Assign',
  LocationOnRounded: 'Edit Location',
  AddCommentRounded: 'Add Comment',
  CropRotateRounded: 'Crop and Rotate',
};

export const recipientAlerts = {
  [RecipientAutoDetectionStatus.Identified]: 'Recipient identified successfully',
  [RecipientAutoDetectionStatus.IdentifiedBasedOnPreviousAssignment]:
    'Recipient identified based on previous assignment',
  [RecipientAutoDetectionStatus.IdentifiedVerificationRequired]: 'Recipient identified but requires validation',
  [RecipientAutoDetectionStatus.IdentifiedMultipleMatches]: 'Possible recipient matches identified',
  [RecipientAutoDetectionStatus.IdentifiedAndNotExist]: 'Recipient identified but does not exist',
  [RecipientAutoDetectionStatus.Failed]: 'Unable to identify recipient',
};

type OnClickType = (prop?: any) => void;

export const getButtonConfig = (
  label: string,
  type: ButtonTypes,
  onClick: OnClickType = () => {},
  disabled = false,
  options: { captureDropdown?: boolean } = {},
) => ({
  label,
  type,
  onClick,
  disabled,
  ...options,
});

export const getIconConfig = (
  icon: string,
  isLoading: boolean,
  onClick: () => void,
  options: {
    color?: IconColors;
    disabled?: boolean;
  } = {},
) => ({
  icon,
  isLoading,
  onClick,
  color: options.color,
  tooltip: tooltips[icon as keyof typeof tooltips],
  disabled: options.disabled,
});

export const getRecipientStatus = (piece?: Piece | null, status?: RecipientAutoDetectionStatus) => {
  if (piece?.recipient) {
    return RecipientStatus.assigned;
  }
  switch (status) {
    case RecipientAutoDetectionStatus.IdentifiedVerificationRequired: {
      return RecipientStatus.validate;
    }
    default:
      return RecipientStatus.unassigned;
  }
};

export const getColorPieceStatus = (piece?: Piece | null, status?: RecipientAutoDetectionStatus) => {
  const recipientStatus = getRecipientStatus(piece, status);
  if (recipientStatus) {
    return ColorStatus[recipientStatus];
  }

  return;
};

export const getPieceDetection = (capturePiece?: CapturePiece | null) => {
  if (!capturePiece) {
    return null;
  }
  return capturePiece.selectedPieceDetection || capturePiece.pieceDetection;
};

export const parseUploadFiles = (uploadedFiles: Array<File | string>, sessionId: string) => {
  return uploadedFiles.reduce((acc: Array<OnFileAddParams>, file) => {
    const id = v4();
    acc.push({ file, fileId: id, sessionId });
    return acc;
  }, []);
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getPieceRecipientParam = (
  piece: Piece | null,
  detection: RecipientAutoDetection | null,
  param: 'displayName' | 'userName',
) =>
  piece?.recipient?.[param] ||
  (detection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired && detection?.recipient?.[param]);

export const getDefaultValues = (containerTypes: ContainerType[], receiveSettings: ReceiveSettings) => ({
  [Workflow.DateReceived]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.DateReceived]),
    createdDate: getDateStringWithoutTime(new Date()),
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.Carrier]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.Carrier]),
    createdDate: null,
    containerNumber: 'USPS',
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.MailBox]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.MailBox]),
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.Location]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.Location]),
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.HighDensityMail]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.HighDensityMail]),
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.Lockers]: {
    containerTypeId: undefined,
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
});

export const captureMethodOptions: ReceiveToggleButtonGroupConfig<CaptureMethod>[] = [
  {
    value: CaptureMethod.Single,
    label: 'Single',
    Icon: CameraAltRounded,
  },
  {
    value: CaptureMethod.Automated,
    label: 'Automated',
    Icon: LinkedCameraRounded,
    disabled: true,
  },
  {
    value: CaptureMethod.Images,
    label: 'Upload images',
    Icon: UploadRounded,
  },
];

export const itemTypeOptions: ReceiveToggleButtonGroupConfig<PieceType>[] = [
  {
    value: PieceType.Mail,
    label: 'Mail',
    Icon: EmailRounded,
  },
  {
    value: PieceType.Package,
    label: 'Packages',
    Icon: PackageIcon,
  },
];

export const workflowMailOptions: ReceiveToggleButtonGroupConfig<Workflow>[] = [
  {
    value: Workflow.DateReceived,
    label: 'Date received',
    Icon: InboxRounded,
  },
  {
    value: Workflow.HighDensityMail,
    label: 'High density mail',
    Icon: FolderSharedRounded,
  },
  {
    value: Workflow.MailBox,
    label: 'Mailbox',
    Icon: MarkunreadMailboxRounded,
  },
];

export const workflowPackagesOptions: ReceiveToggleButtonGroupConfig<Workflow>[] = [
  {
    value: Workflow.Location,
    label: 'Location',
    Icon: PinDropRounded,
  },
  {
    value: Workflow.Carrier,
    label: 'Carrier',
    Icon: LocalShippingRounded,
  },
];

export const nextStepReceiveConfig = {
  [CaptureMethod.Single]: Steps.Photo,
  [CaptureMethod.Automated]: Steps.Photo,
  [CaptureMethod.Images]: Steps.Images,
};

export const settingsNames = ['ReceiveMailWorkflow', 'ReceivePackagesWorkflow', 'ReceiveSortOrder'];
