import { Box, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { GetUsersFilteredRequest, useGetUsersFilteredQuery } from '@api/user';
import { RecipientAutoDetectionStatus, User } from '@apiContract';

import { getPieceDetection } from '../../helper';
import { useAssignmentRecipient } from '../../hooks/useAssignmentRecipient';
import { AddEvent } from '../../hooks/useUpdateQueue';
import { CapturePiece } from '../../types';
import Footer from '../Footer';
import Search from '../Search';
import Title from '../Title';
import AssignTO from './components/AssignTo';
import List from './components/List';
import UserForm from './components/UserForm';
import { sxP20, sxPaper, sxTab, sxTabsSection } from './styles';

const ITEMS_PER_PAGE = 20;

const initialFilters = {
  UserRole: ['Recipient'],
  ItemsPerPage: ITEMS_PER_PAGE,
  PageNumber: 0,
  SearchText: '',
  UserStatus: 1,
};

type UserListProps = {
  file: CapturePiece;
  onClose: () => void;
  addEvent: (param: AddEvent) => void;
};

const UserList = (props: UserListProps) => {
  const { file, onClose, addEvent } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [filters, setFilters] = useState<GetUsersFilteredRequest>({
    ...initialFilters,
    SearchText: file.pieceDetection?.recipientSearchString ?? '',
  });

  const [items, setItems] = useState<Array<User>>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(file?.piece?.recipient);
  const { data = {}, isFetching } = useGetUsersFilteredQuery(filters);
  const { assignRecipientToPiece } = useAssignmentRecipient(addEvent);

  const loadNextPage = useCallback((startIndex: number) => {
    setFilters((prev) => ({
      ...prev,
      PageNumber: Math.floor(startIndex / ITEMS_PER_PAGE),
      ItemsPerPage: ITEMS_PER_PAGE,
    }));
  }, []);

  const selectUser = (user: User) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    if (data.pageNumber === 0) {
      setItems(data.items || []);
    } else {
      setItems((prev) => [...prev, ...(data.items || [])]);
    }
  }, [data]);

  useEffect(() => {
    const pieceDetection = getPieceDetection(file);
    if (
      !selectedUser &&
      pieceDetection?.recipient &&
      pieceDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired
    ) {
      setSelectedUser(pieceDetection.recipient);
    }
  }, [file, selectedUser]);

  const onSearch = (value: string) => {
    setFilters({ ...initialFilters, SearchText: value, PageNumber: 0 });
  };

  const changeTab = () => setTabIndex((prev) => (!prev ? 1 : 0));

  const hasNextPage = (data.totalCount || 0) > items.length;

  const addUserToPiece = (user?: User) => {
    const currentUser = user || selectedUser;

    if (currentUser) {
      assignRecipientToPiece(currentUser, file);
    }

    if (user) {
      setSelectedUser(user);
    }
  };

  const handleSubmit = () => {
    addUserToPiece();
    onClose();
  };

  const isConfirm =
    selectedUser &&
    !file.selectedPieceDetection &&
    !file.piece?.recipient &&
    file.pieceDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired;

  return (
    <Paper elevation={3} sx={{ ...sxPaper }}>
      <Title title="Assign" sx={sxP20} onClose={onClose} />
      <Box component="section" sx={{ ...sxTabsSection(tabIndex) }}>
        <Box display="flex" sx={sxTab}>
          <AssignTO
            user={selectedUser}
            recipientStatus={file.pieceDetection?.status}
            file={file}
            showAlert={
              !file.assignUserError &&
              !!file.pieceDetection &&
              (!file.piece?.recipient || file.pieceDetection?.recipientId === file.piece?.recipient.id)
            }
            loading={file.isUpdating}
          />
          <Search
            setSearchResult={onSearch}
            sx={sxP20}
            defaultSearch={file.pieceDetection?.recipientSearchString}
            isLoading={isFetching}
          />
          <List
            changeTab={changeTab}
            loadNextPage={loadNextPage}
            isNextPageLoading={isFetching}
            items={items}
            setSelectedUser={selectUser}
            selectedUser={selectedUser}
            hasNextPage={hasNextPage}
          />
          <Footer
            sx={sxP20}
            disabled={!selectedUser?.id || selectedUser?.id === file.piece?.recipient?.id}
            title={isConfirm ? 'Confirm Recipient' : 'Save Changes'}
            handleSubmit={handleSubmit}
          />
        </Box>
        <UserForm changeTab={changeTab} assignUser={addUserToPiece} />
      </Box>
    </Paper>
  );
};

export default UserList;
