import { createSlice } from '@reduxjs/toolkit';
import { Piece } from 'src/common/types/apiContract';

type PiceSearch = {
  pieces: Piece[];
  totalCount: number;
  updateTimestamp: number;
};

const initialState: PiceSearch = { pieces: [], totalCount: 0, updateTimestamp: 0 };

const slice = createSlice({
  name: 'piceSearch',
  initialState,
  reducers: {
    setTotalCount: (state, { payload }: { payload: number }) => {
      state.totalCount = payload;
      return state;
    },
    setUpdateTimestamp: (state, { payload }: { payload: number }) => {
      state.updateTimestamp = payload;
      return state;
    },
    addPieces: (state, { payload }: { payload: Piece[] }) => {
      state.pieces.push(...payload);
      return state;
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { setTotalCount, addPieces, clear, setUpdateTimestamp } = slice.actions;

export default slice.reducer;
