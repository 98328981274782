import * as Sentry from '@sentry/react';
import { AxiosError, AxiosResponse } from 'axios';

import { enqueueRdSnackbar } from '@common/uiKit/RdSnackbar';

interface ICustomError {
  code: number;
  message: string;
  errorType: string;
}

export type ValidationResultError = {
  [key in string]: string[];
};

export interface ValidationResult {
  errors: ValidationResultError;
  title: string[];
}

export interface ThrowError {
  success: boolean;
  objectData: any[];
  axiosErrorResponse: AxiosResponse | null;
  errors: ICustomError[];
  validationResult: ValidationResult;
}

type ResponseError = {
  code: number | null;
  message: string | null;
  errorType: string | null;
};

type ValidationResultErrors = {
  [key in string]: string[];
};

type ValidationProblemDetails = {
  type: string | null;
  title: string | null;
  status: number | null;
  detail: string | null;
  instance: string | null;
  errors: ValidationResultErrors | null;
};

// TODO: This interface should be removed because proper types are already generated from Swagger schema.
export interface CommonResponseData<T> {
  userMessage: string | null;
  objectData: T;
  errors?: ResponseError[] | null;
  validationResult?: ValidationProblemDetails | null;
}

const getDefaultData = (data: any) => {
  return {
    userMessage: 'success',
    objectData: data,
    errors: [] as [],
    validationResult: null,
  };
};

const generateError = (response: any): ICustomError => ({
  code: response?.status || 500,
  errorType: 'Server',
  message: response?.data?.userMessage || 'Server error',
});

class AxiosQueryError extends Error {
  constructor(error: AxiosError<{ userMessage?: string }>) {
    super(error.response?.data?.userMessage || error.message);
    this.name = `AxiosQueryError: ${error.response?.status || ''}`;
  }
}

// TODO: Consider refactoring Axios usage and move the below logic to Axios interceptors.
export const apiRequest = async <ResponseData extends { userMessage?: string | null } = any>(
  requestFunction: (...args: any) => Promise<any>,
  requestData: any,
  withUserMessage: boolean = true,
  retries: number = 0,
  noErrorNotifications = false,
): Promise<ResponseData> => {
  try {
    const response =
      requestData !== undefined
        ? ((await requestFunction(requestData)) as AxiosResponse<ResponseData>)
        : ((await requestFunction()) as AxiosResponse<ResponseData>);

    const data = Array.isArray(response.data) ? getDefaultData(response.data) : response.data;
    const firstStatusDigit = response.status.toString()[0];

    if (firstStatusDigit === '2' && withUserMessage && data.userMessage) {
      enqueueRdSnackbar(data.userMessage);
    }

    return data as ResponseData;
  } catch (error: any) {
    if (retries > 0) {
      return apiRequest(requestFunction, requestData, withUserMessage, retries - 1);
    }

    if (!noErrorNotifications) {
      enqueueRdSnackbar(error.response?.data?.userMessage || 'serverError', { variant: 'error' });
    }

    const errorObject: ThrowError = {
      success: false,
      objectData: error?.response?.data?.objectData || [],
      axiosErrorResponse: error.response || null,
      errors: [generateError(error?.response)],
      validationResult: error?.response?.data?.validationResult,
    };

    Sentry.captureException(new AxiosQueryError(error), (scope) => {
      scope.setTransactionName(`API: ${error.response?.config?.url || '-'}`);
      scope.setExtras({
        error: {
          config: error.response?.config,
          responseData: JSON.stringify(error.response?.data),
          status: error.response?.status,
          message: error.message,
        },
      });
      return scope;
    });

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw errorObject;
  }
};
