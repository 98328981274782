import { ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material';
import { CSSProperties, FC } from 'react';

import { Address, Company } from '@apiContract';

import { sxItemButton } from '../styles';

type ListItemProps = {
  selected: boolean;
  style: CSSProperties;
  item?: Company;
  handleClick: () => void;
};

const defaultAddress: Pick<
Address,
'addressLine1' | 'addressLine2' | 'locality' | 'administrativeArea' | 'postalCode'
> = {
  addressLine1: '',
  addressLine2: '',
  locality: '',
  administrativeArea: '',
  postalCode: '',
};

const Item: FC<ListItemProps> = ({ style, selected, item, handleClick }) => {
  const theme = useTheme();
  if (!item) return null;

  const address = item.addresses.find(data => data.default) || item.addresses[0] || defaultAddress;

  return (
    <ListItem component="div" sx={style} disablePadding>
      <ListItemButton onClick={handleClick} sx={{ ...sxItemButton(selected, theme) }}>
        <ListItemText
          primary={`${item.name} (${item.id})`}
          secondary={
            <Typography
              component="div"
              color="text.secondary"
              variant="caption"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {`${address.addressLine1},
              ${address.addressLine2}${address.addressLine2 && ','}
              ${address.locality},
              ${address.administrativeArea} ${address.postalCode}`}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default Item;
