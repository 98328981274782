import { CapturePiece, RecipientStatus } from '../../types';

export * from '../../types';

export enum GroupPiecesStatus {
  all = 'all',
  failed = 'failed',
}

export type ProgressStatus = GroupPiecesStatus | RecipientStatus;

export type PieceByStatus = Record<ProgressStatus, Array<CapturePiece>>;
