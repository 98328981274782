import { Box, Drawer, PaperProps, Theme, useMediaQuery } from '@mui/material';
import { useContext, useState } from 'react';

import Scrollbar from 'src/components/scrollbar';

import { CaptureContext } from '@common/contexts/CaptureContext';
import { Options } from '@common/types/capture';

import { DialogDarkFullscreen } from '../DialogDarkFullscreen';
import Dialog from './components/Dialog';
import Header from './components/Header';
import { useCapture } from './hooks/useCapture';
import { styles, sxMobileGroupBgColor, sxMobileGroupBtn } from './styles';
import { DialogProcess, Steps } from './types';
import { useSearchParams } from 'react-router-dom';
import ButtonsList from './components/ButtonsList';

type CaptureContentProps = {
  onClose: () => void;
  options: Options;
};

const CaptureContent = ({ onClose, options }: CaptureContentProps) => {
  const [dialog, setDialog] = useState<DialogProcess | null>(null);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    getButtonsByState,
    getTitleByState,
    getComponentByState,
    getIconsByState,
    getDrawerContent,
    setDrawer,
    isUnresolvedPieces,
    isUploadingPieces,
    captureState,
  } = useCapture(options);

  const component = getComponentByState();
  const { Component, props } = component || {};

  const handleCloseCapture = (isCloseDialog?: boolean) => {
    if (isUploadingPieces()) {
      return setDialog(DialogProcess.Uploading);
    }

    if (isUnresolvedPieces()) {
      return setDialog(isCloseDialog ? DialogProcess.UnresolvedByClose : DialogProcess.Unresolved);
    }

    onClose();
    return true;
  };

  const paperProps = (props as unknown as { paperProps: Partial<PaperProps<React.ElementType>> }).paperProps || {};
  const buttons = getButtonsByState(handleCloseCapture);
  const { Component: DrawerComponent, props: DrawerProps } = getDrawerContent();

  return (
    <DialogDarkFullscreen open PaperProps={paperProps}>
      <Header
        title={getTitleByState()}
        icons={getIconsByState()}
        onClose={() => {
          handleCloseCapture(true);
        }}
        buttons={buttons}
      />
      {Component && <Component {...props} />}
      {matches ? (
        <Box
          sx={{
            ...sxMobileGroupBtn,
            ...(sxMobileGroupBgColor[captureState as Steps.Images | Steps.ReviewItems] || {}),
          }}
        >
          <ButtonsList buttons={buttons} />
        </Box>
      ) : null}
      <Drawer
        open={!!DrawerComponent}
        sx={styles.sxDrawer}
        anchor="right"
        PaperProps={styles.sxDrawerPaper}
        onClose={() => setDrawer(null)}
      >
        <Scrollbar sx={styles.sxDrawerBox}>{DrawerComponent && <DrawerComponent {...DrawerProps} />}</Scrollbar>
      </Drawer>
      <Dialog dialog={dialog} setDialog={setDialog} handleClose={onClose} />
    </DialogDarkFullscreen>
  );
};

const Capture = () => {
  const [params] = useSearchParams();

  const sessionId = params.get('sessionId');

  const { setClose, options } = useContext(CaptureContext);
  return sessionId ? <CaptureContent onClose={setClose} options={options} /> : null;
};

export default Capture;
