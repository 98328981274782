export const sxPaper = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  minHeight: '100%',
};

export const sxMain = {
  m: 2.5,
  mb: 0,
  flex: 1,
};

export const sxP20 = {
  p: 2.5,
};

export const sxAvatar = {
  width: 48,
  height: 48,
};

export const sxPT1 = {
  pt: 1,
};

export const sxPB3 = {
  pb: 3,
};

export const sxCommentText = {
  width: 1,
};

export const sxFlexStart = {
  alignSelf: 'flex-start',
};
