import { Box, Button, Container, Stack, Typography } from '@mui/material';

import Header from '@minimalsLayouts/common/header-simple';

export const AuthenticationError = () => {
  const reloadHomePage = () => {
    document.cookie = '.AspNetCore.Identity.Application=; Path=/; Domain=accounts.digitalizedsoftware.com Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.location.href = window.location.origin;
  };

  return (
    <>
      <Header />

      <Container component="main">
        <Stack
          spacing={5}
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 480,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Authentication error
            </Typography>
            <Typography variant="body1" color="text.secondary">
              An error occurred during authentication. Please re-authenticate.
            </Typography>
          </Box>

          <Box>
            <img src="/images/illustration_authentication_error.png" alt="" style={{ padding: '40px 67px 80px' }} />

            <Button variant="contained" color="primary" size="large" onClick={reloadHomePage}>
              Re-authenticate
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
};
