export type UserAccountLabel = 'general' | 'notifications' | 'change_password';
export type AdministrationCompanyTab = 'info' | 'settings' | 'subscription';

export type MailroomMailpieceCategory = 'receive' | 'requests' | 'search';

export const inboxLabels = ['new', 'processing', 'processed', 'archive'] as const;
type InboxLabelTuple = typeof inboxLabels;
export type InboxMailpieceCategory = InboxLabelTuple[number];

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_KT_ADMIN = '/superadmin';

export const DEFAULT_REDIRECT_URL = {
  default: '/',
  mailroom: '/mailroom/dashboard',
  admin: '/mailroom/dashboard',
  kt_admin: '/mailroom/dashboard',
  recipient: '/inbox/new',
  company: '/administration/company/info',
};

export const PATH_KT_ADMIN = {
  root: ROOTS_KT_ADMIN,
  onboarding: path(ROOTS_KT_ADMIN, '/onboarding'),
};

export const userRoutes = {
  account: (label: UserAccountLabel) => `/user/account/${label}`,
};

export const administrationRoutes = {
  accountSetup: '/administration/account-setup',
  // Parameter 'tab' must be optional to enable proper navigation menu highlight for child pages.
  company: (tab?: AdministrationCompanyTab) => `/administration/company${tab ? '/' + tab : ''}`,
  purchasePlan: '/administration/company/purchase-plan',
  mailroom: '/administration/mailroom',
  users: '/administration/users',
  createUser: '/administration/users/create',
  editUser: (id: string) => `/administration/users/edit/${id}`,
  upload: '/administration/users/upload',
  automations: '/administration/automations',
  automationCreate: '/administration/automations/create',
  automationEdit: (id: number) => `/administration/automations/edit/${id}`,
};

export const mailroomRoutes = {
  root: '/mailroom/active',
  dashboard: '/mailroom/dashboard',
  receive: '/mailroom/receive',
  items: '/mailroom/active/items',
  storageGroups: '/mailroom/active/storage-groups',
  container: (id: number) => `/mailroom/active/storage-groups/${id}`,
  mailpiece: (mailroomMailpieceCategory: MailroomMailpieceCategory, id: number) =>
    `/mailroom/${mailroomMailpieceCategory}/${id}`,
  pendingRequest: '/mailroom/requests',
  search: '/mailroom/search',
};

export const inboxRoutes = {
  inbox: (label: InboxMailpieceCategory) => `/inbox${label ? '/' + label : ''}`,
  mailpiece: (inboxMailpieceCategory: InboxMailpieceCategory, id: number) => `/inbox/${inboxMailpieceCategory}/${id}`,
};

export const generalRoutes = {
  page404: '/404',
};

export const identityServer = {
  manage: import.meta.env.VITE_APP_IDENTITY + '/Manage',
};
