import type {
  Company,
  CompanyFilteredListParams,
  CompanyPagedList,
  CompanyPagedListResponse,
  CompanyResponse,
  CreateCompanyRequest,
  UpdateCompanyRequest,
} from '@apiContract';

import { receivedDigitalApi } from './index';

const companyApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query<Company, number>({
      query: (id) => `Company/${id}`,
      providesTags: ['Company'],
      transformResponse: (response: CompanyResponse) => response.objectData,
    }),

    updateCompany: builder.mutation<Company, UpdateCompanyRequest>({
      query: (requestData) => ({
        url: 'Company',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Company'],
      transformResponse: (response: CompanyResponse) => response.objectData,
    }),

    createUserCompany: builder.mutation<Company, CreateCompanyRequest>({
      query: (requestData) => ({
        url: 'Company/CreateUserCompany',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['Company', 'User'],
      transformResponse: (response: CompanyResponse) => response.objectData,
    }),
    getCompaniesFiltered: builder.query<CompanyPagedList, CompanyFilteredListParams>({
      query: (requestParams) => ({ url: 'Company/filtered', params: requestParams }),
      transformResponse: (response: CompanyPagedListResponse) => response.objectData,
    }),
  }),
});

export const {
  useLazyGetCompanyQuery,
  useUpdateCompanyMutation,
  useCreateUserCompanyMutation,
  useGetCompaniesFilteredQuery,
} = companyApi;
