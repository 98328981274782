import { SxProps } from '@mui/material';

import { Container, ContainerRequest, ContainerType, PieceType, Workflow } from '@apiContract';
import { WorkflowMail, WorkflowPackages } from 'src/common/types/contractHelpers';

export enum SortingOrder {
  addToFront = 'AddToFront',
  addToBack = 'AddToBack',
}

export enum ItemType {
  mail = 'mail',
  packages = 'package',
}

export type ReceiveSettings = {
  ReceiveMailWorkflow: WorkflowMail;
  ReceivePackagesWorkflow: WorkflowPackages;
  ReceiveSortOrder: SortingOrder;
};

export type StorageGroupData = {
  date: Date;
  storageNumber: string;
  sortingOrder: SortingOrder;
  storageGroupType: string;
};

export type StorageGroupProps = {
  workflow: Workflow;
  itemType: PieceType;
  containerTypes: ContainerType[];
  sx?: SxProps;
  direction?: 'row' | 'column';
  defaultValue?: ContainerRequest | null;
  onChange: (data: ContainerRequest & Partial<Pick<Container, 'nextOrderNo'>>) => void;
  onLoading?: (data: boolean) => void;
  isLoading?: boolean;
  isNextOrderNo?: boolean;
};
