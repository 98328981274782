const convert = {
  HOURS_TO_MILLISECONDS: 3600000,
  MINUTES_TO_MILLISECONDS: 60000,
  SECONDS_TO_MILLISECONDS: 1000,
};

const getOffset = (dateArg: Date | string) => {
  const date = new Date(dateArg);
  const offsetHours = date.getHours();
  const offsetMinutes = date.getMinutes();
  const offsetSeconds = date.getSeconds();
  const offsetMilliseconds = date.getMilliseconds();

  const offset =
    offsetHours * convert.HOURS_TO_MILLISECONDS +
    offsetMinutes * convert.MINUTES_TO_MILLISECONDS +
    offsetSeconds * convert.SECONDS_TO_MILLISECONDS +
    offsetMilliseconds;

  return offset;
};

const setTimeDayStart = (dateArg: Date | string) => {
  const date = new Date(dateArg);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const addTimeNow = (dateArg: Date | string) => {
  const dateEmptyTime = setTimeDayStart(new Date(dateArg));
  const offset = getOffset(new Date());
  dateEmptyTime.setMilliseconds(offset);
  return dateEmptyTime;
};

export const getDateFromMinutesOffset = (minutes: number) => {
  const totalHours = minutes / 60;
  const totalMinutes = minutes % 60;
  const date = new Date();
  date.setHours(totalHours, totalMinutes);
  return date;
};

export const getDateStringWithoutTime = (dateArg: Date) => dateArg.toISOString().split('T')[0];
