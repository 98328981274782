import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { FileRejection } from 'react-dropzone';

import { fileData } from '@minimalsComponents/file-thumbnail';

import { fData } from '@utils/format-number';

import { sxRejection } from '../../styles';

type Props = {
  fileRejections: FileRejection[];
};

const RejectionFiles: FC<Props> = ({ fileRejections }) => {
  if (!fileRejections.length) {
    return null;
  }

  return (
    <Paper variant="outlined" sx={sxRejection}>
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = fileData(file);

        return (
          <Box key={path} my={1}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size ? fData(size) : ''}
            </Typography>

            {errors.map((error) => (
              <Box key={error.code} component="span" typography="caption">
                - {error.code === 'file-too-large' ? 'Image is larger than 30MB' : error.message}
              </Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
};

export default RejectionFiles;
