import { Card, CardContent, Grid, Typography } from '@mui/material';

type ReceiveConfigGroupProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
};

export const ReceiveConfigGroup = ({ title, subtitle, children }: ReceiveConfigGroupProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={4} sx={{ display: { xs: 'none', md: 'initial' } }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 3, display: { md: 'none' } }}>
              {title}
            </Typography>
            {children}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
