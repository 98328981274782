import { Tab, Tabs } from '@mui/material';
import { FC } from 'react';

import Label from '@minimalsComponents/label';

import { sxStatusTitle, sxTabs } from '../../styles';
import { ColorStatus, GroupPiecesStatus, ProgressStatus, RecipientStatus } from '../../types';

type MobileListProps = {
  onChange: (data: ProgressStatus) => void;
  status: ProgressStatus;
  data: Record<ProgressStatus, number>;
};

const MobileList: FC<MobileListProps> = ({ onChange, status, data }) => {
  return (
    <Tabs
      allowScrollButtonsMobile
      variant="scrollable"
      scrollButtons="auto"
      value={status}
      onChange={(_, value: ProgressStatus) => onChange(value)}
      TabScrollButtonProps={{
        className: 'scroll-button',
      }}
      sx={sxTabs}
    >
      {Object.entries(data).map(([key, value]) => {
        const color = key === GroupPiecesStatus.failed ? 'error' : ColorStatus[key as RecipientStatus];

        if (key === GroupPiecesStatus.failed && !value) {
          return null;
        }

        return (
          <Tab
            disableRipple
            key={key}
            value={key}
            icon={
              <Label variant={key === status ? 'filled' : 'soft'} color={color}>
                {value}
              </Label>
            }
            sx={sxStatusTitle}
            iconPosition="end"
            label={key}
          />
        );
      })}
    </Tabs>
  );
};

export default MobileList;
