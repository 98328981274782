import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useUpdatePiecesMutation } from '@api/piece';
import { useCreateSessionMutation, useGetSessionByIdQuery } from '@api/receiveSession';
import {
  CaptureMethod,
  Piece,
  PieceAction,
  PieceAssetSize,
  PieceAssetType,
  PieceStatus,
  PieceType,
  ReceiveSessionResponse,
  RecipientAutoDetection,
  Workflow,
} from '@apiContract';
import { mailroomRoutes } from '@routes';
// eslint-disable-next-line import/named
import { keys } from 'lodash/fp';
import { enqueueRdSnackbar } from 'src/common/uiKit/RdSnackbar';

import { rdBackSignalr } from '@common/api/signalr';
import { ICropperRef } from '@common/components/ImageCropper';
import { ImageEditor } from '@common/components/ImageEditor';
import Loading from '@common/components/PercentLoading';
import { useSettings } from '@common/hooks/useSettings';
import { Options } from '@common/types/capture';
import { SortingOrder, SortingOrderNameByEnum } from '@common/types/contractHelpers';
import { rotateImage } from '@common/utils/image';

import { getPieceAssetUrl } from '@utils/piece';
import { dispatch as storeDispatch } from 'src/redux/store';

import { CameraCapture } from '../../CameraCapture';
import AssigmentList from '../components/AssigmentList';
import Comments from '../components/Comments';
import LocationID from '../components/LocationID';
import NotFound from '../components/NotFound';
import ReceiveConfig from '../components/ReceiveConfig';
import ReviewItems from '../components/ReviewItems';
import UploadImages from '../components/UploadImages';
import {
  getButtonConfig,
  getColorPieceStatus,
  getIconConfig,
  getPieceDetection,
  nextStepReceiveConfig,
  parseUploadFiles,
  toBase64,
} from '../helper';
import { ButtonConfig, DrawerTypes, IconConfig, IdV4, OnPieceCreateType, PieceId, Steps, UploadRef } from '../types';
import { ActionTypes, FileOptionPayload, Store, initStore, reducer } from './reducer';
import { useReceiveSessionItems } from './useReceiveSessionItems';
import { useUpdateQueue } from './useUpdateQueue';
import { useUploadWorker } from './useUploadWorker';
import { receivedDigitalApi } from 'src/redux/api';

type ComponentByState = {
  Component:
  | ((props: any) => JSX.Element | null)
  | ForwardRefExoticComponent<PropsWithoutRef<unknown> & RefAttributes<unknown>>
  | null;
  props: object;
};

type DrawerComponent = {
  Component: ((props: any) => JSX.Element) | null;
  props: Record<string, unknown> & { onClose: () => void };
};

export const useCapture = (optionData: Options) => {
  const [params, setParams] = useSearchParams();
  const paramSessionId = params.get('sessionId');
  const [
    {
      files,
      cursor,
      captureState,
      drawer,
      receiveSession,
      cropReturnPath,
      uploadError,
      options,
      isLoading,
      isNew,
      itemsToLoad,
    },
    dispatch,
  ] = useReducer(reducer, initStore(optionData, !paramSessionId || paramSessionId === 'new'));
  const navigate = useNavigate();

  const [, forceUpdate] = useState({});
  const setCaptureState = useCallback((nextCaptureState: Steps) => {
    dispatch({ type: ActionTypes.SET_CAPTURE_STATE, payload: nextCaptureState });
  }, []);

  const { data: requestedReceiveSession, error: receiveSessionError } = useGetSessionByIdQuery(paramSessionId, {
    skip: !paramSessionId || paramSessionId === 'new',
  });


  if (requestedReceiveSession && itemsToLoad !== requestedReceiveSession?.totalPiecesCount) {
    dispatch({ type: ActionTypes.SET_ITEMS_TO_LOAD, payload: requestedReceiveSession?.totalPiecesCount || 0 });
  }

  if (
    captureState === Steps.Loading &&
    (receiveSessionError || (requestedReceiveSession && !requestedReceiveSession.totalPiecesCount))
  ) {
    setCaptureState(Steps.NotFound);
  }

  if (captureState === Steps.Loading && Math.round((100 / itemsToLoad) * Object.keys(files).length) >= 100) {
    setTimeout(() => {
      const captureType = params.get('captureType') as Steps;
      if (paramSessionId) {
        dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: paramSessionId });
      }
      setCaptureState(
        captureType && [Steps.Photo, Steps.Images].includes(captureType) ? captureType : Steps.ReviewItems,
      );
    }, 300);
  }

  useReceiveSessionItems(requestedReceiveSession, (items: Piece[]) => {
    dispatch({ type: ActionTypes.ADD_PIECE_LOAD_LIST, payload: items });
  });

  const setCursor = useCallback(
    (nextCursor: number) => dispatch({ type: ActionTypes.SET_CURSOR, payload: nextCursor }),
    [],
  );

  const setDrawer = useCallback((nextDrawer: DrawerTypes | null) => {
    dispatch({ type: ActionTypes.SET_DRAWER, payload: nextDrawer });
  }, []);

  const setFileOption = useCallback((payload: FileOptionPayload) => {
    dispatch({ type: ActionTypes.SET_FILE_OPTION, payload });
  }, []);

  const cropperRef = useRef<ICropperRef | null | undefined>(null);
  const uploadImagesRef = useRef<UploadRef>(null);
  const [updatePieces] = useUpdatePiecesMutation();

  const [sessionMutation] = useCreateSessionMutation();
  const { addEvent } = useUpdateQueue();

  const settings: Record<string, boolean> = useSettings(['AutoCroppingEnable']);

  const currentFile = cursor ? files[cursor] : null;

  useEffect(() => () => {
    storeDispatch(receivedDigitalApi.util.invalidateTags(['RS']));
  }, []);

  useEffect(() => {
    rdBackSignalr.registerEvent('OnPieceRecipientDetected', 'pieceCapture', (data: RecipientAutoDetection) => {
      const { pieceId } = data;
      if (!pieceId) return;
      dispatch({ type: ActionTypes.SET_FILE_DETECTION, payload: { id: pieceId, detection: data, addEvent } });
    });

    rdBackSignalr.registerEvent('OnPieceCropped', 'pieceCapture', ({ isSuccess, pieceId, croppedAsset }) => {
      if (isSuccess) {
        dispatch({ type: ActionTypes.SET_PIECE_ASSETS, payload: { id: pieceId, value: [croppedAsset] } });
      }
      
      setFileOption({ id: pieceId, option: 'isAutoCroppedCompleted', value: true });
    });

    rdBackSignalr.registerEvent('OnPieceThumbnailsCreated', 'pieceCapture', ({ pieceId, pieceThumbnailAssets }) => {
      dispatch({ type: ActionTypes.SET_PIECE_ASSETS, payload: { id: pieceId, value: pieceThumbnailAssets } });
    });

    const updateFunc = (e: CustomEvent<{ pieces: PieceId[] }>) => {
      setFileOption({ id: e.detail.pieces, option: 'isUpdating', value: true });
    };

    const updatedFunc = (e: CustomEvent<{ nextPieces: Piece[] }>) => {
      const cb = (state: Store) => {
        if (state.captureState === Steps.Edit && options.captureMethod === CaptureMethod.Single && state.cursor) {
          const currentPiece = state.files[state.cursor];
          if (currentPiece.piece?.recipient) {
            enqueueRdSnackbar(
              `Assigned to ${currentPiece.piece.recipient?.displayName || ''} (${
                currentPiece.piece.recipient?.userName || ''
              })`,
              {
                variant: 'success',
              },
            );
          }
        }
      };

      e.detail.nextPieces.forEach((item) => {
        setFileOption({ id: item.id, option: 'piece', value: item, cb });
        setFileOption({ id: item.id, option: 'isUpdating', value: false });
      });
    };

    const errorFunc = (e: CustomEvent<{ pieces: Piece[] }>) => {
      e.detail.pieces.forEach((item) => {
        setFileOption({ id: item.id, option: 'isUpdating', value: false });
        setFileOption({ id: item.id, option: 'assignUserError', value: true });
      });
    };

    window.addEventListener('PiecesUpdating', updateFunc as EventListener);
    window.addEventListener('PiecesUpdated', updatedFunc as EventListener);
    window.addEventListener('PiecesUpdatedError', errorFunc as EventListener);

    return () => {
      rdBackSignalr.unregisterEvent('OnPieceRecipientDetected', 'pieceCapture');
      rdBackSignalr.unregisterEvent('OnPieceCropped', 'pieceCapture');
      rdBackSignalr.unregisterEvent('OnPieceThumbnailsCreated', 'pieceCapture');
      window.removeEventListener('PiecesUpdating', updateFunc as EventListener);
      window.removeEventListener('PiecesUpdated', updatedFunc as EventListener);
      window.removeEventListener('PiecesUpdatedError', errorFunc as EventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEvent]);

  const onPieceCreated = ({ fileId, piece, status }: OnPieceCreateType) => {
    setFileOption({ id: fileId, option: 'status', value: status });
    if (piece) {
      setFileOption({ id: fileId, option: 'piece', value: piece });
      dispatch({ type: ActionTypes.UPDATE_FILE_ID, payload: fileId });
      dispatch({ type: ActionTypes.CHECK_NOT_SET_RECIPIENT, payload: { id: piece.id, addEvent } });
    }
  };

  const { addFile } = useUploadWorker(onPieceCreated);

  const handlePhotoClick = (photo: string) => {
    dispatch({ type: ActionTypes.INIT_FILE, payload: { photo } });
    setCaptureState(Steps.Review);
  };

  const handleRetake = () => {
    if (cursor) {
      dispatch({ type: ActionTypes.REMOVE_FILE, payload: cursor });
    }
    dispatch({ type: ActionTypes.SET_CURSOR, payload: null });
    setCaptureState(Steps.Photo);
  };

  const handleErrorStartReceive = () => {
    params.delete('sessionId');
    params.delete('captureType');
    params.append('sessionId', 'new');
    setParams(params);
    setCaptureState(Steps.ReceiveConfig);
  };

  const closeDrawer = () => setDrawer(null);

  const handleReviewItems = () => setCaptureState(Steps.ReviewItems);

  const createSession = useCallback(async () => {
    const { captureMethod, pieceType, workflow, storageGroupData } = options;

    try {
      const response = await sessionMutation({
        captureMethod,
        pieceType,
        workflow,
        receiveSessionContainer: {
          containerTypeId: Number(storageGroupData?.containerTypeId),
          createdDate: storageGroupData?.createdDate || null,
          sortOrder: SortingOrderNameByEnum[(storageGroupData?.sortOrder || SortingOrder.none) as SortingOrder],
          containerNumber: storageGroupData?.containerNumber || '',
        },
      });
      const { data } = response as { data: ReceiveSessionResponse };

      if (data) {
        const sessionId = data.objectData?.id || null;
        params.delete('sessionId');
        params.append('sessionId', `${sessionId}`);
        setParams(params);
        return sessionId;
      }
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, sessionMutation]);

  const handleConfirmReview = async () => {
    setCaptureState(Steps.Edit);
    const sessionId = !receiveSession ? await createSession() : receiveSession;

    if (sessionId && cursor && currentFile && currentFile.photo) {
      addFile({ file: currentFile.photo, sessionId, fileId: cursor as string });
      dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: sessionId });
      setFileOption({ id: cursor, option: 'status', value: 'queued' });
    }
  };

  const onUpload = useCallback(
    async (uploadedFiles: Array<File | string>) => {
      const sessionId = !receiveSession ? await createSession() : receiveSession;

      if (sessionId) {
        const data = parseUploadFiles(uploadedFiles, sessionId);
        data.forEach(({ file, fileId }) => {
          if (file) {
            dispatch({ type: ActionTypes.INIT_FILE, payload: { photo: file, id: fileId } });
          }
        });
        addFile(data);

        dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: sessionId });
        setCaptureState(Steps.ReviewItems);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addFile, receiveSession, createSession],
  );

  const handleCrop = async (returnPath: Steps = Steps.Edit) => {
    if (!cursor) return;
    let edit = files[cursor].photo;
    if (files[cursor].photo && typeof files[cursor].photo !== 'string') {
      edit = (await toBase64(files[cursor].photo as File)) as string;
    }
    setFileOption({ id: cursor, option: 'editImage', value: edit });
    dispatch({ type: ActionTypes.SET_CROP_RETURN_PATH, payload: returnPath });
    setCaptureState(Steps.Crop);
  };

  const handleRotate = () => {
    if (!cursor) return;
    rotateImage(currentFile?.editImage || '', -90, (result) => {
      setFileOption({ id: cursor, option: 'editImage', value: result });
    });
  };

  const retryFailedPieces = async (id?: IdV4) => {
    const sessionId = !receiveSession ? await createSession() : receiveSession;
    const failedPieces = Object.values(files).filter((item) => item.status === 'failed');

    if (failedPieces.length && sessionId) {
      const failedFiles = id
        ? { file: files[id].photo, sessionId, fileId: id }
        : failedPieces.map((item) => ({ file: item.photo, sessionId, fileId: item.id as IdV4 }));

      addFile(failedFiles);
      dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: sessionId });
      if (id) {
        setFileOption({ id, option: 'status', value: 'queued' });
      } else {
        failedPieces.forEach(({ id: piceId }) => setFileOption({ id: piceId, option: 'status', value: 'queued' }));
      }
    }
  };

  const updateReceiveConfig = (payload: Partial<Options>) => {
    dispatch({ type: ActionTypes.UPDATE_RECEIVE_CONFIG, payload });
  };

  const setIsLoading = (payload: boolean) => {
    dispatch({ type: ActionTypes.SET_IS_LOADING, payload });
  };

  const isUnresolvedPieces = () => {
    return Object.values(files).some((item) => {
      const { piece } = item;
      return !piece || !piece.recipient;
    });
  };

  const isUploadingPieces = () => {
    return Object.values(files).some(
      (item) => !['failed', 'detected', 'completed', 'loadedFromServer'].includes(item.status),
    );
  };

  const onNextState = () => {
    switch (captureState) {
      case Steps.ReceiveConfig: {
        setCaptureState(nextStepReceiveConfig[options.captureMethod]);
        break;
      }
      case Steps.Photo:
        setCaptureState(Steps.Review);
        break;
      case Steps.Review:
        setCaptureState(Steps.Edit);
        break;
      case Steps.Edit:
      case Steps.ReviewItems:
        setCaptureState(Steps.Photo);
        break;
      default:
        break;
    }
  };

  const getButtonsByState = (onClose: () => boolean | void): ButtonConfig[] => {
    switch (true) {
      case captureState === Steps.ReceiveConfig:
        return [getButtonConfig('Start Receiving', 'primary', onNextState, isLoading)];
      case captureState === Steps.Photo && keys(files).length > 0:
        return [getButtonConfig('Review Items', 'primary', handleReviewItems)];
      case captureState === Steps.Review:
        return [
          getButtonConfig('Retake', 'inherit', handleRetake),
          getButtonConfig('Confirm', 'primary', handleConfirmReview),
        ];
      case captureState === Steps.Edit:
        return [
          getButtonConfig('Review Items', 'inherit', handleReviewItems),
          getButtonConfig('Continue', 'primary', onNextState),
        ];
      case captureState === Steps.Crop:
        return [
          getButtonConfig('Cancel', 'inherit', () => {
            if (cursor) {
              setFileOption({ id: cursor, option: 'editImage', value: null });
            }
            if (cropReturnPath) {
              setCaptureState(cropReturnPath);
            }
          }),
          getButtonConfig('Save Changes', 'primary', async () => {
            const img = await cropperRef.current?.done();
            if (cropReturnPath) {
              setCaptureState(cropReturnPath);
            }
            if (cursor) {
              const result = await updatePieces({
                action: PieceAction.CropImage,
                pieces: [
                  {
                    id: +cursor,
                    croppedFileBase64: img.split(',')[1],
                  },
                ],
              }).unwrap();
              setFileOption({ id: cursor, option: 'piece', value: result[0] });
              setFileOption({ id: cursor, option: 'editImage', value: img });
            }
          }),
        ];
      case captureState === Steps.ReviewItems:
        return [
          getButtonConfig(
            'Continue Receiving',
            'inherit',
            setCaptureState,
            !isNew && requestedReceiveSession && requestedReceiveSession?.isClosed,
            { captureDropdown: true },
          ),
          getButtonConfig('Finish', 'primary', () => {
            const isFinished = onClose();
            if (isFinished) {
              navigate({
                pathname: mailroomRoutes.items,
                search: createSearchParams({
                  PieceStatus: PieceStatus.Unsent.toString(),
                }).toString(),
              });
            }
          }),
        ];
      case captureState === Steps.Images:
        return uploadImagesRef.current
          ? [
            getButtonConfig('Remove all', 'inherit', uploadImagesRef.current.removeAll),
            getButtonConfig('Upload Images', 'primary', uploadImagesRef.current.upload, uploadError),
          ]
          : [];
      default:
        return [];
    }
  };

  const getIconsByState = (): IconConfig[] => {
    switch (true) {
      case captureState === Steps.Edit: {
        const pieceDetection = getPieceDetection(currentFile);

        const isUserLoading =
          !!currentFile?.piece &&
          (currentFile.isUpdating || !(currentFile?.piece?.recipient || currentFile?.pieceDetection));

        const icons = [
          getIconConfig('PersonPinRounded', isUserLoading, () => setDrawer(DrawerTypes.User), {
            color: isUserLoading ? undefined : getColorPieceStatus(currentFile?.piece, pieceDetection?.status),
            disabled: !currentFile?.piece,
          }),
          getIconConfig('AddCommentRounded', false, () => setDrawer(DrawerTypes.Comments), {
            disabled: !currentFile?.piece,
          }),
          getIconConfig(
            'CropRotateRounded',
            settings.AutoCroppingEnable &&
              options.pieceType === PieceType.Mail &&
              !!currentFile?.piece &&
              !currentFile.isAutoCroppedCompleted,
            () => handleCrop(Steps.Edit),
            { disabled: !currentFile?.piece },
          ),
        ];

        if (options.workflow !== Workflow.HighDensityMail && options.workflow !== Workflow.MailBox) {
          const location = getIconConfig('LocationOnRounded', false, () => setDrawer(DrawerTypes.Location), {
            disabled: !currentFile?.piece,
          });
          return [icons[0], location, ...icons.slice(1)];
        }

        return icons;
      }
      case captureState === Steps.Crop:
        return [getIconConfig('Rotate90DegreesCcwRounded', false, handleRotate)];
      case captureState === Steps.ReviewItems: {
        const isUploading = isUploadingPieces();
        const failedPieces = Object.values(files).filter((item) => item.status === 'failed');

        return failedPieces.length || isUploading
          ? [
            {
              ...getIconConfig('CloudUploadRounded', isUploading, retryFailedPieces),
              retryContent: failedPieces.length,
            },
          ]
          : [];
      }
      default:
        return [];
    }
  };

  const getTitleByState = () => {
    switch (captureState) {
      case Steps.ReceiveConfig:
        return 'Receive Items';
      case Steps.Photo:
        return 'Capture';
      case Steps.Review:
        return 'Review';
      case Steps.Edit:
      case Steps.Crop:
        return 'Edit';
      case Steps.Images:
        return 'Upload Images';
      case Steps.ReviewItems:
      case Steps.NotFound:
        return 'Review Items';
      default:
        return '';
    }
  };

  const getComponentByState = (): ComponentByState => {
    switch (captureState) {
      case Steps.Loading:
        return {
          Component: Loading,
          props: { percent: Math.round((100 / itemsToLoad) * Object.keys(files).length) },
        };
      case Steps.ReceiveConfig:
        return {
          Component: ReceiveConfig,
          props: {
            options,
            isLoading,
            updateReceiveConfig,
            setIsLoading,
            paperProps: { sx: { backgroundColor: 'background.default' } },
          },
        };
      case Steps.Photo:
        return {
          Component: CameraCapture,
          props: {
            capture: handlePhotoClick,
          },
        };
      case Steps.Review:
        return {
          Component: ImageEditor,
          props: { imageBase64: currentFile?.editImage || currentFile?.photo, croppingActive: false },
        };
      case Steps.Edit:
        return {
          Component: ImageEditor,
          props: {
            imageBase64:
              currentFile?.editImage ||
              getPieceAssetUrl(currentFile?.piece?.pieceAssets || [], PieceAssetSize.Large) ||
              currentFile?.photo,
            cropperRef: cropperRef,
            isLoading: !currentFile?.piece,
          },
        };
      case Steps.Crop:
        return {
          Component: ImageEditor,
          props: {
            imageBase64:
              currentFile?.editImage ||
              getPieceAssetUrl(currentFile?.piece?.pieceAssets || [], PieceAssetSize.Large, PieceAssetType.Original),
            croppingActive: true,
            cropperRef: cropperRef,
            isLoading: !getPieceAssetUrl(
              currentFile?.piece?.pieceAssets || [],
              PieceAssetSize.Large,
              PieceAssetType.Cropped,
            ),
          },
        };
      case Steps.ReviewItems:
        return {
          Component: ReviewItems,
          props: {
            mapCapturePiece: files,
            setDrawer: setDrawer,
            onCropClick: handleCrop,
            setCursor: setCursor,
            retryFailedPieces: retryFailedPieces,
            defaultCursor: Steps.ReviewItems === cropReturnPath && cursor,
            workflow: options.workflow,
            requestedReceiveSession,
            isNew,
          },
        };
      case Steps.Images:
        return {
          Component: UploadImages,
          props: {
            ref: uploadImagesRef,
            type: options.pieceType,
            forceUpdate,
            onUpload,
            setInvalid: (valid: boolean) => dispatch({ type: ActionTypes.SET_UPLOAD_ERROR, payload: valid }),
          },
        };
      case Steps.NotFound:
        return {
          Component: NotFound,
          props: { onStartReceive: handleErrorStartReceive },
        };
      default:
        return { Component: null, props: {} };
    }
  };

  const getDrawerContent = (): DrawerComponent => {
    switch (drawer) {
      case DrawerTypes.User:
        return {
          Component: AssigmentList,
          props: { file: currentFile, onClose: closeDrawer, addEvent },
        };
      case DrawerTypes.Location:
        return currentFile?.piece?.pieceLocation
          ? {
            Component: LocationID,
            props: {
              piece: currentFile?.piece,
              itemType: options.pieceType,
              onChange: (piece: Piece) => setFileOption({ id: piece.id, option: 'piece', value: piece }),
              onClose: closeDrawer,
            },
          }
          : { Component: null, props: { onClose: closeDrawer } };
      case DrawerTypes.Comments:
        return {
          Component: Comments,
          props: { pieceId: currentFile?.piece?.id, onClose: closeDrawer },
        };
      default:
        return { Component: null, props: { onClose: closeDrawer } };
    }
  };

  return {
    captureState,
    getIconsByState,
    getComponentByState,
    onNextState,
    getButtonsByState,
    getTitleByState,
    getDrawerContent,
    setDrawer,
    isUnresolvedPieces,
    isUploadingPieces,
  };
};
