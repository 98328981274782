// import { List,ListItem, ListItemAvatar, ListItemButton, Avatar, ListItemText, Typography } from "@mui/material";
import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { Company } from '@apiContract';

import { sxList, sxNoResult } from '../styles';
import Item from './Item';

type Props = {
  loadNextPage: (startIndex: number, stopIndex: number) => void | Promise<void>;
  isNextPageLoading: boolean;
  items: Array<Company>;
  setSelectedItem: (company: Company) => void;
  selectedItem?: Company;
  hasNextPage: boolean;
};

const CompanyItem = ({ style, index, data }: ListChildComponentProps) => (
  <Item
    style={style}
    selected={!!data.selectedItem && !!data.items[index] && data.items[index]?.id === data.selectedItem?.id}
    item={data.items[index]}
    handleClick={() => data.items[index] && data.setSelectedItem(data.items[index] as Company)}
  />
);

const CompanyList: FC<Props> = ({
  loadNextPage,
  isNextPageLoading,
  items,
  setSelectedItem,
  selectedItem,
  hasNextPage,
}) => {
  const theme = useTheme();

  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  return (
    <Box sx={{ ...sxList(theme) }}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
              {({ onItemsRendered, ref }) => {
                return (
                  <FixedSizeList
                    height={height}
                    width={width}
                    onItemsRendered={onItemsRendered}
                    itemCount={itemCount}
                    itemSize={66}
                    ref={ref}
                    itemData={{
                      selectedItem,
                      items,
                      setSelectedItem,
                      isNextPageLoading,
                    }}
                    className="companiesScrollBar"
                  >
                    {CompanyItem}
                  </FixedSizeList>
                );
              }}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
      {items.length === 0 && !isNextPageLoading ? (
        <Typography sx={sxNoResult} variant="body2">
          No results found
        </Typography>
      ) : null}
    </Box>
  );
};

export default CompanyList;
