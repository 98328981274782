import { PieceAsset, PieceAssetSize,PieceAssetType, Workflow } from "src/common/types/apiContract";
const AVAILABLE_ASSETS = [PieceAssetType.Original, PieceAssetType.AutoCropped, PieceAssetType.Cropped];

export const getPieceAssetUrl = (pieceAssets: Array<PieceAsset>, size: PieceAssetSize, strictType?: PieceAssetType) => {
  const assets: Array<PieceAsset> = [];

  if (strictType) {
    return pieceAssets.find((item) => item.pieceAssetType === strictType && item.pieceAssetSize === size)?.url || '';
  }

  [...pieceAssets]
    .sort((itemA, itemB) => (itemB?.pieceAssetType || 0) - (itemA?.pieceAssetType || 0))
    .forEach((item) => {
      if (
        item.pieceAssetSize &&
        item.pieceAssetSize <= size &&
        item.url &&
        AVAILABLE_ASSETS.includes(item.pieceAssetType as PieceAssetType)
      ) {
        const lastAsset = assets[assets.length - 1];
        if (assets.length && lastAsset.pieceAssetType === item.pieceAssetType) {
          const isSmallerSize = lastAsset.pieceAssetSize && lastAsset.pieceAssetSize > item.pieceAssetSize;
          
          if (isSmallerSize) {
            assets.push(item);
          } else {
            assets.splice(assets.length - 1, 0, item);
          }
        } else {
          assets.push(item);
        }
      }
    });

  return assets[0] && assets[0].url ? assets[0].url : '';
};

export const workflowName = {
  [Workflow.DateReceived]: 'Date Received',
  [Workflow.MailBox]: 'Mailbox',
  [Workflow.HighDensityMail]: 'High Density Mail',
  [Workflow.Lockers]: 'Lockers',
  [Workflow.Location]: 'Location',
  [Workflow.Carrier]: 'Carrier',
};
