import { useRef } from 'react';

import { Piece, PiecePagedList, ReceiveSession } from '@apiContract';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// eslint-disable-next-line import/named
import { times } from 'lodash/fp';
import { PromiseQueue } from 'src/utils/promiseQueue';

const ITEMS_PER_PAGE = 100;

export const useReceiveSessionItems = (
  receiveSession: ReceiveSession | undefined,
  setItems: (items: Piece[]) => void,
) => {
  const { accessToken } = useOidcAccessToken();

  const loaded = useRef(false);

  const loadPiecese = async () => {
    if (!receiveSession) return;
    loaded.current = true;
    const url = new URL(`${import.meta.env.VITE_APP_API}/api/Piece/filtered`);
    const params = { ReceiveSessionId: `${receiveSession.id}`, ItemsPerPage: `${ITEMS_PER_PAGE}`, pageNumber: '0' };
    const pages = receiveSession.totalPiecesCount ? Math.ceil(receiveSession.totalPiecesCount / ITEMS_PER_PAGE) : 0;

    const tasks: (() => Promise<undefined>)[] = [];

    times(
      (i) =>
        tasks.push(async () => {
          params.pageNumber = `${i}`;
          url.search = new URLSearchParams(params).toString();
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });
          const responseItems = (await response.json()).objectData as PiecePagedList;
          setItems(responseItems.items || []);
        }),
      pages,
    );

    const queue = new PromiseQueue(tasks);
    queue.run();
  };

  if (receiveSession && !loaded.current) {
    loadPiecese();
  }
};
