import { Box, CircularProgress } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import { CameraPicker } from '@common/components/CameraPicker';
import useLocalStorage from '@common/hooks/useLocalStorage';
import { useScreenOrientation } from '@common/hooks/useScreenOrientation';
import { useRdSnackbar } from '@common/uiKit/RdSnackbar';

import { CaptureButton } from './components/CaptureButton';

interface ICaptureScreen {
  capture: (image: string) => any;
  disabled?: boolean;
}

const IDEAL_WIDTH = 2560;
const IDEAL_HEIGHT = 2560;

export const CameraCapture = ({ capture, disabled = false }: ICaptureScreen) => {
  const webcamRef = useRef<Webcam>(null);

  const [cameraDevices, setCameraDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeCameraDeviceId, setActiveCameraDeviceId] = useLocalStorage<string>('rd_recent_camera_device', '');
  const [cameraInitialized, setCameraInitialized] = useState<boolean>(false);
  const [screenshotWidth, setScreenshotWidth] = useState<number>(0);
  const [screenshotHeight, setScreenshotHeight] = useState<number>(0);

  const orientation = useScreenOrientation();

  const { notify } = useRdSnackbar();

  const videoConstraints: MediaTrackConstraints = useMemo(() => {
    return {
      deviceId: activeCameraDeviceId || undefined,
      width: { ideal: 4032 },
      height: { ideal: 3048 },
      frameRate: { min: Number(import.meta.env.VITE_APP_CAMERA_FRAMERATE) || 24 },
    };
  }, [activeCameraDeviceId]);

  // In the below function .enumerateDevices() query must be executed after the camera has completely
  // been initialized and all necessary permissions were issued by the user. Otherwise devices list
  // returned by that call might not contain some vital data. Currently, in Chrome devices in the list
  // have emty string IDs, in Firefox devices labels are missing.
  const detectCameras = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter((device) => device.kind === 'videoinput');

    if (!cameras.length) {
      notify('There are no connected video devices.', { variant: 'error' });
    }

    setCameraDevices(cameras);
  };

  const onCameraSelected = (deviceId: string) => {
    setActiveCameraDeviceId(deviceId);
  };

  useEffect(() => {
    if (cameraInitialized) {
      detectCameras();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraInitialized]);

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = () => {
      detectCameras();
    };

    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!cameraDevices.length) return;

    if (!activeCameraDeviceId || !cameraDevices.find((device) => device.deviceId === activeCameraDeviceId)) {
      setActiveCameraDeviceId(cameraDevices[0].deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraDevices]);

  const handleCapture = () => {
    const videoTrack = webcamRef.current?.stream?.getVideoTracks()[0];
    if (videoTrack) {
      const imageSrc = webcamRef.current?.getScreenshot();
      if (imageSrc) {
        capture(imageSrc);
      }
    }
  };

  const handleOnUserMediaError = (error: string | DOMException) => {
    console.error(error);
    if (error.toString().includes('Permission denied')) {
      notify('Permission denied', { variant: 'error' });
      return;
    }

    notify('Application could not start the camera. Make sure it is not used by other application.', {
      variant: 'error',
    });
  };

  const onUserMedia = async (media: MediaStream) => {
    const videoTrack = media.getVideoTracks()[0];
    const settings = videoTrack.getSettings();
    const actualWidth = settings.width || IDEAL_WIDTH;
    const actualHeight = settings.height || IDEAL_HEIGHT;

    setScreenshotWidth(actualWidth);
    setScreenshotHeight(actualHeight);
    setCameraInitialized(true);
  };

  return (
    <Box
      className="rd-camera-capture"
      sx={{
        display: disabled ? 'none' : 'flex',
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
        justifyContent: 'center',
      }}
    >
      <Webcam
        style={{ maxWidth: '100%' }}
        onUserMedia={onUserMedia}
        onUserMediaError={handleOnUserMediaError}
        minScreenshotWidth={orientation === 'portrait' ? screenshotHeight : screenshotWidth}
        minScreenshotHeight={orientation === 'portrait' ? screenshotWidth : screenshotHeight}
        forceScreenshotSourceSize={true}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />

      {cameraInitialized && (
        <CaptureButton
          sx={{
            position: 'absolute',
            bottom: orientation === 'landscape' ? '50%' : 40,
            right: orientation === 'landscape' ? 40 : '50%',
            transform: orientation === 'landscape' ? 'translateY(50%)' : 'translateX(50%)',
          }}
          onClick={handleCapture}
        />
      )}

      {cameraInitialized && (
        <CameraPicker
          cameras={cameraDevices}
          value={activeCameraDeviceId}
          onCameraChange={onCameraSelected}
          sx={{
            position: 'absolute',
            top: orientation === 'landscape' ? 20 : 'auto',
            bottom: orientation === 'portrait' ? 20 : 'auto',
            right: 20,
          }}
        />
      )}

      {!cameraInitialized && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
