import { getPieceDetection, getRecipientStatus } from '../../../helper';
import { CapturePiece, RecipientStatus } from '../../../types';
import { GroupPiecesStatus, PieceByStatus } from '../types';

export const getPiecesByStatus = (pieces: Array<CapturePiece>, search: string) => {
  const pieceByStatus = pieces.reduce(
    (store, item) => {
      const pieceDetection = getPieceDetection(item);
      const recipient = item.piece?.recipient || pieceDetection?.recipient;
      const lowerSearch = search.toLowerCase();

      if (
        (!!lowerSearch && !recipient) ||
        (recipient?.displayName.toLowerCase().indexOf(lowerSearch) === -1 &&
          recipient?.userName.toLowerCase().indexOf(lowerSearch) === -1)
      ) {
        return store;
      }

      store[GroupPiecesStatus.all].push(item);

      if (item.piece?.recipient) {
        store[RecipientStatus.assigned].push(item);
        return store;
      }

      if (pieceDetection?.status !== undefined) {
        const recipientStatus = getRecipientStatus(item.piece, pieceDetection.status);

        switch (recipientStatus) {
          case RecipientStatus.assigned: {
            store[RecipientStatus.assigned].push(item);
            break;
          }
          case RecipientStatus.validate: {
            store[RecipientStatus.validate].push(item);
            break;
          }
          case RecipientStatus.unassigned: {
            store[RecipientStatus.unassigned].push(item);
            break;
          }
        }
      }

      if (item.status === 'loadedFromServer' && !item.piece?.recipient && !pieceDetection?.status) {
        store[RecipientStatus.unassigned].push(item);
      }

      if (item.status === 'failed') {
        store[GroupPiecesStatus.failed].push(item);
      }

      return store;
    },
    {
      [GroupPiecesStatus.all]: [],
      [RecipientStatus.assigned]: [],
      [RecipientStatus.validate]: [],
      [RecipientStatus.unassigned]: [],
      [GroupPiecesStatus.failed]: [],
    } as PieceByStatus,
  );

  return pieceByStatus;
};
