import { useEffect } from 'react';
import { Navigate, useRoutes, Outlet, useNavigate } from 'react-router-dom';

import { OidcUserStatus, useOidcUser, OidcSecure } from '@axa-fr/react-oidc';
import CompactLayout from '@minimalsLayouts/compact';
import DashboardLayout from '@minimalsLayouts/dashboard';
import { CaptureProvider } from 'src/common/contexts/CaptureContext';

import Capture from '@common/components/Capture';
import { SettingsProvider } from '@common/contexts/SettingsContext';

import { DefaultRecirect } from './DefaultRedirect';
import { AccountSetupGuard } from './guards/AccountSetupGuard';
import GuestGuard from './guards/GuestGuard';
import { RoleBasedGuard } from './guards/RoleBasedGuard';
import { UserProfileGuard } from './guards/UserProfileGuard';
import { AppCommonDataLoader } from './loaders/AppCommonDataLoader';
import {
  AdministrationAccountSetup,
  AdministrationAutomationCreate,
  AdministrationAutomationEdit,
  AdministrationAutomations,
  AdministrationCompany,
  AdministrationMailroom,
  AdministrationPurchasePlan,
  AdministrationUserCreate,
  AdministrationUserEdit,
  AdministrationUsersManagement,
  AdministrationUsersUpload,
  AuthRegister,
  Inbox,
  Mailpiece,
  MailroomActiveItems,
  MailroomActiveStorageGroups,
  MailroomDashboard,
  MailroomPendingRequests,
  MailroomSearch,
  MailroomStorageGroupContent,
  Onboarding,
  Page404,
  Page500,
  UserAccount,
  MailroomReceiveSessions,
} from './pages';
import { SessionLost } from 'src/common/components/SessionLost';
import { administrationRoutes, userRoutes } from './paths';

export default function Router() {
  const { oidcUser, oidcUserLoadingState } = useOidcUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!oidcUser && oidcUserLoadingState === OidcUserStatus.Loaded) {
      navigate('/session-timed-out');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUserLoadingState, oidcUser]);

  return useRoutes([
    // Protected routes:
    {
      element: (
        <OidcSecure>
          <UserProfileGuard>
            <SettingsProvider>
              <Outlet />
            </SettingsProvider>
          </UserProfileGuard>
        </OidcSecure>
      ),
      children: [
        {
          element: <AppCommonDataLoader />,
          children: [
            {
              index: true,
              element: <DefaultRecirect />,
            },

            {
              path: 'administration/account-setup',
              element: <AdministrationAccountSetup />,
            },

            {
              element: (
                <AccountSetupGuard>
                  <DashboardLayout>
                    <Outlet />
                  </DashboardLayout>
                </AccountSetupGuard>
              ),
              children: [
                {
                  path: 'superadmin/onboarding',
                  element: (
                    <RoleBasedGuard accessibleRoles={['KTAdmin']}>
                      <Onboarding />
                    </RoleBasedGuard>
                  ),
                },

                {
                  path: 'administration',
                  element: (
                    <RoleBasedGuard accessibleRoles={['Admin', 'KTAdmin']}>
                      <Outlet />
                    </RoleBasedGuard>
                  ),
                  children: [
                    {
                      path: 'company',
                      children: [
                        { index: true, element: <Navigate to={administrationRoutes.company('info')} replace /> },
                        { path: ':tab', element: <AdministrationCompany /> },
                        { path: 'purchase-plan', element: <AdministrationPurchasePlan /> },
                      ],
                    },
                    {
                      path: 'mailroom',
                      children: [
                        { index: true, element: <AdministrationMailroom /> },
                        { path: ':tab', element: <AdministrationMailroom /> },
                      ],
                    },
                    {
                      path: 'users',
                      children: [
                        { index: true, element: <AdministrationUsersManagement /> },
                        { path: 'create', element: <AdministrationUserCreate /> },
                        { path: 'edit/:id', element: <AdministrationUserEdit /> },
                        { path: 'upload', element: <AdministrationUsersUpload /> },
                      ],
                    },
                    {
                      path: 'automations',
                      children: [
                        { index: true, element: <AdministrationAutomations /> },
                        { path: 'create', element: <AdministrationAutomationCreate /> },
                        { path: 'edit/:id', element: <AdministrationAutomationEdit /> },
                      ],
                    },
                  ],
                },

                {
                  path: 'user/account',
                  children: [
                    { index: true, element: <Navigate to={userRoutes.account('general')} replace /> },
                    { path: ':label', element: <UserAccount /> },
                  ],
                },

                {
                  path: 'mailroom',
                  element: (
                    <RoleBasedGuard accessibleRoles={['Mailroom']}>
                      <CaptureProvider>
                        <Outlet />
                        <Capture />
                      </CaptureProvider>
                    </RoleBasedGuard>
                  ),
                  children: [
                    { path: 'dashboard', element: <MailroomDashboard /> },
                    { path: 'receive', element: <MailroomReceiveSessions /> },
                    { path: 'requests', element: <MailroomPendingRequests /> },
                    {
                      path: 'active',
                      children: [
                        {
                          path: 'items',
                          element: <MailroomActiveItems />,
                        },
                        {
                          path: 'storage-groups',
                          element: <MailroomActiveStorageGroups />,
                        },
                        {
                          path: 'storage-groups/:id',
                          element: <MailroomStorageGroupContent />,
                        },
                      ],
                    },
                    { path: 'search', element: <MailroomSearch /> },
                    { path: ':mailpieceCategory/:mailpieceId', element: <Mailpiece type="Mailroom" /> },
                  ],
                },

                {
                  path: 'inbox',
                  element: (
                    <RoleBasedGuard accessibleRoles={['Recipient']}>
                      <Outlet />
                    </RoleBasedGuard>
                  ),
                  children: [
                    { path: ':label', element: <Inbox /> },
                    { path: ':mailpieceCategory/:mailpieceId', element: <Mailpiece type="Inbox" /> },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    // Public routes:

    {
      path: 'auth/register',
      element: (
        <GuestGuard>
          <AuthRegister />
        </GuestGuard>
      ),
    },

    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: '404', element: <Page404 /> },
        // TODO: Looks like this page is unused, discuss...
        { path: '500', element: <Page500 /> },
        { path: 'session-timed-out', element: <SessionLost withLogout /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
