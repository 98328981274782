import { ElementType, Suspense, lazy } from 'react';

import LoadingScreen from '@common/components/LoadingScreen';

const Loadable =
  (Component: ElementType) =>
    (props: any) =>
      (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
      );

// General purpose page components and the ones used on multiple routes in the app.
export const Mailpiece = Loadable(lazy(() => import('../pages/MailpiecePage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));

// "Auth" routes group.
export const AuthRegister = Loadable(lazy(() => import('../pages/AuthRegister')));

// "User" routes group.
export const UserAccount = Loadable(lazy(() => import('../pages/UserAccount')));

// "Administration" routes group.
export const AdministrationAccountSetup = Loadable(lazy(() => import('../pages/AdministrationAccountSetup')));
export const AdministrationCompany = Loadable(lazy(() => import('../pages/AdministrationCompany')));
export const AdministrationPurchasePlan = Loadable(lazy(() => import('../pages/AdministrationPurchasePlan')));
export const AdministrationMailroom = Loadable(lazy(() => import('../pages/AdministrationMailroom')));
export const AdministrationUsersManagement = Loadable(lazy(() => import('../pages/AdministrationUsersManagement')));
export const AdministrationUserCreate = Loadable(lazy(() => import('../pages/AdministrationUserCreate')));
export const AdministrationUserEdit = Loadable(lazy(() => import('../pages/AdministrationUserEdit')));
export const AdministrationUsersUpload = Loadable(lazy(() => import('../pages/AdministrationUsersUpload')));
export const AdministrationAutomations = Loadable(lazy(() => import('../pages/AdministrationAutomations')));
export const AdministrationAutomationCreate = Loadable(lazy(() => import('../pages/AdministrationAutomationCreate')));
export const AdministrationAutomationEdit = Loadable(lazy(() => import('../pages/AdministrationAutomationEdit')));
export const Onboarding = Loadable(lazy(() => import('../pages/kt/Onboarding')));

// "Mailroom" routes group.
export const MailroomDashboard = Loadable(lazy(() => import('../pages/MailroomDashboard')));
export const MailroomStorageGroupContent = Loadable(lazy(() => import('../pages/MailroomStorageGroupContent')));
export const MailroomActiveItems = Loadable(lazy(() => import('../pages/MailroomActiveItems')));
export const MailroomActiveStorageGroups = Loadable(lazy(() => import('../pages/MailroomActiveStorageGroups')));
export const MailroomPendingRequests = Loadable(lazy(() => import('../pages/MailroomPendingRequests')));
export const MailroomSearch = Loadable(lazy(() => import('../pages/MailroomSearch')));
export const MailroomReceiveSessions = Loadable(lazy(() => import('../pages/MailroomReceiveSessions')));

// "Inbox" routes group.
export const Inbox = Loadable(lazy(() => import('../pages/Inbox')));
