import { List } from '@mui/material';
import { FC } from 'react';

import { sxDeskList } from '../../../styles';
import { ProgressStatus, RecipientStatus } from '../../../types';
import Item from './Item';

type DesktopListProps = {
  onChange: (data: ProgressStatus) => void;
  status: ProgressStatus;
  data: Record<ProgressStatus, number>;
};

const DesktopList: FC<DesktopListProps> = ({ onChange, status, data }) => {
  return (
    <List sx={sxDeskList} disablePadding>
      {Object.entries(data).map(([key, value]) => (
        <Item
          key={key}
          status={key as RecipientStatus}
          handleClick={onChange}
          isActive={key === status}
          count={value}
        />
      ))}
    </List>
  );
};

export default DesktopList;
