import { useState, useCallback, useRef } from 'react';

// ----------------------------------------------------------------------

export const getStorage = (key: string) => {
  let value = null;

  try {
    const result = window.localStorage.getItem(key);

    if (result) {
      value = JSON.parse(result);
    }
  } catch (error) {
    console.error(error);
  }

  return value;
};

export const setStorage = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
};

export const removeStorage = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

// ----------------------------------------------------------------------

export function useLocalStorage(key: string, initialState: any) {
  const prevKey = useRef<string | null>(key);

  const [state, setState] = useState({ ...initialState, ...getStorage(key) });

  if (prevKey.current !== key) {
    const restored = getStorage(key);

    if (restored) {
      setState((prevValue: any) => ({
        ...prevValue,
        ...restored,
      }));
    }
    prevKey.current = key;
  }

  const updateState = useCallback(
    (updateValue: any) => {
      setState((prevValue: any) => {
        setStorage(key, {
          ...prevValue,
          ...updateValue,
        });

        return {
          ...prevValue,
          ...updateValue,
        };
      });
    },
    [key],
  );

  // custom function release/1.4.3
  const replaceState = useCallback(
    (updateValue: any) => {
      setState(() => {
        setStorage(key, updateValue);

        return updateValue;
      });
    },
    [key],
  );

  const update = useCallback(
    (name: string, updateValue: any) => {
      updateState({
        [name]: updateValue,
      });
    },
    [updateState],
  );

  const reset = useCallback(() => {
    removeStorage(key);
    setState(initialState);
  }, [initialState, key]);

  return {
    state,
    update,
    updateState,
    replaceState,
    reset,
  };
}
