import { InfoRounded } from '@mui/icons-material';
import { FormHelperText } from '@mui/material';

export const ExistingContainerWarning = () => {
  return (
    <FormHelperText sx={{ mt: 0 }}>
      <InfoRounded sx={{ width: '18px', height: '18px', verticalAlign: 'middle' }} />
      <span style={{ verticalAlign: 'middle', marginLeft: '4px' }}>This storage group already exists</span>
    </FormHelperText>
  );
};
