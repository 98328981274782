import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';

import { useGetContainerTypesQuery } from '@api/container';
import { Workflow } from '@apiContract';
import { WorkflowNameByEnum } from '../types/contractHelpers';

type SelectStorageTypeProps = {
  workflow: Workflow;
  containerTypeId: string;
  setContainerTypeId: (value: string) => void;
};

export const SelectStorageType = (props: SelectStorageTypeProps) => {
  const { workflow, containerTypeId, setContainerTypeId } = props;

  const { data: containerTypes = [] } = useGetContainerTypesQuery();

  const storageGroupTypeOptions = useMemo(
    () =>
      containerTypes.reduce<{ value: number; label: string }[]>(
        (options, type) => (type.workflow === WorkflowNameByEnum[workflow] ? [...options, { value: type.id, label: type.name }] : options),
        [],
      ),
    [containerTypes, workflow],
  );

  return (
    <FormControl fullWidth>
      <InputLabel>Type</InputLabel> 
      <Select
        value={containerTypeId}
        label="Type"
        onChange={(event: SelectChangeEvent) => {
          setContainerTypeId(event.target.value);
        }}
      >
        {storageGroupTypeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
