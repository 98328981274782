import { type Theme } from '@mui/material';

export const sxPaper = {
  width: 1,
  maxWidth: 376,
  display: 'flex',
  flexDirection: 'column',
  p: 1,
};

export const sxList = (theme: Theme) => ({
  position: 'relative',
  display: 'flex',
  flexGrow: '1',
  '& .companiesScrollBar': {
    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[100],
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '12px',
    },
  },
  height: 330,
});

export const sxNoResult = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const sxItemButton = (selected: boolean, theme: Theme) => ({
  borderRadius: 1,
  py: 1.5,
  px: 2.5,
  bgcolor: selected ? theme.palette.action.selected : 'transparent',
});
