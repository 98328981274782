export const sxLoading = {
  box: {
    alignSelf: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '50%',
  },
  progress: { width: '100%', mt: 1, mb: 1.5 },
};