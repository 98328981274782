import { Navigate } from 'react-router-dom';

import { useGetMyProfileQuery } from '@api/user';

import LoadingScreen from '@common/components/LoadingScreen';
import { useUserPermissions } from '@common/hooks/useUserPermissions';

export type Role = 'KTAdmin' | 'Admin' | 'Recipient' | 'Mailroom';

type RoleBasedGuardProp = {
  accessibleRoles: Role[];
  children: React.ReactElement;
};

export const RoleBasedGuard = ({ accessibleRoles, children }: RoleBasedGuardProp) => {
  const { data: currentUser } = useGetMyProfileQuery();

  const { isAllowed } = useUserPermissions();

  if (!currentUser) return <LoadingScreen />;

  if (!isAllowed(accessibleRoles)) {
    return <Navigate to="404" />;
  }

  return children;
};
