import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import { Box, IconButton, ListItemText, Stack } from '@mui/material';
import { CSSProperties, useEffect, useRef } from 'react';

import Iconify from '@minimalsComponents/iconify';

import { fData } from '@utils/format-number';

import { sxRow } from '../../../styles';
import { CustomFile } from '../../../types';

const GAP_ROW = 8;

const Row = ({
  index,
  onRemove,
  setSize,
  style,
  file,
}: {
  style: CSSProperties;
  onRemove: ((file: CustomFile | string) => void) | undefined;
  setSize: (index: number, size: number) => void;
  file: CustomFile;
  index: number;
}) => {
  const { name = '', size = 0 } = file;
  const isNotFormatFile = typeof file === 'string';
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSize(index, (rowRef.current?.getBoundingClientRect().height as number) + GAP_ROW);
  }, [setSize, index]);

  return (
    <Box style={style}>
      <Stack ref={rowRef} key={name + index} spacing={2} direction="row" alignItems="center" sx={sxRow}>
        <InsertDriveFileRoundedIcon fontSize="large" />

        <ListItemText
          primary={isNotFormatFile ? (file as string) : name}
          primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
          secondary={isNotFormatFile ? '' : fData(size)}
          secondaryTypographyProps={{
            component: 'span',
            typography: 'caption',
          }}
        />
        {onRemove && (
          <IconButton size="small" onClick={() => onRemove(file)}>
            <Iconify icon="mingcute:close-line" width={18} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};

export default Row;
