import { Stack, ToggleButton } from '@mui/material';

import { ReceiveToggleButtonGroupConfig } from '../../types';

interface ReceiveToggleButtonGroupProps<T> {
  config: ReceiveToggleButtonGroupConfig<T>[];
  value: T;
  disabled?: boolean;
  onChange: (value: T) => void;
}

export const ReceiveToggleButtonGroup = <T extends string | number>({
  config,
  value,
  disabled: groupDisabled,
  onChange,
}: ReceiveToggleButtonGroupProps<T>) => {
  return (
    <Stack spacing={2} sx={{ flexDirection: { xs: config.length < 3 ? 'row' : 'column', md: 'row' } }}>
      {config.map(({ value: configItemValue, label, Icon, disabled }) => (
        <ToggleButton
          key={configItemValue as string}
          value={configItemValue as NonNullable<T>}
          selected={configItemValue === value}
          onChange={(_, newValue: T) => {
            onChange(newValue);
          }}
          disabled={groupDisabled || disabled}
          color="primary"
          size="large"
          sx={{ flex: 1 }}
        >
          <Icon sx={{ mr: 1 }} /> {label}
        </ToggleButton>
      ))}
    </Stack>
  );
};
