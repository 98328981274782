import { useGetMyProfileQuery } from '@api/user';
import { PieceAction, User } from '@apiContract';

import { CapturePiece } from '../types';
import { AddEvent } from './useUpdateQueue';

export const useAssignmentRecipient = (addEvent: (param: AddEvent) => void) => {
  const { data: profile } = useGetMyProfileQuery();

  const assignRecipientToPiece = (user: User, file: CapturePiece) => {
    if (profile && file.piece) {
      addEvent({
        event: { id: file.piece.id, recipientId: user.id },
        action: PieceAction.Assign,
        source: 'user',
      });
    }
  };
  return { assignRecipientToPiece };
};
