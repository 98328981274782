import { FC } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PieceStatus } from '@apiContract';

import { mailroomRoutes } from '@routes';

import { DialogConfirmation } from '@common/uiKit/DialogConfirmation';

import { DialogProcess } from '../types';

const UnresolvedDialog = {
  title: 'Finish with unresolved items?',
  content:
    'Some items are either unassigned or need to be validated. If you finish now, unassigned items will ' +
    'remain unassigned, and items that need to be validated will be assigned as they are.',
  accept: 'Finish',
  cancel: 'Continue Assigning',
};

const dialogContent = {
  [DialogProcess.Uploading]: {
    title: 'Quit uploading and discard items?',
    content: 'These items will be lost if you quit now.',
    accept: 'Discard Items',
    cancel: 'Continue Uploading',
  },
  [DialogProcess.Unresolved]: UnresolvedDialog,
  [DialogProcess.UnresolvedByClose]: UnresolvedDialog,
};

type DialogProps = {
  dialog: DialogProcess | null;
  setDialog: (data: DialogProcess | null) => void;
  handleClose: () => void;
};

const Dialog: FC<DialogProps> = ({ dialog, setDialog, handleClose }) => {
  const navigate = useNavigate();

  return (
    <DialogConfirmation
      open={!!dialog}
      handleClose={() => setDialog(null)}
      title={dialog ? dialogContent[dialog].title : ''}
      content={dialog ? dialogContent[dialog].content : ''}
      acceptButtonHandleClick={() => {
        setDialog(null);
        handleClose();
        if (dialog === DialogProcess.Unresolved) {
          navigate({
            pathname: mailroomRoutes.items,
            search: createSearchParams({
              PieceStatus: PieceStatus.Unsent.toString(),
            }).toString(),
          });
        }
      }}
      cancelButtonHandleClick={() => setDialog(null)}
      acceptButtonTitle={dialog ? dialogContent[dialog].accept : ''}
      cancelButtonTitle={dialog ? dialogContent[dialog].cancel : ''}
      acceptButtonProps={{ variant: 'text' }}
      cancelButtonProps={{ variant: 'text' }}
      maxWidth="sm"
    />
  );
};

export default Dialog;
