import { SearchRounded } from '@mui/icons-material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useGetCompaniesFilteredQuery } from '@api/company';
import { useGetMyProfileQuery, useSetCompanyUserMutation } from '@api/user';
import { Company, CompanyFilteredListParams } from '@apiContract';
import CustomPopover from '@minimalsComponents/custom-popover';

import List from './components/List';
import { sxPaper } from './styles';

const ITEMS_PER_PAGE = 20;

const initialFilters = {
  ItemsPerPage: ITEMS_PER_PAGE,
  PageNumber: 0,
  SearchText: '',
  Descending: false,
};
const emptyData = { pageNumber: 0, items: [], totalCount: 0 };

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filters, setFilters] = useState<CompanyFilteredListParams>(initialFilters);

  const [companies, setCompanies] = useState<Array<Company>>([]);
  const { data = emptyData, isFetching } = useGetCompaniesFilteredQuery(filters, { skip: !anchorEl });
  const [setCompanyUser] = useSetCompanyUserMutation();
  const { data: profile } = useGetMyProfileQuery();

  useEffect(() => {
    if (data.pageNumber === 0) {
      setCompanies(data?.items || []);
    } else {
      setCompanies((prev) => [...prev, ...(data.items || [])]);
    }
  }, [data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilters({ ...initialFilters, SearchText: '', PageNumber: 0 });
  };

  const onSearch = (value: string) => {
    setFilters({ ...initialFilters, SearchText: value, PageNumber: 0 });
  };

  const loadNextPage = useCallback((startIndex: number) => {
    setFilters((prev) => ({
      ...prev,
      PageNumber: Math.floor(startIndex / ITEMS_PER_PAGE),
      ItemsPerPage: ITEMS_PER_PAGE,
    }));
  }, []);

  const updateUserCompany = useCallback(
    (company: Company) => {
      setCompanyUser({ companyId: company.id });
      window.location.reload();
    },
    [setCompanyUser],
  );

  const hasNextPage = (data.totalCount || 0) > companies.length;

  return (
    <>
      <Button
        sx={{ color: 'text.secondary' }}
        onClick={handleClick}
        endIcon={<ExpandMoreRoundedIcon color="disabled" />}
      >
        {profile?.company.name}
      </Button>
      <CustomPopover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: sxPaper,
          },
        }}
      >
        <Stack p={1.5} spacing={2}>
          <Typography variant="h6">Change Company</Typography>
          <TextField
            value={filters.SearchText}
            onChange={(e) => onSearch(e.target.value)}
            fullWidth
            placeholder="Search..."
            name="search"
            InputProps={{ startAdornment: <SearchRounded color="disabled" sx={{ mr: 1 }} /> }}
          />
        </Stack>
        <List
          loadNextPage={loadNextPage}
          isNextPageLoading={isFetching}
          hasNextPage={hasNextPage}
          items={companies}
          selectedItem={profile?.company}
          setSelectedItem={updateUserCompany}
        />
      </CustomPopover>
    </>
  );
}
