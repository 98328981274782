import EventRoundedIcon from '@mui/icons-material/EventRounded';
import { DatePicker } from '@mui/x-date-pickers';

import { useLocationId } from '@common/hooks/useLocationId';

interface ContainerDatePickerProps {
  value: Date | null;
  error: boolean;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  onChange: (newValue: Date | null) => void;
}

export const ContainerDatePicker = ({ value, error, size, fullWidth = false, onChange }: ContainerDatePickerProps) => {
  const { locationIdDateFormat, locationIdDateUppercase } = useLocationId();

  return (
    <DatePicker
      value={value}
      defaultValue={value}
      onChange={(date) => {
        let newDate = null;

        if (date instanceof Date && !isNaN(date.getTime())) {
          newDate = new Date();
          newDate.setFullYear(date.getFullYear());
          newDate.setMonth(date.getMonth());
          newDate.setDate(date.getDate());
        }

        onChange(newDate);
      }}
      label="Date"
      format={locationIdDateFormat}
      slots={{
        openPickerIcon: EventRoundedIcon,
      }}
      slotProps={{
        textField: {
          sx: { '& .MuiInputBase-input': { textTransform: locationIdDateUppercase ? 'uppercase' : 'none' } },
          size: size,
          onKeyDown: (event) => event.preventDefault(),
          error: error,
          fullWidth,
        },
      }}
    />
  );
};
