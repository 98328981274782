import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useRef, useImperativeHandle, useState } from 'react';

import { useDebounce } from '@common/hooks/useDebounce';

export type DebounceTextFieldHandle = {
  resetDebouncedTextFieldValue: () => void;
  setValue: (data: string) => void;
};

type Props = TextFieldProps & {
  defaultValue?: string;
  onChangeDebounced: (data: string) => void;
};

const DebounceTextField = forwardRef<DebounceTextFieldHandle, Props>(
  ({ onChangeDebounced, defaultValue, ...textFieldProps }: Props, ref) => {
    const [value, setValue] = useState<string>(defaultValue || '');
    const debounceValue = useDebounce<string>(value);

    const debounceRef = useRef(debounceValue);

    useImperativeHandle(
      ref,
      () => {
        return {
          resetDebouncedTextFieldValue: () => setValue(''),
          setValue,
        };
      },
      [],
    );

    if (debounceValue !== debounceRef.current) {
      onChangeDebounced(debounceValue);
      debounceRef.current = debounceValue;
    }

    return (
      <TextField
        fullWidth
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
        {...textFieldProps}
      />
    );
  },
);

export default DebounceTextField;
