import { FlipToBackRounded, FlipToFrontRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { Workflow } from '@apiContract';

import { ContainerDatePicker } from '@common/components/ContainerDatePicker';
import { ExistingContainerWarning } from '@common/components/ExistingContainerWarning';
import { SelectMailCarrier } from '@common/components/SelectMailCarrier';
import { SelectStorageType } from '@common/components/SelectStorageType';
import DebounceTextField from '@common/uiKit/DebounceTextField';

import { ToggleButtonGroup, ToggleButtonGroupConfig } from './components/ToggleButtonGroup';
import { SortingOrder, StorageGroupProps } from './types';
import { useStorageGroup } from './useStorageGroup';

export * from './types';
const sortingOrderOptions: ToggleButtonGroupConfig<SortingOrder>[] = [
  {
    value: SortingOrder.addToFront,
    label: 'Add to front',
    Icon: FlipToFrontRounded,
  },
  {
    value: SortingOrder.addToBack,
    label: 'Add to back',
    Icon: FlipToBackRounded,
  },
];
const TOOLTIP = 'Sorting order cannot be changed after storage group is created';

const StorageGroup: FC<StorageGroupProps> = ({
  workflow,
  itemType,
  sx = {},
  containerTypes,
  direction = 'row',
  defaultValue,
  onChange,
  onLoading,
  isLoading,
  isNextOrderNo,
}) => {
  const {
    nameFieldLabel,
    containerTypeId,
    date,
    containerNumber,
    existingContainer,
    sortingOrder,
    fetchingExistingContainer,
    onChangeValue,
  } = useStorageGroup({
    defaultValue,
    containerTypes,
    workflow,
    onChange,
    itemType,
    onLoading,
    isLoading,
    isNextOrderNo,
  });

  return (
    <Stack sx={sx} spacing={3}>
      <Stack spacing={3} direction={{ xs: 'column', md: direction }}>
        {workflow === Workflow.DateReceived && (
          <>
            <ContainerDatePicker value={date} error={!date} fullWidth onChange={onChangeValue('date')} />
            <SelectStorageType
              workflow={workflow}
              containerTypeId={containerTypeId}
              setContainerTypeId={onChangeValue('containerTypeId')}
            />
          </>
        )}
        {(workflow === Workflow.DateReceived || workflow === Workflow.Location) && (
          <DebounceTextField
            label={nameFieldLabel}
            defaultValue={containerNumber}
            onChangeDebounced={onChangeValue('containerNumber')}
          />
        )}
        {workflow === Workflow.Carrier && (
          <SelectMailCarrier carrier={containerNumber} setCarrier={onChangeValue('containerNumber')} />
        )}
      </Stack>

      {existingContainer && <ExistingContainerWarning />}

      {workflow === Workflow.DateReceived && (
        <Stack spacing={2}>
          <Typography variant="subtitle2">Sorting order</Typography>
          <ToggleButtonGroup
            config={sortingOrderOptions}
            value={sortingOrder}
            tooltip={existingContainer ? TOOLTIP : ''}
            disabled={fetchingExistingContainer || !!existingContainer}
            onChange={onChangeValue('sortingOrder')}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default StorageGroup;
