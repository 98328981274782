import axios from 'axios';

// ----------------------------------------------------------------------

// const axiosInstance = axios.create();

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

export const configureBaseURLAxios = () => {
  axios.defaults.baseURL = `${import.meta.env.VITE_APP_API}/api`;
};

export const configureTokenAxios = (token: string) => {
  axios.defaults.headers.common.Authorization = token ? 'Bearer ' + token : '';
};

export const axiosDefaultInstance = axios.create();

// export default axiosInstance;
