import type {
  BooleanResponse,
  Subscription,
  SubscriptionRequest,
  SubscriptionResponse,
  SubscriptionSummary,
  SubscriptionSummaryResponse,
  UpcomingInvoice,
  UpcomingInvoiceResponse,
} from '@apiContract';

import { receivedDigitalApi } from './index';

const subscriptionApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionSummary: builder.query<SubscriptionSummary, void>({
      query: () => 'Subscription/Summary',
      providesTags: ['Subscription'],
      transformResponse: (response: SubscriptionSummaryResponse) => response.objectData,
    }),

    createSubscription: builder.mutation<Subscription, SubscriptionRequest>({
      query: (requestData) => ({
        url: 'Subscription',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['Subscription'],
      transformResponse: (response: SubscriptionResponse) => response.objectData,
    }),

    updateSubscription: builder.mutation<Subscription, SubscriptionRequest>({
      query: (requestData) => ({
        url: 'Subscription',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Subscription'],
      transformResponse: (response: SubscriptionResponse) => response.objectData,
    }),

    cancelSubscription: builder.mutation<boolean, void>({
      query: () => ({
        url: 'Subscription/cancel',
        method: 'PUT',
      }),
      invalidatesTags: ['Subscription'],
      transformResponse: (response: BooleanResponse) => response.objectData,
    }),

    reactivateSubscription: builder.mutation<boolean, void>({
      query: () => ({
        url: 'Subscription/reactivate',
        method: 'PUT',
      }),
      invalidatesTags: ['Subscription'],
      transformResponse: (response: BooleanResponse) => response.objectData,
    }),

    calculateUpcomingInvoice: builder.mutation<UpcomingInvoice, SubscriptionRequest>({
      query: (requestData) => ({
        url: 'Subscription/upcoming',
        method: 'PUT',
        body: requestData,
      }),
      transformResponse: (response: UpcomingInvoiceResponse) => response.objectData,
    }),
  }),
});

export const {
  useGetSubscriptionSummaryQuery,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useReactivateSubscriptionMutation,
  useCalculateUpcomingInvoiceMutation,
} = subscriptionApi;
