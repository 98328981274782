import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { CSSProperties, FC } from 'react';

import { PieceAction, PieceAssetSize } from '@apiContract';
import Label from '@minimalsComponents/label';
import { SentIcon } from 'src/assets/icons/SentIcon';

import { getPieceAssetUrl } from '@utils/piece';

import { getPieceDetection, getPieceRecipientParam, getRecipientStatus } from '../../../../helper';
import { CaptureId, CapturePiece, ColorStatus, RecipientStatus } from '../../../../types';
import {
  sxAvatar,
  sxAvatarBase,
  sxDisabled,
  sxImg,
  sxPiece,
  sxPieceItemBtn,
  sxPieceItemBtnActive,
  sxSendIcon,
  sxSkeleton,
} from '../../styles';

type ItemProps = {
  handleClick: (cursor: CaptureId, capturePiece: CapturePiece) => void;
  style: CSSProperties;
  piece: CapturePiece;
  isActive: boolean;
};

const Item: FC<ItemProps> = ({ handleClick, style, isActive, piece }) => {
  const pieceDetection = getPieceDetection(piece);

  const status = getRecipientStatus(piece.piece, pieceDetection?.status);
  const isFailed = piece.status === 'failed';

  const isSend = (piece?.piece?.lifecycleStage || 0) >= PieceAction.Submit;

  return (
    <>
      {piece.piece || piece.status === 'failed' ? (
        <ListItem disablePadding style={style} component="div" sx={sxPiece}>
          <ListItemButton
            onClick={() => handleClick(piece?.id || '', piece)}
            sx={isActive ? sxPieceItemBtnActive : sxPieceItemBtn}
          >
            <ListItemAvatar sx={{ position: 'relative' }}>
              <Avatar
                alt="Piece"
                src={
                  getPieceAssetUrl(piece.piece?.pieceAssets || [], PieceAssetSize.Small) ||
                  (piece.photo instanceof File ? URL.createObjectURL(piece.photo) : piece.photo)
                }
                variant="rounded"
                imgProps={{ sx: sxImg }}
                sx={{ ...sxAvatar, ...(isSend ? sxDisabled : {}) }}
              />
              {isSend ? <SentIcon sx={sxSendIcon} /> : null}
            </ListItemAvatar>
            <ListItemText
              sx={isSend ? sxDisabled : {}}
              primary={
                <Typography color="" variant="subtitle2">
                  {getPieceRecipientParam(piece.piece, pieceDetection, 'displayName')}
                </Typography>
              }
              secondary={
                <Typography color="text.secondary" variant="body2">
                  {getPieceRecipientParam(piece.piece, pieceDetection, 'userName')}
                </Typography>
              }
            />
            <Label
              sx={isSend ? sxDisabled : {}}
              variant="soft"
              color={isFailed ? 'error' : ColorStatus[status as RecipientStatus]}
            >
              {isFailed ? 'failed' : status}
            </Label>
          </ListItemButton>
        </ListItem>
      ) : (
        <Box display="flex" style={style} sx={sxSkeleton} alignItems="center" gap={2}>
          <Skeleton variant="rounded" sx={sxAvatarBase} />
          <Box flex={1}>
            <Skeleton variant="text" width="75%" />
            <Skeleton variant="text" width="55%" />
          </Box>
          <Skeleton variant="rounded" width={64} height={24} />
        </Box>
      )}
    </>
  );
};

export default Item;
