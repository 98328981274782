export const styles = {
  container: {
    position: 'relative',
    width: '44px',
    height: '44px',
    p: '2px',
    mr: 1.5,
    cursor: 'pointer',
  },
  icon: (themeColor?: string) => ({
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '50%',
    left: '50%',
    mt: '-10px',
    ml: '-10px',
    color: themeColor || 'text.secondary',
  }),
};

export const sxRetryContainer = {
  p: 2.5,
  width: 376,
};

export const sxPopover = {
  backgroundImage: 'unset',
};

export const sxTitle = {
  mb: 2.5,
};

export const sxRetryBtn = {
  width: 1,
  bgcolor: 'action.hover',
};
