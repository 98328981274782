import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { ButtonConfig, IconConfig } from '../../types';
import IconWithLoader from '../IconWithLoader';
import { styles } from './styles';
import ButtonsList from '../ButtonsList';

interface HeaderProps {
  onClose: () => any;
  buttons: ButtonConfig[];
  actions?: string[];
  title?: string;
  icons?: IconConfig[];
}

const Header = ({ buttons, icons = [], title = '', onClose }: HeaderProps) => {
  const sx = styles(buttons.length);

  return (
    <Box sx={sx.container}>
      <Box display="flex" alignItems="center" gap={2}>
        <Box sx={sx.iconBox}>
          <IconButton onClick={onClose}>
            <Close style={sx.icon} />
          </IconButton>
        </Box>
        {title && (
          <Typography sx={sx.title} fontWeight="bold" color="white">
            {title}
          </Typography>
        )}
      </Box>

      <Box sx={sx.actions}>
        {icons.map((icon) => (
          <IconWithLoader key={icon.icon} {...icon} />
        ))}
        <Box sx={sx.mobileDesktopButton}>
          <Box sx={sx.groupButton}>
            <ButtonsList buttons={buttons} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
