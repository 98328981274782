import type { Setting, SettingArrayResponse, SettingRequest } from '@apiContract';

import { receivedDigitalApi } from './index';

const settingsApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<Setting[], void>({
      query: () => 'Setting',
      providesTags: ['Settings'],
      transformResponse: (response: SettingArrayResponse) => response.objectData,
    }),

    updateSettingsUser: builder.mutation<Setting[], SettingRequest[]>({
      query: (requestData) => ({
        url: 'Setting/user',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Settings'],
      transformResponse: (response: SettingArrayResponse) => response.objectData,
    }),

    updateSettingsMailroom: builder.mutation<Setting[], SettingRequest[]>({
      query: (requestData) => ({
        url: 'Setting/mailroom',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Settings', 'Pieces'],
      transformResponse: (response: SettingArrayResponse) => response.objectData,
    }),

    updateSettingsCompany: builder.mutation<Setting[], SettingRequest[]>({
      query: (requestData) => ({
        url: 'Setting/company',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Settings'],
      transformResponse: (response: SettingArrayResponse) => response.objectData,
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useUpdateSettingsUserMutation,
  useUpdateSettingsMailroomMutation,
  useUpdateSettingsCompanyMutation,
} = settingsApi;
