/* eslint-disable @typescript-eslint/naming-convention */
// TOD: fix name convention
import { type Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { Variants } from 'framer-motion';

export const sxGridContainer = (theme: Theme) => ({
  bgcolor: { xs: theme.palette.grey[800], md: 'background.default' },
  flex: 1,
  flexDirection: { xs: 'column', md: 'row' },
  position: 'relative',
  overflow: 'hidden',
  padding: 2,
  pb: { xs: 0, md: 2 },
  height: 1,
  minHeight: 300,
});

export const sxGridChild_1 = {
  flexDirection: { xs: 'column', md: 'row' },
  flexGrow: { xs: 1 },
  p: { xs: 1, md: 'unset' },
  bgcolor: 'background.default',
  gap: { xs: 1, md: 'unset' },
  borderRadius: { xs: 1.5, md: 0 },
};

export const sxGridChild_1_1 = {
  py: { xs: 0, md: 2 },
  px: { xs: 0, lg: 1.5 },
  paddingRight: { md: 0.5, lg: 1.5 },
  width: 1,
};

export const sxGridChild_1_2 = (theme: Theme) => ({
  bgcolor: theme.palette.grey[800],
  borderRadius: 1.5,
  flexDirection: 'column',
  flexGrow: { xs: 1 },
});

export const sxGridChild_2 = {
  flexGrow: { xs: 1 },
  p: { xs: 2, md: 0 },
  pb: { xs: 0 },
  height: { xs: 1, md: 'unset' },
  justifyContent: 'center',
};

export const sxGridChild_2_1 = (theme: Theme) => ({
  borderRadius: 1.5,
  bgcolor: { xs: 'background.default', md: theme.palette.grey[800] },
  flexDirection: 'column',
  flexGrow: 1,
  p: { xs: 1, md: 0 },
});

export const sxGridChild_2_1_1 = {
  p: { xs: 0, md: 2 },
  bgcolor: { xs: 'background.default', md: 'transparent' },
  borderTopLeftRadius: { xs: 12, md: 0 },
  borderTopRightRadius: { xs: 12, md: 0 },
};

export const sxGridChild_2_1_1_1 = (theme: Theme) => ({
  flexGrow: { xs: 0, md: 1 },
  p: { xs: 2, md: 0 },
  bgcolor: { xs: theme.palette.grey[800], md: 'transparent' },
  borderTopLeftRadius: { xs: 12, md: 0 },
  borderTopRightRadius: { xs: 12, md: 0 },
});

export const sxGridChild_2_1_1_2 = {
  p: { xs: 2, md: 0 },
};

export const sxGridChild_2_1_2 = (theme: Theme) => ({
  p: 2,
  paddingTop: 0,
  bgcolor: { xs: theme.palette.grey[800], md: 'transparent' },
  borderBottomLeftRadius: { xs: 12, md: 0 },
  borderBottomRightRadius: { xs: 12, md: 0 },
});

export const sxTabs = {
  px: 2,
  width: 1,
  '& > .scroll-button:first-child': {
    marginLeft: -2,
  },
  '& > .scroll-button:last-child': {
    marginRight: -2,
  },
};

export const sxItemStatus = {
  p: 1,
  paddingLeft: { md: 0.5, lg: 2 },
  paddingRight: { md: 0.5, lg: 1 },
};

export const sxStatusTitle = {
  textTransform: 'capitalize',
};

export const sxPiece = {
  paddingLeft: 2,
  paddingRight: '10px',
};

export const sxSkeleton = {
  paddingLeft: 3,
  paddingRight: '18px',
};

export const sxP2 = {
  p: 2,
};

export const sxPX2 = {
  px: 2,
};

export const sxPieceList = (theme: Theme) => ({
  position: 'relative',
  '& .customScrollBar': {
    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[800],
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[700],
      borderRadius: '12px',
    },
  },
});

export const sxAvatarBase = {
  width: '64px',
  height: '64px',
};

export const sxAvatar = {
  ...sxAvatarBase,
  bgcolor: 'common.black',
};

export const sxPieceItemBtn = {
  borderRadius: 2,
  p: 1,
};

export const sxPieceItemBtnActive = (theme: Theme) => ({
  ...sxPieceItemBtn,
  bgcolor: theme.palette.action.hover,
});

export const sxImageContainer = {
  bgcolor: 'black',
  width: 1,
  height: 1,
  borderRadius: 1,
};

export const sxDeskList = {
  width: 1,
};

export const sxPieceViewItem = {
  borderRadius: { xs: 1.5, md: 0 },
  minHeight: '48px',
};
export const sxBackBtn = {
  display: { xs: 'flex', md: 'none' },
};

export const animationOpenClosed: Variants = {
  open: {
    x: 0,
    display: 'flex',
    opacity: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  closed: {
    opacity: 0,
    x: '50%',
    display: 'none',
    position: 'relative',
    transitionEnd: {},
  },
};

export const sxMR0 = {
  mr: 0,
};

export const sxImg: CSSProperties = { objectFit: 'contain', cursor: 'zoom-in', maxWidth: 'unset' };

export const sxImgEmpty_160: CSSProperties = {
  height: '160px',
  width: '160px',
};

export const sxImgEmpty_60: CSSProperties = {
  height: '60px',
  width: '100%',
};

export const sxCenter = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const sxSendIcon = {
  position: 'absolute',
  zIndex: 1000,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  ml: 0.5,
  mt: 0.5,
};

export const sxDisabled = {
  opacity: 0.48,
};
