const containerShared = {
  minHeight: '64px',
  width: '100%',
  backgroundColor: 'background.default',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  pr: 3,
  pl: 1.5,
  boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
  zIndex: 1500,
};

export const styles = (buttonsCount: number) => ({
  container: {
    ...containerShared,
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconBox: { display: 'flex', justifyContent: 'flex-start' },
  icon: { fontSize: '30px', color: 'white' },
  title: { textAlign: 'center', justifySelf: 'center' },
  mobileDesktopButton: {
    display: { xs: 'none', md: 'block' },
    backgroundColor: 'inherit',
  },
  groupButton: {
    display: 'flex',
    width: 1,
    justifyContent: { xs: 'space-between', md: 'flex-end' },
    gap: 1.5,
  },
  buttonWidth: { width: buttonsCount === 1 ? '100%' : '49%' },
});
