import { useEffect, useMemo, useRef, useState } from 'react';

import { GetContainerByRequestRequest, useGetContainerByRequestQuery } from '@api/container';
import { PieceType, Workflow } from 'src/common/types/apiContract';
import { WorkflowNameByEnum } from 'src/common/types/contractHelpers';

import { useSettings } from '@common/hooks/useSettings';

import { ReceiveSettings, SortingOrder, StorageGroupProps } from './types';
import { getDateStringWithoutTime } from 'src/common/utils/time';

const settingsNames = ['ReceiveMailWorkflow', 'ReceivePackagesWorkflow', 'ReceiveSortOrder'];

export const useStorageGroup = ({
  defaultValue,
  containerTypes,
  workflow,
  onChange,
  itemType,
  onLoading,
  isLoading,
  isNextOrderNo,
}: StorageGroupProps) => {
  const receiveSettings = useSettings(settingsNames) as ReceiveSettings;
  const [container, setContainer] = useState({
    id: null,
    date: (defaultValue?.createdDate || workflow === Workflow.MailBox) ? new Date(defaultValue?.createdDate || Date.now()) : null,
    containerTypeId:
      (defaultValue?.containerTypeId
        ? defaultValue?.containerTypeId
        : containerTypes?.find((type) => type.workflow === WorkflowNameByEnum[workflow])?.id
      )?.toString() || '',
    containerNumber: defaultValue?.containerNumber || '',
    sortingOrder: receiveSettings.ReceiveSortOrder,
  });
  const { date, containerTypeId, containerNumber, sortingOrder } = container;
  const prevWorkflow = useRef<Workflow | null>(null);

  const existingContainerRequestData = useMemo<GetContainerByRequestRequest>(() => {
    const requestData: GetContainerByRequestRequest = {};

    if (date) requestData.CreatedDate = getDateStringWithoutTime(date);
    if (containerTypeId) requestData.ContainerTypeId = containerTypeId;
    if (containerNumber) requestData.ContainerNumber = containerNumber;

    return requestData;
  }, [date, containerTypeId, containerNumber]);

  const { data: existingContainer, isFetching: fetchingExistingContainer } = useGetContainerByRequestQuery(
    existingContainerRequestData,
    {
      skip:
        !existingContainerRequestData.ContainerTypeId ||
        [Workflow.HighDensityMail, Workflow.MailBox].includes(workflow),
      refetchOnMountOrArgChange: true,
    },
  );

  const onChangeValue = (key: string) => (value: any) => {
    const nextContainer = { ...container, [key]: value };
    setContainer(nextContainer);
    onChange({
      id: nextContainer.id,
      createdDate: nextContainer.date ? getDateStringWithoutTime(nextContainer.date) : null,
      containerTypeId: +nextContainer.containerTypeId,
      containerNumber: nextContainer.containerNumber,
      sortOrder: nextContainer.sortingOrder,
    });
  };

  if (isLoading !== fetchingExistingContainer && onLoading) {
    onLoading(fetchingExistingContainer);
  }

  if (container.id !== (existingContainer?.id || null)) {
    onChangeValue('id')(existingContainer?.id || null);
  }

  const nameFieldLabel = useMemo(() => {
    if (itemType === PieceType.Package) return 'Group Name';
    return containerTypes?.find((type) => type.id === Number(containerTypeId))?.containerUiLabel || '';
  }, [containerTypes, itemType, containerTypeId]);

  if (prevWorkflow.current && prevWorkflow.current !== workflow) {
    const nextContainer = {
      id: null,
      date: workflow === Workflow.MailBox ? new Date() : null,
      containerTypeId:
        containerTypes?.find((type) => type.workflow === WorkflowNameByEnum[workflow])?.id.toString() || '',
      containerNumber: workflow === Workflow.Carrier ? 'USPS' : '',
      sortingOrder: receiveSettings.ReceiveSortOrder,
    };
    setContainer(nextContainer);
    onChange({
      ...nextContainer,
      containerTypeId: +nextContainer.containerTypeId,
    });
  }

  prevWorkflow.current = workflow;

  useEffect(() => {
    const nextSortOrder = (existingContainer?.sortOrder as SortingOrder) || receiveSettings.ReceiveSortOrder;
    if (nextSortOrder !== sortingOrder) {
      setContainer({
        ...container,
        sortingOrder: nextSortOrder,
      });
    }

    if (isNextOrderNo) {
      onChange({
        id: container.id,
        createdDate: container.date ? getDateStringWithoutTime(container.date) : null,
        containerTypeId: +container.containerTypeId,
        containerNumber: container.containerNumber,
        sortOrder: nextSortOrder !== sortingOrder ? nextSortOrder : container.sortingOrder,
        nextOrderNo: existingContainer?.nextOrderNo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingContainer]);

  return {
    nameFieldLabel,
    containerTypeId,
    date,
    containerNumber,
    existingContainer,
    sortingOrder,
    fetchingExistingContainer,
    onChangeValue,
  };
};
