import { useCallback, useEffect, useRef } from 'react';

import { ContainerSize, Coordinates, CropPointsCoordinates, points } from '../index';

interface CropPointsDelimitersProps {
  previewSize: ContainerSize;
  cropPoints: CropPointsCoordinates;
}

export const CropEdges = ({ previewSize, cropPoints }: CropPointsDelimitersProps) => {
  const canvas = useRef<HTMLCanvasElement>(null);

  const clearCanvas = useCallback(() => {
    const ctx = canvas.current?.getContext('2d');
    ctx?.clearRect(0, 0, previewSize.width, previewSize.height);
  }, [previewSize]);

  const drawShape = useCallback(([point1, point2, point3, point4]: Coordinates[]) => {
    const ctx = canvas.current?.getContext('2d');

    if (!ctx) return;

    ctx.lineWidth = 3;
    ctx.strokeStyle = 'white';

    ctx.beginPath();
    ctx.moveTo(point1.x, point1.y);
    ctx.lineTo(point2.x, point2.y);
    ctx.lineTo(point4.x, point4.y);
    ctx.lineTo(point3.x, point3.y);
    ctx.lineTo(point1.x, point1.y);
    ctx.closePath();
    ctx.stroke();
  }, []);

  useEffect(() => {
    clearCanvas();
    const coordinates = points.map((point) => cropPoints[point]);
    drawShape(coordinates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropPoints]);

  return (
    <canvas
      className={'rd-cropper-edges'}
      ref={canvas}
      style={{
        position: 'absolute',
        zIndex: 5,
      }}
      width={previewSize.width}
      height={previewSize.height}
    />
  );
};
