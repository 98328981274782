export const resizeImage = (base64Str: string, maxWidth = 400, maxHeight = 350): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64Str;
    img.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/jpeg'));
      } catch (error) {
        console.error('Image resizing did not succeed:', error);
        reject(error);
      }
    };
  });
};

export const rotateImage = (base64: string, angle: number, callback: (result: string) => any) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = new Image();

  image.onload = function () {
    canvas.width = angle % 180 === 0 ? image.width : image.height;
    canvas.height = angle % 180 === 0 ? image.height : image.width;

    ctx!.translate(canvas.width / 2, canvas.height / 2);
    ctx!.rotate((angle * Math.PI) / 180);
    ctx!.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL());
  };

  image.src = base64;
};
