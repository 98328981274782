import { useCallback } from 'react';

import { useGetMyProfileQuery } from '@api/user';

export const useUserPermissions = () => {
  const { data: currentUser } = useGetMyProfileQuery();

  const isAllowed = useCallback(
    (requiredInterface: string[]) => {
      const roles = currentUser ? currentUser.roles : [];

      if (roles.includes('KTAdmin')) return true;

      const filteredArray = roles.filter((value) => requiredInterface.includes(value));
      return filteredArray.length ? true : false;
    },
    [currentUser],
  );

  return { isAllowed };
};
