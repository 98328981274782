import { useMemo } from 'react';

import { format } from 'date-fns';

import { useSettings } from '@common/hooks/useSettings';

// TODO: In case of further updates to the date formats, consider removing uppercase [U].
export type LocationIdTemplate = '{MMM[U]} {dd}' | '{MM/dd}' | '{MMM dd}' | '{MM-dd}' | '{dd MMM}' | '{dd MMM[U]}';

const settingName = ['ContainerDateFormat'];

export const useLocationId = () => {
  const { ContainerDateFormat: locationIdSettingValue } = useSettings(settingName) as {
    ContainerDateFormat: LocationIdTemplate;
  };

  // https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  const convertFormatStringToUnicodeToken = (str: string) => {
    return str.replaceAll(/[{}]/g, '').replace('[U]', '');
  };

  const locationIdDateFormat = useMemo(() => {
    return convertFormatStringToUnicodeToken(locationIdSettingValue);
  }, [locationIdSettingValue]);

  const locationIdDateUppercase = useMemo(() => {
    return locationIdSettingValue.includes('U');
  }, [locationIdSettingValue]);

  const getLocationIdFromDate = (dateValue: Date | string) => {
    const date = new Date(dateValue);
    const locationId = format(date, locationIdDateFormat);
    return locationIdDateUppercase ? locationId.toUpperCase() : locationId;
  };

  const getLocationIdFromFormat = (formatValue: string) => {
    const date = new Date();
    const unicodeFormat = convertFormatStringToUnicodeToken(formatValue);
    const isUppercase = formatValue.includes('U');
    const locationId = format(date, unicodeFormat);
    return isUppercase ? locationId.toUpperCase() : locationId;
  };

  return { locationIdDateFormat, locationIdDateUppercase, getLocationIdFromDate, getLocationIdFromFormat };
};
