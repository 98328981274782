import { Box } from '@mui/system';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { UploadRef } from '../../types';
import Upload from './components/Upload';
import { sxAlert, sxMain } from './styles';
import { useGetSubscriptionSummaryQuery } from 'src/redux/api/subscription';
import { PieceType } from 'src/common/types/apiContract';
import { Alert } from '@mui/material';

type UploadImagesProps = {
  forceUpdate: (data: object) => void;
  onUpload: (data: Array<File | string>) => void;
  type: PieceType;
  setInvalid: (a: boolean) => void;
};

const UploadImages = forwardRef<unknown, unknown>((props, ref) => {
  const { forceUpdate, onUpload, type, setInvalid } = props as UploadImagesProps;
  const [files, setFiles] = useState<(File | string)[]>([]);
  const { data: subscriptionSummary } = useGetSubscriptionSummaryQuery(undefined, { refetchOnMountOrArgChange: true });
  const [error, setError] = useState('');

  const checkSubscription = (filesLength: number) => {
    const totalKey = type === PieceType.Mail ? 'maxMail' : 'maxPackages';
    const usedKey = type === PieceType.Mail ? 'usedMail' : 'usedPackages';

    if (!subscriptionSummary) {
      setError('Can not get subscription info.');
      setInvalid(true);
      return false;
    }

    const leftItems = subscriptionSummary[totalKey] - subscriptionSummary[usedKey];

    if (leftItems < filesLength) {
      setError(`You’ve exceeded the limit of items you can receive on your current plan. You have ${leftItems} items remaining.`);
      setInvalid(true);
    } else {
      setError('');
      setInvalid(false);
    }
    return true;
  };

  const handleDropMultiFile = useCallback(
    (acceptedFiles: File[]) => {
      const nextFiles = [
        ...files,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          }),
        ),
      ];

      if (!checkSubscription(nextFiles.length)) return;

      setFiles([
        ...files,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          }),
        ),
      ]);
    },
    // eslint-disable-next-line
    [files, type, setError, setInvalid, subscriptionSummary],
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filesFiltered = files.filter((fileFiltered) => fileFiltered !== inputFile);
    if (!checkSubscription(filesFiltered.length)) return;
    setFiles(filesFiltered);
  };

  useImperativeHandle(
    ref,
    (): UploadRef => {
      forceUpdate({});
      return files.length
        ? {
          upload() {
            onUpload(files);
          },
          removeAll() {
            setFiles([]);
          },
        }
        : null;
    },
    [files, forceUpdate, onUpload],
  );

  return (
    <Box sx={sxMain}>
      {!window.OffscreenCanvas && <Alert severity='info' sx={sxAlert}>For best performance, please upload no more than 50 images at a time</Alert>}
      <Upload error={error} files={files} onDrop={handleDropMultiFile} onRemove={handleRemoveFile} />
    </Box>
  );
});

export default UploadImages;
