import { useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

import { ProgressStatus } from '../../types';
import DesktopList from './DesktopList';
import MobileList from './MobileList';

type ProgressListProps = {
  status: ProgressStatus;
  onChange: (data: ProgressStatus) => void;
  data: Record<ProgressStatus, number>;
};

const ProgressList: FC<ProgressListProps> = ({ onChange, status, data }) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  if (matches) {
    return <MobileList onChange={onChange} status={status} data={data} />;
  }

  return <DesktopList onChange={onChange} status={status} data={data} />;
};

export default ProgressList;
