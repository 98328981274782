import { useCallback, useEffect, useState } from 'react';

// TODO: Remove this hook. It is formerly provided by the Minimals template.

export const useScreenOrientation = () => {
  const [{ width, height }, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getOrientation = useCallback(() => {
    return width >= height ? 'landscape-primary' : 'portrait-primary';
  }, [width, height]);

  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    setOrientation(getOrientation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return orientation.split('-')[0] as 'landscape' | 'portrait';
};
