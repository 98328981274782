import { LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { sxLoading } from './styles';

type Props = {
  percent: number;
};

const PercentLoading = ({ percent }: Props) => {
  return (<Box
    sx={sxLoading.box}
  >
    <Typography variant="subtitle2">{percent || 0} %</Typography>
    <LinearProgress sx={sxLoading.progress} variant="determinate" value={percent || 0} />
    <Typography color="text.secondary" variant="body2">Loading items...</Typography>
  </Box>);
};

export default PercentLoading;
