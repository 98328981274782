import { type Theme } from '@mui/material';

export const sxSelectedBox = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: 0,
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  bgcolor: 'rgba(0, 0, 0, .5)',
};

export const sxPaper = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  minHeight: '100%',
};

export const sxList = (theme: Theme) => ({
  display: 'flex',
  flexGrow: '1',
  px: 2.5,
  mt: 2,
  '& .customScrollBar': {
    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[800],
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[700],
      borderRadius: '12px',
    },
  },
  minHeight: '150px',
});

export const sxNoResult = {
  position: 'absolute',
  top: '68px',
  width: 1,
  textAlign: 'center',
};

export const sxAssignToBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2.5,
  p: 2.5,
};

export const sxAssignButton = (theme: Theme) => ({
  borderRadius: 2,
  py: 2,
  bgcolor: theme.palette.action.hover,
});

export const sxP20 = {
  p: 2.5,
};

export const sxPT20 = {
  pt: 2.5,
};

export const sxAvatarBigSize = {
  height: '48px',
  width: '48px',
};

export const sxItemButton = (selected: boolean, theme: Theme) => ({
  borderRadius: 2,
  p: 1,
  bgcolor: selected ? theme.palette.action.hover : 'transparent',
});

export const sxButton = {
  px: '22px',
  py: '11px',
};

export const sxCheckOutIcon = {
  color: 'primary.main',
};

export const sxIconGrey500 = (theme: Theme) => ({
  color: theme.palette.grey[500],
});

export const sxAvatar = (theme: Theme) => ({
  bgcolor: theme.palette.grey[700],
  textTransform: 'uppercase',
  color: theme.palette.grey[500],
});

export const sxItemAvatar = {
  position: 'relative',
};

export const sxTabsSection = (index: number) => ({
  transform: `translateX(${-100 * index}%)`,
  display: 'flex',
  flexGrow: 1,
  transition: '0.5s ease-in-out',
});

export const sxTab = {
  minWidth: 1,
  flexGrow: 1,
  flexDirection: 'column',
};

export const sxFormTab = {
  ...sxTab,
  ...sxP20,
  justifyContent: 'space-between',
};
