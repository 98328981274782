import { useEffect } from 'react';

import { useOidcAccessToken } from '@axa-fr/react-oidc';

import { configureTokenAxios } from '@common/api/axios';

import { setCredentials } from '@redux/slices/auth';
import { useDispatch } from '@redux/store';

type AppInitializerProps = {
  children: React.ReactNode;
};

export const AppInitializer = ({ children }: AppInitializerProps) => {
  const dispatch = useDispatch();
  const { accessToken } = useOidcAccessToken();

  useEffect(() => {
    if (accessToken) {
      dispatch(setCredentials(accessToken));
      configureTokenAxios(accessToken);
    }
  }, [accessToken, dispatch]);

  return <>{children}</>;
};
