import { useMemo } from 'react';

import type {
  BooleanResponse,
  CreatePieceCommentRequest,
  CreatePieceCommentsRequest,
  CreatePieceDocRequest,
  CreatePiecePageRequest,
  CreatePieceRequest,
  Int32ArrayResponse,
  PeriodType,
  Piece,
  PieceAction,
  PieceArrayResponse,
  PieceComment,
  PieceCommentArrayResponse,
  PieceCommentListResponse,
  PieceCommentResponse,
  PieceCounters,
  PieceCountersResponse,
  PieceDoc,
  PieceDocPage,
  PieceDocPageResponse,
  PieceDocResponse,
  PieceFilterScope,
  PiecePagedList,
  PiecePagedListResponse,
  PieceResponse,
  PieceStatus,
  PieceType,
  UpdatePieceDocRequest,
  UpdatePiecePageRequest,
  UpdatePiecesRequest,
} from '@apiContract';
import { PieceState } from '@apiContract';
import * as Sentry from '@sentry/react';

import { receivedDigitalApi } from './index';

export type GetPiecesFilteredRequest = {
  Scope: PieceFilterScope;
  Type?: PieceType;
  ContainerId?: number;
  PieceState?: PieceState;
  PieceStatus?: PieceStatus;
  LifecycleStage?: PieceAction;
  Period?: PeriodType;
  StartDate?: string;
  EndDate?: string;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  ItemsPerPage?: number;
  PageNumber?: number;
  ReceivedBy?: string;
  IsInMailCenterPiece?: boolean;
};

const reorder = (piece: Piece, order: number[]): Piece => {
  const newPiecePages = order
    .map((id, index) => {
      const piecePage = piece.pieceDoc?.piecePages.find((page) => page.id === id);
      if (!piecePage) return undefined;
      return { ...piecePage, sortOrder: index + 1 };
    })
    .filter((page) => Boolean(page));

  // @ts-expect-error Type bug on backend - .pieceDoc property should be nullable.
  return { ...piece, pieceDoc: piece.pieceDoc ? { ...piece.pieceDoc, piecePages: newPiecePages } : null };
};

export const pieceApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getPiece: builder.query<Piece, number>({
      query: (id) => `Piece/${id}`,
      transformResponse: (response: PieceResponse) => response.objectData,
    }),

    getPieces: builder.query<Piece[], void>({
      query: () => 'Piece',
      providesTags: (result) => {
        const tagsList = result?.map((piece) => ({ type: 'Piece' as const, id: piece.id })) || [];
        return ['Pieces', ...tagsList];
      },
      transformResponse: (response: PieceArrayResponse) => response.objectData,
    }),

    getPiecesFiltered: builder.query<PiecePagedList, GetPiecesFilteredRequest>({
      query: (params) => {
        return (Object.keys(params) as Array<keyof typeof params>).reduce((queryString, param, index) => {
          return queryString + `${index === 0 ? '?' : '&'}${param}=${params[param]}`;
        }, 'Piece/filtered');
      },
      providesTags: (result) => {
        const tagsList = result?.items?.map((piece) => ({ type: 'Piece' as const, id: piece.id })) || [];
        return ['Pieces', ...tagsList];
      },
      transformResponse: (response: PiecePagedListResponse) => response.objectData,
    }),

    getPiecesUnsent: builder.query<number[], number | void>({
      query: (containerId) => 'Piece/unsent' + (containerId ? `?containerId=${containerId}` : ''),
      providesTags: ['Pieces'],
      transformResponse: (response: Int32ArrayResponse) => response.objectData,
    }),

    getPiecesCounters: builder.query<PieceCounters, void>({
      query: () => 'Piece/counters',
      providesTags: ['PiecesCounters'],
      transformResponse: (response: PieceCountersResponse) => response.objectData,
    }),

    createPiece: builder.mutation<Piece, { doNotInvalidate?: boolean } & CreatePieceRequest>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: (requestData) => ({
        url: 'Piece',
        method: 'POST',
        body: requestData,
      }),
      // Newly created pieces are not involved in any currently existing counter, so currently no need to invalidate 'PiecesCounters'.
      invalidatesTags: (_, __, requestData) => (requestData.doNotInvalidate ? [] : ['Pieces']),
      transformResponse: (response: PieceResponse) => response.objectData,
    }),

    deletePiece: builder.mutation<BooleanResponse, number>({
      query: (pieceId) => ({
        url: `Piece/${pieceId}`,
        method: 'DELETE',
      }),
      // Currently only unsent pieces can be deleted. Since they are not counted, no need to invalidate 'PiecesCounters'.
      invalidatesTags: ['Pieces', 'Containers', 'Container'],
    }),

    updatePieces: builder.mutation<Piece[], UpdatePiecesRequest>({
      query: (requestData) => ({
        url: 'Piece/PutPieces',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Pieces', 'Containers', 'Container', 'PiecesCounters'],
      transformResponse: (response: PieceArrayResponse) => response.objectData,
      transformErrorResponse(response: { status: number, data: PieceArrayResponse }, _, arg) {
        Sentry.captureEvent({
          message: response.data?.userMessage || 'API: Piece/PutPieces',
          level: 'error',
          extra: {
            payloadKeys: Object.keys(arg),
            payload: {
              action: arg.action,
              pickupSignatureKey: arg.pickupSignatureKey,
              pickupDate: arg.pickupDate,
              addressId: arg.addressId,
              qrCode: arg.qrCode,
              trackingNumber: arg.trackingNumber,
              pieces: arg.pieces?.map((item) => JSON.stringify(item)) || [],
            },
            response: JSON.stringify(response), 
          },
        });

        return response;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        // TODO: Consider moving this update to RTKQuery tags mechanism. Also, check deletePiece mutation invalidation logic.
        response.data.forEach((updatedPiece) => {
          dispatch(
            pieceApi.util.updateQueryData('getPiece', updatedPiece.id, (draft) => {
              if (draft.id === updatedPiece.id) return updatedPiece;
            }),
          );
        });
      },
    }),

    deletePieces: builder.mutation<BooleanResponse, number[]>({
      query: (requestData) => ({
        url: 'Piece/DeleteList',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Pieces', 'PiecesCounters'],
    }),

    // Below endpoints should be moved into their own file from here.

    createPieceDocument: builder.mutation<PieceDoc, CreatePieceDocRequest>({
      query: (requestData) => ({
        url: 'PieceDoc',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => {
        return result ? [{ type: 'Piece', id: requestData.pieceId }] : [];
      },
      transformResponse: (response: PieceDocResponse) => response.objectData,
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        if (!requestData.pieceId) return;

        dispatch(
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            if (draft.id === requestData.pieceId) draft.pieceDoc = response.data;
          }),
        );
      },
    }),

    updatePieceDocument: builder.mutation<PieceDoc, UpdatePieceDocRequest & { pieceId: number }>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ pieceId, ...requestData }) => ({
        url: 'PieceDoc',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => {
        return result ? [{ type: 'Piece', id: requestData.pieceId }] : [];
      },
      transformResponse: (response: PieceDocResponse) => response.objectData,
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        dispatch(
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            if (draft.id === requestData.pieceId) draft.pieceDoc = response.data;
          }),
        );
      },
    }),

    deletePieceDocument: builder.mutation<BooleanResponse, { pieceDocumentId: number; pieceId: number }>({
      query: (requestData) => ({
        url: `PieceDoc/${requestData.pieceDocumentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, requestData) => {
        return result ? [{ type: 'Piece', id: requestData.pieceId }] : [];
      },
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            // @ts-expect-error Type bug on backend - .pieceDoc property should be nullable.
            if (draft.id === requestData.pieceId) draft.pieceDoc = null;
          }),
        );
      },
    }),

    // Below endpoints should be moved into their own file from here.

    createPiecePage: builder.mutation<PieceDocPage, CreatePiecePageRequest & { pieceId: number }>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ pieceId, ...requestData }) => ({
        url: 'PiecePage',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => {
        return result ? [{ type: 'Piece', id: requestData.pieceId }] : [];
      },
      transformResponse: (response: PieceDocPageResponse) => response.objectData,
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        dispatch(
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            if (draft.pieceDoc?.id === requestData.pieceDocId) draft.pieceDoc.piecePages.push(response.data);
          }),
        );
      },
    }),

    updatePiecePage: builder.mutation<PieceDocPage, UpdatePiecePageRequest & { pieceId: number }>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ pieceId, ...requestData }) => ({
        url: 'PiecePage',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => {
        return result ? [{ type: 'Piece', id: requestData.pieceId }] : [];
      },
      transformResponse: (response: PieceDocPageResponse) => response.objectData,
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        dispatch(
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            if (draft.pieceDoc?.id === requestData.pieceDocId) {
              const index = draft.pieceDoc.piecePages.findIndex((page) => page.id === requestData.id);
              if (index !== -1) draft.pieceDoc.piecePages[index] = response.data;
            }
          }),
        );
      },
    }),

    updatePiecePagesOrder: builder.mutation<BooleanResponse, { order: number[]; pieceId: number }>({
      query: (requestData) => ({
        url: 'PiecePage/UpdateOrder',
        method: 'PUT',
        body: requestData.order,
      }),
      invalidatesTags: (result, _, requestData) => {
        return result ? [{ type: 'Piece', id: requestData.pieceId }] : [];
      },
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            if (draft.id === requestData.pieceId) return reorder(draft, requestData.order);
          }),
        );
      },
    }),

    deletePiecePage: builder.mutation<BooleanResponse, { pieceId: number; piecePageId: number }>({
      query: (requestData) => ({
        url: `PiecePage/${requestData.piecePageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, requestData) => {
        return result ? [{ type: 'Piece', id: requestData.pieceId }] : [];
      },
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            if (draft.id === requestData.pieceId) {
              const pageIndex =
                draft.pieceDoc?.piecePages.findIndex((page) => page.id === requestData.piecePageId) || -1;
              if (pageIndex !== -1) draft.pieceDoc?.piecePages.splice(pageIndex, 1);
            }
          }),
        );
      },
    }),

    // Below endpoints should be moved into their own file from here.

    getPieceComments: builder.query<PieceComment[], number>({
      query: (pieceId) => `PieceComment/piece/${pieceId}`,
      transformResponse: (response: PieceCommentArrayResponse) => response.objectData,
    }),

    createPieceComment: builder.mutation<PieceComment, CreatePieceCommentRequest>({
      query: (requestData) => ({
        url: 'PieceComment',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'Piece', id: result.pieceId }] : [];
      },
      transformResponse: (response: PieceCommentResponse) => response.objectData,
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        dispatch(
          // @ts-expect-error Type bug on backend - .pieceId property should not be optional.
          pieceApi.util.updateQueryData('getPieceComments', requestData.pieceId, (draft) => {
            draft.push(response.data);
          }),
        );

        dispatch(
          // @ts-expect-error Type bug on backend - .pieceId property should not be optional.
          pieceApi.util.updateQueryData('getPiece', requestData.pieceId, (draft) => {
            draft.commentsCount++;
          }),
        );
      },
    }),

    createMailpiecesListComment: builder.mutation<PieceComment[], CreatePieceCommentsRequest>({
      query: (requestData) => ({
        url: 'PieceComment/list',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (result) => {
        return result ? result.map((piece) => ({ type: 'Piece', id: piece.id })) : [];
      },
      transformResponse: (response: PieceCommentListResponse) => response.objectData,
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        response.data.forEach((comment) => {
          dispatch(
            pieceApi.util.updateQueryData('getPieceComments', comment.pieceId, (draft) => {
              draft.push(comment);
            }),
          );

          dispatch(
            pieceApi.util.updateQueryData('getPiece', comment.pieceId, (draft) => {
              draft.commentsCount++;
            }),
          );
        });
      },
    }),
  }),
});

export const {
  useGetPieceQuery,
  useGetPiecesQuery,
  useGetPiecesUnsentQuery,
  useLazyGetPiecesUnsentQuery,
  useGetPiecesCountersQuery,
  useCreatePieceMutation,
  useDeletePieceMutation,
  useUpdatePiecesMutation,
  useDeletePiecesMutation,
  useCreatePieceDocumentMutation,
  useUpdatePieceDocumentMutation,
  useDeletePieceDocumentMutation,
  useCreatePiecePageMutation,
  useUpdatePiecePageMutation,
  useUpdatePiecePagesOrderMutation,
  useDeletePiecePageMutation,
  useGetPieceCommentsQuery,
  useCreatePieceCommentMutation,
  useCreateMailpiecesListCommentMutation,
} = pieceApi;

export const useGetPiecesFilteredAndCountersQuery = (requestArguments: GetPiecesFilteredRequest) => {
  return pieceApi.useGetPiecesFilteredQuery(requestArguments, { refetchOnMountOrArgChange: true });
};

export const useGetMailroomPiecesCountQuery = () => {
  const { data } = useGetPiecesCountersQuery();

  return useMemo(() => {
    if (!data) return 0;
    const counters = Object.values(data.pendingCounters) as number[];
    return counters.reduce((total, counter) => total + counter, 0);
  }, [data]);
};
