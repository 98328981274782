import { Box, Stack, Typography } from '@mui/material';

import { UploadIllustration } from 'src/assets/illustrations';

import { sxBrowse, sxIllustration } from '../../styles';

const Placeholder = () => (
  <Stack spacing={{ xs: 1, md: 3 }} alignItems="center" justifyContent="center" flexWrap="wrap">
    <UploadIllustration sx={sxIllustration} />
    <Stack spacing={1} textAlign="center">
      <Typography variant="h6">Select files</Typography>
      <Typography variant="body2" color="text.secondary">
        Drop files here or click to
        <Box component="span" sx={sxBrowse}>
          browse
        </Box>
        through your machine (max size of 30 Mb)
      </Typography>
    </Stack>
  </Stack>
);

export default Placeholder;
