import PersonIcon from '@mui/icons-material/Person';
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { FC } from 'react';

import { RecipientAutoDetectionStatus, User } from '@apiContract';
import { m } from 'framer-motion';

import { getColorPieceStatus, recipientAlerts } from '../../../helper';
import { sxSearchCircle } from '../../../styles';
import { sxAssignButton, sxAssignToBox, sxAvatarBigSize, sxIconGrey500 } from '../styles';
import { CapturePiece } from '../../../types';

type AssignToProps = {
  recipientStatus?: RecipientAutoDetectionStatus;
  user?: User;
  showAlert: boolean;
  loading: boolean;
  file: CapturePiece | null,
};

const AssignTO: FC<AssignToProps> = ({ file, user, showAlert, recipientStatus, loading = false }) => {
  const theme = useTheme();
  const color = getColorPieceStatus(file?.piece, recipientStatus) || 'info';
  const alert = recipientStatus !== undefined ? recipientAlerts[recipientStatus] : null;

  return (
    <Box sx={sxAssignToBox}>
      {showAlert && alert && (
        <m.div
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Alert severity={color}>{alert}</Alert>
        </m.div>
      )}
      <ListItem disablePadding component="div">
        <ListItemButton sx={{ ...sxAssignButton(theme) }}>
          <Box display="flex" flexDirection="row" alignItems="center" flex="1">
            <ListItemAvatar>
              <Avatar sx={sxAvatarBigSize} alt={`${user?.displayName}`} src={user?.profileImageUrl}>
                {user && !user?.profileImageUrl ? (
                  `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
                ) : (
                  <PersonIcon sx={sxIconGrey500} />
                )}
              </Avatar>
            </ListItemAvatar>
            {user ? (
              <ListItemText
                primary={<Typography variant="subtitle1">{`${user.firstName} ${user.lastName}`}</Typography>}
                secondary={
                  <Typography color="text.secondary" variant="body1">
                    {user.userName}
                  </Typography>
                }
              />
            ) : (
              <Typography variant="subtitle1">{'Unassigned user'}</Typography>
            )}
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={22} sx={sxSearchCircle} />
            </Box>
          )}
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default AssignTO;
