import { Outlet } from 'react-router-dom';

import { useGetUsersQuery } from '@api/user';

import LoadingScreen from '@common/components/LoadingScreen';

export const AppCommonDataLoader = () => {
  const { isLoading: isLoadingUsers } = useGetUsersQuery();

  if (isLoadingUsers) return <LoadingScreen />;

  return <Outlet />;
};
