export const getRightPoints = (points: any[]) => {
  return points
    .sort((a: any, b: any) => a.corner.x - b.corner.x)
    .splice(2, 2)
    .sort((a: any, b: any) => a.corner.y - b.corner.y);
};

export const getLeftPoints = (points: any[]) => {
  return points
    .sort((a: any, b: any) => b.corner.x - a.corner.x)
    .splice(2, 2)
    .sort((a: any, b: any) => a.corner.y - b.corner.y);
};

export const filterPointsCorrectly = (points: any[]) => {
  const leftPointsBuffer = [...points];
  const rightPointsBuffer = [...points];
  const leftPoints = getLeftPoints(leftPointsBuffer);
  const rightPoints = getRightPoints(rightPointsBuffer);

  const leftTopCorner = leftPoints[0];
  const leftBottomCorner = leftPoints[1];
  const rightTopCorner = rightPoints[0];
  const rightBottomCorner = rightPoints[1];
  return [leftTopCorner, rightTopCorner, rightBottomCorner, leftBottomCorner];
};

export const getCornerArrayFromContour = (cv: any, foundContour: any) => {
  const corner1 = new cv.Point(foundContour.data32S[0], foundContour.data32S[1]);
  const corner2 = new cv.Point(foundContour.data32S[2], foundContour.data32S[3]);
  const corner3 = new cv.Point(foundContour.data32S[4], foundContour.data32S[5]);
  const corner4 = new cv.Point(foundContour.data32S[6], foundContour.data32S[7]);

  const cornerArray = [{ corner: corner1 }, { corner: corner2 }, { corner: corner3 }, { corner: corner4 }];
  cornerArray
    .sort((item1: any, item2: any) => {
      return item1.corner.y < item2.corner.y ? -1 : item1.corner.y > item2.corner.y ? 1 : 0;
    })
    .slice(0, 5);

  return cornerArray;
};
